import React, {useState, useEffect} from 'react';
import apiService from '../../services/api';
import Heatmap from '../shared/heatmap';
import { TABLE_BLURED_VALUE } from '../../utils/constants/app-constants';
import CardHeading from './CardHeading';
import { TbWorld } from "react-icons/tb";
import PaginatedTable from '../shared/RedesignedPaginatedTable';

const TopAirports = ({title, timePeriod, presetFilters, hasLimitedAccess=false }) => {
  const [airportData, setAirportData] = useState(null);
  const [fetching, setFetching] = useState(false);

  const transformTableData = (response) =>{
    const doNotTransformKeys = ['lat','lng']
    return response.map((obj,index)=>{
      const updatedData = hasLimitedAccess ? Object.fromEntries(Object.entries(obj).map(([key,value])=>(
        [key, (index > 2 && !doNotTransformKeys.includes(key)) ? TABLE_BLURED_VALUE : value]
      ))): obj
      updatedData.firstCellSubValue = updatedData.country;
      delete updatedData.country;
      return updatedData;
    })
  }
  useEffect(() => {
    async function fetchData() {
        setFetching(true);
        const airportResponse = await apiService.getFlightsByAirport({
            timePeriod: timePeriod ? timePeriod : 30, 
            additionalFilters: presetFilters ? presetFilters : ""
        });
        setAirportData(transformTableData(airportResponse));
        setFetching(false);
    }
    fetchData();
  }, [timePeriod]);
  return (
    <div className="grid lg:grid-cols-2 gap-5">      
      <div className="live-flights-container flex flex-col gap-4 max-lg:order-2">
        <CardHeading title={title} icon={TbWorld} />

        {fetching && 
          <div className="my-auto flex flex-col justify-center items-center">
            <i className="fa fa-spinner fa-spin text-6xl"></i>
            <h3 className="text-3xl mt-6">Loading Data...</h3>
          </div>
        }

        {!fetching && (
          !airportData ? (
            <div className="my-auto flex flex-col justify-center items-center">
              <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
              <h3 className="text-xl mt-6">No Data Available.</h3>
            </div>
          ) : <PaginatedTable data={airportData} additional={airportData.length} type="Airports" includeChart={true} additionalFilters={presetFilters} />
        )}
      </div>
      <div className="map-height rounded-[30px] overflow-hidden max-lg:order-1">
        {airportData && <Heatmap data={airportData} containerId={`heatmap-2`} />}
      </div>
    </div>
  );
};

export default TopAirports;