import styles from './styles/styles.module.scss'
import GLBAircraftModelViewer from '../../components/common/GLBAircraftModelViewer'
import CreateShareLinkModal from '../common/CreateShareLinkModal'
import ComparisonModal from '../CompareAircrafts/comparisonModal'
import { CURRENCY_OPTIONS, REGION_OPTIONS, UNIT_OPTIONS } from '../../utils/constants/app-constants'
import Dropdown from '../common/Dropdown'
import StatDonuts from './statDonuts'

const KeyFacts = ({ params, unit, region, currency, onUnitChanged, onregionChanged, onCurrencyChanged, pageData,regionPrefix, conversionRate, currencySymbol, allowComparison ,readOnly  }) => {
  const keyFacts = params.key_facts?.split('\n') || []

  return (
    <div id='overview' className='pb-7 border-b border-black !max-w-full !m-0'>
      <div className="relative w-full ">
        <div className="realtive flex max-lg:flex-col max-lg:gap-4">
          <div className="w-full h-96 lg:h-[500px]">
            <GLBAircraftModelViewer src={params.aircraft_3d_model} />
          </div>
          <div className='max-lg:overflow-x-auto lg:absolute top-0 right-0 h-full'>
            <StatDonuts 
              params={params}
              currencySymbol={currencySymbol}
              regionPrefix={regionPrefix}
              conversionRate={conversionRate}
              unit={unit}
            />
          </div>
        </div>
        <div className='absolute top-0 left-0 text-xl lg:text-6xl font-semibold'>
          <p className='text-oldSilver text-base'>
            {params.production_start} -{' '}
            {params.production_end ? params.production_end : 'Current'}
          </p>
          <h3 className='heading-medium w-min'>{params.aircraft_name}</h3>
        </div>
      </div>
      <div className='grid lg:grid-cols-[1fr,1fr,20%] gap-5 lg:gap-10 items-end'>
        <ul className='list-outside ml-6 list-disc mt-8 text-lg md:text-xl text-oldSilver'>
          {keyFacts.map((fact, index) => {
            return <li key={index}>{fact}</li>
          })}
        </ul>
        <div className='flex lg:justify-around gap-5 lg:flex-wrap max-lg:overflow-x-auto max-lg:overflow-y-hidden mx-auto w-auto max-w-full'>
          <div className="text-center space-y-2">
            <h6 className='text-sm'>Unit</h6>
            <Dropdown
              className={styles.dropdown}
              value={unit}
              setValue={value => onUnitChanged(value)}
              options={UNIT_OPTIONS}
            />
          </div>
          <div className="text-center space-y-2">
            <h6 className='text-sm'>Region</h6>
            <Dropdown
              className={styles.dropdown}
              value={region}
              setValue={value => onregionChanged(value)}
              options={REGION_OPTIONS}
            />
          </div>
          <div className="text-center space-y-2">
            <h6 className='text-sm'>Currency</h6>
            <Dropdown
              className={styles.dropdown}
              value={currency}
              setValue={value => onCurrencyChanged(value)}
              options={CURRENCY_OPTIONS}
            />
          </div>
        </div>
        <div className='flex gap-5 flex-wrap justify-center'>
          {allowComparison && (
          <ComparisonModal currentIds={[params?.id]} />
          )}
          {!readOnly && (
          <CreateShareLinkModal
            pageType='aircraft'
            searchParams={window.location.search}
            pageData={pageData}
          />
          )}
        </div>
      </div>
    </div>
  )
}

export default KeyFacts
