import React from 'react'

const CardHeading = ({ icon: Icon, title, subTitle }) => {
  return (
    <div className='w-full flex gap-3 items-center'>
      <div className='size-13 md:size-16 rounded-full border-2 border-lightSilver flex justify-center items-center shrink-0'>
        <Icon size={20} />
      </div>
      <div>
        <h3 className='text-2xl font-semibold'>{title}</h3>
        {subTitle && <p className='text-xl'>{subTitle}</p>}
      </div>
    </div>
  )
}

export default CardHeading
