import { useEffect, useState, useCallback } from "react";
import { useBeforeUnload } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { AUTHENTICATION_COOKIE_KEY, USERlEVELS } from "../utils/constants/app-constants";

const API_URL = `https://compareprivateplanes.com/wp-json/wp/v2`;

function useUserAuthentication() {
  const cookies = Cookies.get();
  const [, loggedInUserLogin] = Object.entries(cookies).find(([key]) =>
    key.includes(AUTHENTICATION_COOKIE_KEY)
  ) || [undefined, undefined];

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userLevel, setUserLevel] = useState(null);

  const fetchUserDetails = useCallback(async () => {
    try {
      setIsAuthenticating(true);
      if (!loggedInUserLogin) throw new Error("No access code found");

      const userlogin = decodeURI(loggedInUserLogin);
      const { data: targetUserMemberships } = await axios(
        `${API_URL}/user-membership/`,
        { params: { userlogin } }
      );

      // Check if any membership is found against user login
      if (targetUserMemberships.length === 0)
        throw new Error("No membership found");

      // TEST ENTRY *Garvit*
      // targetUserMemberships.push({
      //   object_id: 71,
      //   status: "active",
      //   expiration_date: "2025-12-31T23:59:59",
      // });

      // Filter out expired or inactive memberships *Garvit*
      const activeMemberships = targetUserMemberships.filter((membership) => {
        const expirationDate = new Date(membership.expiration_date);
        return membership.status === "active" && (membership.expiration_date?expirationDate > new Date():true)
      });
      activeMemberships.reverse()
      if (activeMemberships.length === 0)
        throw new Error("No active membership found");

      let userMembershipLevel;

      // Iterate over activeMemberships *Garvit*
      for (let i = 0; i < activeMemberships.length; i++) {
        const object_id = activeMemberships[i].object_id
        if (USERlEVELS[object_id]) {
          userMembershipLevel = USERlEVELS[object_id];
          break;
        }
      }

      // If no matching object_id was found in activeMemberships, throw an error *Garvit*
      if (!userMembershipLevel) {
        throw new Error("No matching object_id found in activeMemberships");
      }

      // Setting the user level *Garvit*
      setUserLevel(userMembershipLevel);

      // If membership is active
      setIsAuthenticated(true);
    } catch (error) {
      console.log("Error in authenticating user", error);
      setIsAuthenticated(false); // false
    } finally {
      setIsAuthenticating(false);
    }
  }, [loggedInUserLogin]);

  useBeforeUnload(
    useCallback(() => {
      Cookies.remove(AUTHENTICATION_COOKIE_KEY);
      localStorage.setItem("test", "cleared");
    }, [])
  );

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  return {
    isAuthenticating,
    isAuthenticated,
    userLevel,
  };
}

export default useUserAuthentication;
