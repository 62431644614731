import cn from 'classnames'
import global from '../styles/global.module.scss'
import numeral from 'numeral'
import { GoogleMap, Circle, LoadScript, Marker } from '@react-google-maps/api'
import { useState } from 'react'
import Slider from '@mui/material/Slider'
import { FaRegMap } from "react-icons/fa";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import styles from './styles/styles.module.scss'
import CardHeading from './CardHeading'

const RangesRow = ({ title, subTitle, value }) => {
  return (
    <tr className='pt-4 pb-3 first:pt-0 last:pb-0'>
      <td className='pt-[inherit] pb-[inherit]'>
        <h6 className='text-CharlestonGreen font-semibold text-lg'>{title}</h6>
        <p className='text-base font-medium text-quickSilver'>{subTitle}</p>
      </td>
      <td className='pl-4 text-right font-bold text-lg'>
        {numeral(value).format('0,0')}
      </td>
    </tr>
  )
}
const Map = ({ containerClassName, latLng, options }) => (
  <GoogleMap
    zoom={2}
    center={latLng}
    options={{
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false
    }}
    mapContainerClassName={`h-[400px] md:h-full rounded-[30px] overflow-hidden ${containerClassName}`}
  >
    <Circle center={latLng} options={options} />
    <Marker position={latLng} />
  </GoogleMap>
)

const RangeMap = ({ latLng, setLatLong, address, setAddress, params }) => {
  const [numberOfPassengers, setNumberOfPassengers] = useState(1)

  const decreasePerPassengerNauticalMiles = params.range_decrease_per_passenger
  const decreasePerPassengerMiles =
    params.range_decrease_per_passenger * 1.15078
  const decreasePerPassengerKm = params.range_decrease_per_passenger * 1.852
  const maxNumberOfPassengers = params.max_pax
  const rangeNauticalMiles = params.range_NM
  const rangeMiles = params.range_Miles
  const rangeKm = params.range_km

  function handleChange (address) {
    setAddress(address)
  }

  function handleSelect (address) {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => setLatLong(latLng))
      .catch(error => console.error('Error', error))
  }

  var options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: (rangeKm - numberOfPassengers * decreasePerPassengerKm) * 1000,
    zIndex: 1
  }

  //console.log(options)
  
  return (
    <div id="range_map" className='w-full '>
      <LoadScript
        id='script-loader'
        googleMapsApiKey='AIzaSyBoq4qT-mCxXcn5Mx77PAYVWXriJrVEY9A'
        libraries={['places']}
      >
        <div className='w-full grid md:grid-cols-[40%,1fr] gap-10'>
          <div className='flex flex-col justify-between gap-4 md:gap-8'>
            <CardHeading title='Range Map' icon={FaRegMap} />
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div className={styles.range_configs}>
                  <div
                    className={cn(
                      styles.map_inputs,
                      global.pdf_hidden,
                      'flex flex-col gap-4 md:gap-8'
                    )}
                  >
                    <div className='grid grid-cols-[40%,1fr] items-center'>
                      <label className='font-medium text-lg'>Pax number</label>
                      <div className='flex gap-4'>
                        <input
                          type='number'
                          value={numberOfPassengers}
                          min={0}
                          max={maxNumberOfPassengers}
                          onChange={e => {
                            const value = parseInt(e.target.value)
                            value >= 0 &&
                              value <= maxNumberOfPassengers &&
                              setNumberOfPassengers(value)
                          }}
                          className='border border-lightSilver px-2 text-right py-0 h-fit rounded-full max-w-14'
                        />
                        <Slider
                          valueLabelDisplay='auto'
                          aria-label='Volume'
                          value={numberOfPassengers}
                          max={maxNumberOfPassengers}
                          onChange={(e, newValue) =>
                            setNumberOfPassengers(newValue)
                          }
                          sx={{
                            '& .MuiSlider-thumb': {
                              color: '#454545'
                            },
                            '& .MuiSlider-track': {
                              color: '#454545'
                            },
                            '& .MuiSlider-rail': {
                              color: '#acc4e4'
                            },
                            '& .MuiSlider-active': {
                              color: 'green'
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-[40%,1fr] items-center'>
                      <label
                        className='font-medium text-lg'
                        label='startLocation'
                      >
                        Start Location
                      </label>
                      <input
                        {...getInputProps({
                          placeholder: 'Start location',
                          className: 'location-search-input'
                        })}
                        id='startLocation'
                        label='Start Location'
                        className='w-full border border-lightSilver px-2 py-0 h-fit rounded-full'
                      />
                    </div>
                  </div>
                  <div className='autocomplete-dropdown-container'>
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item'
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' }
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <Map latLng={latLng} options={options} containerClassName={'md:hidden'} />

            <table className='table-auto'>
              <tbody className='divide-y divide-gainsboro'>
                <RangesRow
                  title='Range'
                  subTitle='Nautical Miles'
                  value={rangeNauticalMiles}
                />
                <RangesRow title='Range' subTitle='Miles' value={rangeMiles} />
                <RangesRow
                  title='Adjusted Range'
                  subTitle='Nautical Miles'
                  value={
                    rangeNauticalMiles -
                    numberOfPassengers * decreasePerPassengerNauticalMiles
                  }
                />
                <RangesRow
                  title='Adjusted Range'
                  subTitle='Miles'
                  value={
                    rangeMiles - numberOfPassengers * decreasePerPassengerMiles
                  }
                />
              </tbody>
            </table>
          </div>
          <Map latLng={latLng} options={options} containerClassName={'max-md:hidden'} />
        </div>
      </LoadScript>
    </div>
  )
}

export default RangeMap
