import React, { useState, useEffect, useContext, useMemo } from "react";
import { StateContext } from "../../context";
import cn from "classnames";
import global from "../styles/global.module.scss";
import styles from "./styles/styles.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Dropdown from "../common/Dropdown";
import { FUTURE_OPTIONS } from "../../utils/constants/app-constants";
import numeral from "numeral";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const AquisitionCost = ({estimatedFutureValue, setestimatedFutureValue,yearManufacture0,setYearManufacture0,yearManufacture1,setYearManufacture1,yearManufacture2,setYearManufacture2, data,currencySymbol, currency,hasLimitedAccess = false, readOnly=false }) => {
  const { getCurrencyConversion } = useContext(StateContext);
  const [showGraph, setShowGraph ] = useState(false);
  const [conversionRate, setConversionRate] = useState(1);
  useEffect(() => {
    getCurrencyConversion(currency).then((rate) => setConversionRate(rate))
  }, [currency]);
  console.log(conversionRate)
  const keys0 = Object.keys(JSON.parse(data[0].acquisition_values));
  const values0 = Object.values(JSON.parse(data[0].acquisition_values));
  const keys1 = Object.keys(JSON.parse(data[1].acquisition_values));
  const values1 = Object.values(JSON.parse(data[1].acquisition_values));
  const values = data.map((aircraft)=>Object.values(JSON.parse(aircraft.acquisition_values)));
  
  var keys2 =
    data[2] !== undefined
      ? Object.keys(JSON.parse(data[2].acquisition_values))
      : null;
  var values2 =
    data[2] !== undefined
      ? Object.values(JSON.parse(data[2].acquisition_values))
      : null;
  const [i0, seti0] = useState(0);
  const [i1, seti1] = useState(0);
  const [i2, seti2] = useState(0);
  const [futureValueConstant0, setfutureValueConstant0] = useState();
  const [futureValueConstant1, setfutureValueConstant1] = useState();
  const [futureValueConstant2, setfutureValueConstant2] = useState();


  

  useEffect(() => {
    setfutureValueConstant0(
      values0[i0] *
        Math.pow(
          (100 - parseFloat(data[0].depreication_rate)) / 100,
          parseFloat(estimatedFutureValue)
        )
    );
    setfutureValueConstant1(
      values1[i1] *
        Math.pow(
          (100 - parseFloat(data[1].depreication_rate)) / 100,
          parseFloat(estimatedFutureValue)
        )
    );

    if (data[2] !== undefined) {
      setfutureValueConstant2(
        values2[i2] *
          Math.pow(
            (100 - parseFloat(data[2].depreication_rate)) / 100,
            parseFloat(estimatedFutureValue)
          )
      );
    }
  }, [
    i0,
    i1,
    i2,
    yearManufacture0,
    yearManufacture1,
    yearManufacture2,
    estimatedFutureValue,
  ]);

  const onYearChanged = (val, index) => {
    if (index === 0) {
      setYearManufacture0(val);
      for (var c = 0; c < values0.length; c++) {
        if (keys0[c] === val) {
          seti0(c);
        }
      }
    } else if (index === 1) {
      setYearManufacture1(val);
      for (var c = 0; c < values1.length; c++) {
        if (keys1[c] === val) {
          seti1(c);
        }
      }
    } else {
      setYearManufacture2(val);
      for (var c = 0; c < values2.length; c++) {
        if (keys2[c] === val) {
          seti2(c);
        }
      }
    }
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: "Year",
        },
      },

      y: {
        display: true,
        title: {
          display: true,
          text: `Value (${
            currency === "USD" ? "$" : currency === "GBP" ? "£" : "€"
          })`,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function () {
            return "";
          },
        },
      },
      legend: {
        position: "bottom",
      },
    },
  };

  const labels = keys1;

  const lineData = {
    labels,
    datasets:
      data[2] !== undefined
        ? [
            {
              label: data[0].aircraft_name,
              data: values0,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: data[1].aircraft_name,
              data: values1,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
              label: data[2].aircraft_name,
              data: values2,
              borderColor: "rgb(153, 82, 155)",
              backgroundColor: "rgba(153, 82, 155, 0.5)",
            },
          ]
        : [
            {
              label: data[0].aircraft_name,
              data: values0,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: data[1].aircraft_name,
              data: values1,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
  };

  const onestimatedFutureValueChanged = (val) => {
    setestimatedFutureValue(val);
  };
  return (
    <div id="acquisition_costs" className="">
      <section className={cn(global.section, global.new_page, global.page_break)}>
        <h3 className="heading-medium">Acquisition Costs</h3>
        <main className={cn(styles.aquisition_container)}>
          <div className={cn(styles.future_value,"my-14")}>
            <b>Future Value Select</b>
            <Dropdown
              disabled={hasLimitedAccess || readOnly}
              className={styles.dropdown}
              value={estimatedFutureValue}
              setValue={(value) => onestimatedFutureValueChanged(value)}
              options={FUTURE_OPTIONS}
            />
          </div>
          <div className="overflow-x-auto">
            <div className={cn(styles.compare_table)}>
              <div className={cn(styles.compare_table_column)}>
                <span
                  className={cn(
                    styles.table_column_head,
                    styles.invisible
                  )}
                >
                  invisible
                </span>
                <span
                  className={cn(
                    styles.compare_table_column_cell,
                    styles.table_key,
                    "h-[78px]"
                  )}
                >
                  Select Year
                </span>
                <span
                  className={cn(
                    styles.compare_table_column_cell,
                    styles.table_key
                  )}
                >
                  New Purchase Price
                </span>
                <span
                  className={cn(
                    styles.compare_table_column_cell,
                    styles.table_key
                  )}
                >
                  Current Value
                </span>
                <span
                  className={cn(
                    styles.compare_table_column_cell,
                    styles.table_key
                  )}
                >
                  Depreciation Rate
                </span>
                <span
                  className={cn(
                    styles.compare_table_column_cell,
                    styles.table_key
                  )}
                >
                  Future Values
                </span>
              </div>
              {data.map((aircraft, index) => {
                return (
                  <div
                    className={cn(styles.compare_table_column)}
                    key={aircraft.aircraft_id}
                  >
                    <span
                      className={cn(
                        styles.table_column_head,
                        "px-5"
                      )}
                    >
                      {aircraft.aircraft_name}
                    </span>
                    <span className={cn(styles.compare_table_column_cell)}>
                      <div className={styles.dropdown + " "}>
                        <center>
                          <Dropdown
                            disabled={hasLimitedAccess || readOnly}
                            className={styles.dropdown}
                            value={
                              index === 0
                                ? yearManufacture0
                                : index === 1
                                ? yearManufacture1
                                : index === 2
                                ? yearManufacture2
                                : null
                            }
                            setValue={(value) => onYearChanged(value, index)}
                            options={
                              index === 0 ? keys0 : index === 1 ? keys1 : keys2
                            }
                          />
                        </center>
                      </div>
                    </span>
                    <span
                      className={`${hasLimitedAccess ? "blurData select-none" : ""} ${cn(
                        styles.compare_table_column_cell,
                        "text-grey font-medium"
                      )}`}
                    >
                      {hasLimitedAccess ? "$000" : aircraft.new_purchase === 0
                        ? "-"
                        : currencySymbol + numeral(aircraft.new_purchase * conversionRate).format("0,0")
                      }
                    </span>
                    <span
                      className={`${hasLimitedAccess ? "blurData select-none" : ""} ${cn(
                        styles.compare_table_column_cell,
                        "text-grey font-medium"
                      )}`}
                    >
                      {hasLimitedAccess ? "$000" : index === 0
                        ? values0[i0] === 0
                          ? "-"
                          : currencySymbol + numeral(values0[i0] * conversionRate).format("0,0")
                        : index === 1
                        ? values1[i1] === 0
                          ? "-"
                          : currencySymbol + numeral(values1[i1] * conversionRate).format("0,0")
                        : values2[i2] === 0
                        ? "-"
                        : currencySymbol + numeral(values2[i2] * conversionRate).format("0,0")}
                    </span>
                    <span
                      className={`${hasLimitedAccess ? "blurData select-none" : ""} ${cn(
                        styles.compare_table_column_cell,
                        "text-grey font-medium"
                      )}`}
                    >
                      {hasLimitedAccess ? "00%" : `-${aircraft.depreication_rate}%`}
                    </span>
                    <span
                      className={`${hasLimitedAccess ? "blurData select-none" : ""} ${cn(
                        styles.compare_table_column_cell,
                        "text-grey font-medium"
                      )}`}
                    >
                      {hasLimitedAccess ? "$000" : index === 0
                        ? futureValueConstant0 === 0
                          ? "-"
                          : currencySymbol + numeral(futureValueConstant0 * conversionRate).format("0,0")
                        : index === 1
                        ? futureValueConstant1 === 0
                          ? "-"
                          : currencySymbol + numeral(futureValueConstant1 * conversionRate).format("0,0")
                        : futureValueConstant2 === 0
                        ? "-"
                        : currencySymbol + numeral(futureValueConstant2 * conversionRate).format("0,0")
                      }
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`${cn(global.line_chart)} ${showGraph?'':'hidden'} mt-5`}>
            <Line data={lineData} options={options} />
          </div>
          <button disabled={hasLimitedAccess} className={`${hasLimitedAccess ? "blur-[2px]" : ""} mt-4 border border-solid border-gray-300 rounded-lg shadow-md py-2 px-2 text-center text-base text-normal`} type="button" onClick={()=>setShowGraph(!showGraph)}>{showGraph ? "Hide" : "Show"} Graph</button>
        </main>
      </section>
    </div>
  );
};
export default AquisitionCost;
