import React, { useContext, useState } from 'react'
import apiService from '../../services/api'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import AppContext from '../../custom-context/appContext'

const AirportSelect = ({ mission, setQuestionsAnswers, name, q1Index }) => {
  const [selectedValue, setSelectedValue] = useState({
    label: mission[name].input || '',
    value: mission[name].icao || ''
  })
  async function searchSuggestions (inputValue) {
    return new Promise(async resolve => {
      if (!inputValue) inputValue = 'a'
      const missionAiports = [mission.airport1, mission.airport2]
      const res = await apiService.getAirportSuggestions(inputValue)
      resolve(
        res.data
          .map(({ airportName, icao }) => ({
            label: airportName,
            value: icao
          }))
          .filter(option => !missionAiports.includes(option.value))
      )
    })
  }
  const customStyles = {
    container: styles => ({
      ...styles,
      width: '230px'
    }),
    input: styles => ({
      ...styles,
      borderRadius: '8px'
    }),
    control: styles => ({
      ...styles,
      borderRadius: '8px',
      border: '1px solid rgb(209 213 219)',
      boxShadow:
        'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px'
    })
  }
  const handleChange = e => {
    setSelectedValue(e)
    setQuestionsAnswers(prev => {
      return prev.map((val, i) =>
        i === 0
          ? {
              ...val,
              answer: val.answer.map((mission, i) =>
                i === q1Index
                  ? {
                      ...mission,
                      [name]: { input: e.label, icao: e.value }
                    }
                  : mission
              )
            }
          : val
      )
    })
  }
  return (
    <AsyncSelect
      styles={customStyles}
      cacheOptions
      defaultOptions
      name={name}
      onChange={handleChange}
      loadOptions={searchSuggestions}
      value={selectedValue}
      menuPosition='fixed'
    />
  )
}
const Answers = ({ currentIndex, questionsAnswers, setQuestionsAnswers }) => {
  const { userLevel } = useContext(AppContext)
  const handleInputChange = (e, q1Index) => {
    let value = e.target.value
    const name = e.target.name
    let max = e.target.max
    let min = e.target.min
    if (
      [1, 2, 4, 6].includes(currentIndex) ||
      (currentIndex === 5 && name === 'minimum_pilots') ||
      (currentIndex === 0 && name === 'timesPerYear') ||
      (currentIndex === 3 && name === 'budget') ||
      (currentIndex === 9 && name === 'results')
    ) {
      value = Number(value)
      max = Number(max)
      min = Number(min)
      if (min && value < min) value = min
      else if (value < 0 || isNaN(value)) value = 0
      if (max && value > max) value = max
    }
    setQuestionsAnswers(prev => {
      if (currentIndex === 0) {
        if (name === 'return') {
          value = e.target.checked
        }
        return prev.map((val, i) =>
          i === currentIndex
            ? {
                ...val,
                answer: val.answer.map((mission, i) =>
                  i === q1Index
                    ? {
                        ...mission,
                        [name]: ['airport1', 'airport2'].includes(name)
                          ? { input: value, icao: mission[name].icao }
                          : value
                      }
                    : mission
                )
              }
            : val
        )
      } else if (currentIndex === 3) {
        if (name === 'inculde_depreciation') {
          value = e.target.checked
        }
        return prev.map((val, i) =>
          i === currentIndex
            ? {
                ...val,
                answer: {
                  ...val.answer,
                  [name]: value
                }
              }
            : val
        )
      } else if (currentIndex === 5) {
        if (name !== 'minimum_pilots') {
          value = e.target.checked
        }
        return prev.map((val, i) =>
          i === currentIndex
            ? {
                ...val,
                answer: {
                  ...val.answer,
                  [name]: value
                }
              }
            : val
        )
      } else if (currentIndex === 9) {
        return prev.map((val, i) =>
          i === currentIndex
            ? {
                ...val,
                answer: {
                  ...val.answer,
                  [name]: value
                }
              }
            : val
        )
      } else {
        return prev.map((val, i) =>
          i === currentIndex ? { ...val, answer: value } : val
        )
      }
    })
  }
  const handleAddMission = () => {
    // Add a new row to the table
    setQuestionsAnswers(prev => {
      return prev.map((item, i) => {
        if (i === currentIndex) {
          // Add a new entry to the answer array
          return {
            ...item,
            answer: [
              ...item.answer,
              {
                airport1: { input: '', icao: '' },
                airport2: { input: '', icao: '' },
                timesPerYear: 0,
                return: false
              }
            ]
          }
        }
        return item
      })
    })
  }
  const handleDeleteMission = rowIndex => {
    // Delete a row from the table
    setQuestionsAnswers(prev => {
      return prev.map((item, i) => {
        if (i === currentIndex) {
          // Remove the row at the specified index
          const updatedAnswer =
            item.answer.length === 1
              ? item.answer
              : item.answer.filter((row, index) => index !== rowIndex)
          return {
            ...item,
            answer: updatedAnswer
          }
        }
        return item
      })
    })
  }
  const id = questionsAnswers[currentIndex].id
  // const question = questionsAnswers[currentIndex].question
  const answer = questionsAnswers[currentIndex].answer
  // console.log(id, question, answer)
  const textarePlaceholder =
    id === 8
      ? 'Each passenger will have one large suitcase and one small backpack'
      : id === 9
      ? 'Prioritize aircraft with a high cruise speed'
      : 'Answer...'
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 9].includes(currentIndex) ? (
        <div className='px-4 py-1'>
          {[1, 2, 4, 6].includes(currentIndex) ? (
            <>
              {[2].includes(currentIndex) ? '$' : ''}
              &nbsp;
              <input
                autoFocus
                type='number'
                className='border border-solid border-gray-300 rounded-lg shadow-md py-2 px-2 text-center text-base text-normal w-32'
                value={answer.toString()}
                onChange={handleInputChange}
                min={0}
                max={currentIndex === 1 ? 19 : ''}
              />
              &nbsp;
              {currentIndex === 1 ? 'Passengers' : ''}
              {[2, 3].includes(currentIndex) ? 'Million' : ''}
              {[4, 6].includes(currentIndex) ? 'years' : ''}
            </>
          ) : currentIndex === 3 ? (
            <>
              $ &nbsp;
              <input
                autoFocus
                type='number'
                className='border border-solid border-gray-300 rounded-lg shadow-md py-2 px-2 text-center text-base text-normal w-32'
                value={answer.budget.toString()}
                name={'budget'}
                onChange={handleInputChange}
                min={0}
                max={currentIndex === 1 ? 19 : ''}
              />
              &nbsp;Million
              {currentIndex === 3 ? (
                <div className='inline-flex ml-2 pl-2 border-l-2 gap-2 items-center'>
                  <span>Inc. depreciation?</span>
                  <input
                    autoFocus
                    type='checkbox'
                    name='inculde_depreciation'
                    className='cursor-pointer size-5'
                    checked={answer.inculde_depreciation}
                    onChange={handleInputChange}
                  />
                </div>
              ) : (
                ''
              )}
            </>
          ) : currentIndex === 0 ? (
            <>
              <div className='overflow-x-auto scrollbar-size py-5'>
                <table className='w-full min-w-[700px]'>
                  <thead>
                    <tr>
                      <th>Departure Airport</th>
                      <th>Arrival Airport</th>
                      <th>Times per Year</th>
                      <th>Return</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='*:*:py-2 *:*:px-2  *:*:text-center'>
                    {answer.map((mission, index) => (
                      <tr key={index}>
                        <td>
                          <div className='flex justify-center'>
                            <AirportSelect
                              mission={questionsAnswers[0].answer[index]}
                              setQuestionsAnswers={setQuestionsAnswers}
                              name='airport1'
                              q1Index={index}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='flex justify-center'>
                            <AirportSelect
                              mission={questionsAnswers[0].answer[index]}
                              setQuestionsAnswers={setQuestionsAnswers}
                              name='airport2'
                              q1Index={index}
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            autoFocus
                            type='number'
                            name='timesPerYear'
                            value={mission.timesPerYear.toString()}
                            className='border border-solid border-gray-300 rounded-lg shadow-md py-2 px-2 text-center text-base text-normal w-32'
                            onChange={e => handleInputChange(e, index)}
                            min={0}
                          />
                        </td>
                        <td>
                          <input
                            autoFocus
                            type='checkbox'
                            name='return'
                            className='cursor-pointer size-5'
                            checked={mission.return}
                            onChange={e => handleInputChange(e, index)}
                          />
                        </td>
                        <td>
                          <button
                            onClick={() => handleDeleteMission(index)}
                            title='Remove Mission'
                          >
                            <i className='fa-regular fa-trash-can text-red-500'></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-4 w-full text-center'>
                {(userLevel === 'jet_finder' ? answer.length < 3 : true) && (
                  <button
                    className='py-1 px-6 bg-[#8000FF] text-white rounded-md'
                    onClick={handleAddMission}
                  >
                    Add Mission
                  </button>
                )}
              </div>
            </>
          ) : currentIndex === 5 ? (
            <table className='capitalize'>
              <tbody className='*:*:py-2 *:*:px-2'>
                {Object.entries(answer).map(([key, val], i) => {
                  return (
                    <tr key={i} className='capitalize'>
                      <td>
                        <label htmlFor={key}>{key.replaceAll('_', ' ')}</label>
                      </td>
                      <td>
                        {key === 'minimum_pilots' ? (
                          <input
                            autoFocus
                            type='number'
                            className='border border-solid border-gray-300 rounded-lg shadow-md py-2 px-2 text-center text-base text-normal w-32'
                            name={key}
                            id={key}
                            value={val.toString()}
                            onChange={handleInputChange}
                            min={1}
                            max={2}
                          />
                        ) : (
                          <input
                            autoFocus
                            type='checkbox'
                            className='size-5'
                            checked={val}
                            name={key}
                            id={key}
                            onChange={handleInputChange}
                          />
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : currentIndex === 9 ? (
            <div className='overflow-x-auto scrollbar-size py-5'>
              <table className='w-full min-w-[700px]'>
                <thead>
                  <tr>
                    <th>
                      <div>Results</div>
                      <div className='text-[#B6B8BD] text-xs font-normal'>
                        ( Limit )
                      </div>
                    </th>
                    <th>
                      <div>Overall Match</div>
                      <div className='text-[#B6B8BD] text-xs font-normal'>
                        ( % or above )
                      </div>
                    </th>
                    <th>
                      <div>Mission Match</div>
                      <div className='text-[#B6B8BD] text-xs font-normal'>
                        ( % or above )
                      </div>
                    </th>
                    <th>
                      <div>Feature Match</div>
                      <div className='text-[#B6B8BD] text-xs font-normal'>
                        ( % or above )
                      </div>
                    </th>
                    <th>
                      <div>Budget Match</div>
                      <div className='text-[#B6B8BD] text-xs font-normal'>
                        ( % or above )
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='*:*:py-2 *:*:px-3  *:*:text-center'>
                  <tr>
                    {Object.entries(answer).map(([key, val], i) => {
                      return (
                        <td key={i}>
                          <div className='w-full flex items-center gap-[3px]'>
                            {key === 'results' ? (
                              <input
                                autoFocus
                                type='number'
                                className='flex-1 border border-solid border-gray-300 rounded-lg shadow-md py-2 px-2 text-center text-base text-normal'
                                name={key}
                                id={key}
                                value={val.toString()}
                                onChange={handleInputChange}
                                min={1}
                                max={20}
                              />
                            ) : (
                              <Select
                                className='flex-1'
                                defaultValue={{ value: 0, label: 'Default' }}
                                menuPosition='fixed'
                                onChange={option =>
                                  handleInputChange({
                                    target: { name: key, value: option.value }
                                  })
                                }
                                name={key}
                                options={[
                                  { value: 0, label: 'Default' },
                                  { value: 90, label: '90%' },
                                  { value: 80, label: '80%' },
                                  { value: 70, label: '70%' },
                                  { value: 60, label: '60%' },
                                  { value: 50, label: '50%' }
                                ]}
                                styles={{
                                  input: styles => ({
                                    ...styles,
                                    borderRadius: '8px'
                                  }),
                                  control: styles => ({
                                    ...styles,
                                    borderRadius: '8px',
                                    border: '1px solid rgb(209 213 219)',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px'
                                  })
                                }}
                              />
                            )}
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <textarea
          autoFocus
          value={answer}
          onChange={handleInputChange}
          className='w-full px-4 py-1 min-h-20 outline-none resize-none bg-transparent'
          placeholder={textarePlaceholder}
        />
      )}
    </>
  )
}

export default Answers
