import React, { useEffect, useState } from 'react'
import { VectorMap } from '@react-jvectormap/core'
import { worldMill } from '@react-jvectormap/world'

const transformData = data => {
  return Object.fromEntries(
    data.map(({ countryCode, aircraft }) => {
      return [countryCode, aircraft]
    })
  )
}
const CountryHeatMap = ({ data }) => {
  const [transformedData, setTransformedData] = useState(transformData(data))
  console.log(data)
  console.log(transformedData)
  useEffect(() => {
    setTransformedData(transformData(data))
  }, data)
  return (
    <VectorMap
      map={worldMill}
      backgroundColor='#262626' // Change the background color
      series={{
        regions: [
          {
            values: transformedData,
            scale: ['#8a932e', '#ff0000'], // From dark to red
            normalizeFunction: 'polynomial',
            attribute: 'fill'
          }
        ]
      }}
      regionStyle={{
        initial: {
          fill: '#090909' // Default color for countries not in countryValues
        }
      }}
      onRegionTipShow={(e, el, code) => {
        // Show only the country name if there's no value
        const value = transformedData[code]
        if (value || value === 0) {
          el.html(`${el.html()} - ${value}`)
        } else {
          el.html(el.html())
        }
      }}
    />
  )
}

export default CountryHeatMap
