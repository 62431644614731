import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import baseStyles from "./styles/styles.module.scss";
import styles from "../SingleAircraft/styles/styles.module.scss";
import numeral from "numeral";
import OwnershipCostsPieChart from "../common/OwnershipCostsPieChart";
import CardHeading from "../SingleAircraft/CardHeading";
import { RiLoopRightLine } from "react-icons/ri";
import { CiCalculator2 } from "react-icons/ci";
import { AiOutlineCalculator } from "react-icons/ai";

  
const CostsBreakDown = ({title, headIcon,index, rows, readOnly=false, currencySymbol, hasLimitedAccess,aircraftCostValues,setAircraftCostValues, resetValues})=>{
  const percentageOfTotalCost = (value, highestValue) => {
    return ((value / highestValue) * 100).toFixed(0)
  }
  return (
    <div className="space-y-10">
      <div className="flex">
        <CardHeading title={title} subTitle={'Breakdown'} icon={headIcon} />
        <button className={cn("re_interact_button text-sm !gap-1 whitespace-nowrap h-fit my-auto")} disabled={readOnly} onClick={() =>!readOnly && resetValues()}>
          Reset Values
          <RiLoopRightLine size={14} />
        </button>
      </div>
      <table className="table-auto w-full overflow-x-scroll">
        <tbody>
          {
            rows.map(({displayName, variable, color})=>(
              <tr>
                <td className="sm:whitespace-nowrap lg:whitespace-normal px-2 pb-2">{displayName}</td>
                <td className="whitespace-nowrap px-2 xl:pl-4 pb-2">
                  <span className="mr-2">{currencySymbol}</span>
                  <span className={`${hasLimitedAccess ? "blurData" : ""}`}>
                    <input
                      className={`${styles.cost_input} lg:max-w-24`}
                      type="text"
                      name={variable}
                      index={index}
                      placeholder={displayName}
                      value={hasLimitedAccess ? "Null" : numeral(aircraftCostValues[variable][index]).format("0,0")}
                      onInput={(e)=>!readOnly && ( !hasLimitedAccess && handleInputChange(e, index, setAircraftCostValues))}
                      disabled={readOnly || hasLimitedAccess}
                    />
                  </span>
                </td>
                <td className="w-full px-2 pb-2">
                  <div className="rounded-2xl overflow-hidden bg-[#e6e4e5] h-2 w-full">
                    <div className="h-full" style={{width: `${percentageOfTotalCost(aircraftCostValues[variable][index],Math.max(...aircraftCostValues[variable]))}%`, background: color}}></div>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
  </div>
  )
}
const handleInputChange = (event, index, setAircraftCostValues) => {
  const { name, value } = event.target;
  let newValue = parseFloat(value.replace(",", ""));
  setAircraftCostValues(prevValues => ({
    ...prevValues,
    [name]: prevValues[name].map((oldValue,i)=>i===index ?newValue: oldValue) 
  }));
};

const OwnershipCost = ({estimatedHours,setEstimatedHours, data, region, conversionRate, currencySymbol, unit, setNbHoursProp,hasLimitedAccess = false, readOnly=false}) => {

  const [aircraftCostValues, setAircraftCostValues] = useState(calculateAircraftCostsValues());

  const totalCosts = useMemo(()=>(
    data.map((aircraft,i)=>{
      const fixed = [
        aircraftCostValues.crewSalary[i], 
        aircraftCostValues.crewTraining[i], 
        aircraftCostValues.hangar[i], 
        aircraftCostValues.insuranceHull[i], 
        aircraftCostValues.insuranceLiability[i], 
        aircraftCostValues.management[i], 
        aircraftCostValues.miscFixed[i]
      ].reduce((a, b) => a + b, 0);
      const variable = [
        aircraftCostValues.fuelCost[i], 
        aircraftCostValues.maintenance[i], 
        aircraftCostValues.engOverhaul[i], 
        aircraftCostValues.groundFees[i], 
        aircraftCostValues.miscVar[i]
      ].reduce((a, b) => a + b, 0);
      return {
        fixed,
        variable
      }
    })
  ),[data, aircraftCostValues]);

  function regionInitials(region) {
    const regionMap = {
      "North America": "NA",
      "South America": "SA",
      "Europe": "EU",
      "Asia": "AS"
    };
    
    return regionMap[region]
  }

  function getConvertedValuesForParameter (parameter) {
    return data.map((aircraft) => aircraft[`${regionInitials(region)}_${parameter}`] * conversionRate)
  }

  function calculateAircraftCostsValues (){
    const employBenefit = getConvertedValuesForParameter("annual_employee_benefits")
    const annualCaptain = getConvertedValuesForParameter("annual_captain")
    const annualFirstOffice = getConvertedValuesForParameter("annual_first_office")
    const crewSalary = [0,0,0].map((_,i)=>{
      return employBenefit[i] + annualCaptain[i] + annualFirstOffice[i]
    })
    return {
      crewTraining: getConvertedValuesForParameter("annual_crew_training"),
      hangar: getConvertedValuesForParameter("annual_hangar"),
      insuranceHull: getConvertedValuesForParameter("annual_insurance_hull"),
      insuranceLiability: getConvertedValuesForParameter("annual_insurance_liability"),
      management: getConvertedValuesForParameter("annual_management"),
      miscFixed: getConvertedValuesForParameter("annual_misc"),
      fuelCost: getConvertedValuesForParameter("hourly_fuel"),
      maintenance: getConvertedValuesForParameter("hourly_maintenance"),
      engOverhaul: getConvertedValuesForParameter("hourly_engine_overhaul"),
      groundFees: getConvertedValuesForParameter("hourly_ground_fees"),
      miscVar: getConvertedValuesForParameter("hourly_misc"),
      annualTotal: getConvertedValuesForParameter("annual_total"),
      hourlyTotal: getConvertedValuesForParameter("hourly_total"),
      crewSalary: crewSalary,
    }
  }
  const resetValues = () => {
    console.log("resetting values");
    setAircraftCostValues(calculateAircraftCostsValues());
  }

  useEffect(() => {
    console.log(aircraftCostValues);
  }, [aircraftCostValues]);

  useEffect(() => {
    if (!data || !region) return;
    resetValues()
  }, [data, region]);
  
  const handleUpdateEstimatedHours = (e)=>{
    const value = parseInt(e.target.value);
    if (value >= 0){
      setEstimatedHours(value)
    }
  }
  return (
    <>
    {Object.keys(aircraftCostValues).length > 0 && (
      <div id="ownership_costs" className="">
        <h3 className="heading-medium mb-12">Ownership Costs</h3>
        <div className="flex gap-4 justify-center items-center text-center mb-8">
          <h4 className="font-semibold">Annual Flight Hours:</h4>
          <input
            className={styles.cost_input}
            type="number"
            name="nbHours"
            min={0}
            placeholder="Enter hours"
            value={estimatedHours}
            onChange={(e)=> !readOnly && handleUpdateEstimatedHours(e)}
            disabled={readOnly}
          />
        </div>
        <div className={`grid grid-cols-1 lg:grid-cols-${data.length} gap-15`}>
          {
            data.map((aircraft,i)=>{
              const totalVariableCost =  totalCosts[i].variable;
              const totalFixedCost =  totalCosts[i].fixed;
              const fixedCostsData = [
                {
                  label: 'Crew Salary',
                  color: 'rgba(255, 99, 132, 1)',
                  value: aircraftCostValues.crewSalary[i],
                },
                {
                  label: 'Crew Training',
                  color: 'rgba(54, 162, 235, 1)',
                  value: aircraftCostValues.crewTraining[i],
                },
                {
                  label: 'Hangar',
                  color: 'rgba(255, 206, 86, 1)',
                  value: aircraftCostValues.hangar[i],
                },
                {
                  label: 'Insurance',
                  color: 'rgba(75, 192, 192, 1)',
                  value: aircraftCostValues.insuranceHull[i] + aircraftCostValues.insuranceLiability[i],
                },
                {
                  label: 'Management',
                  color: 'rgba(153, 102, 255, 1)',
                  value: aircraftCostValues.management[i],
                },
                {
                  label: 'Miscellaneous Fixed',
                  color: 'rgba(255, 159, 64, 1)',
                  value: aircraftCostValues.miscFixed[i],
                },
              ]
              const variableCostsData = [
                {
                  label: 'Fuel Cost',
                  color: 'rgba(255, 99, 132, 1)',
                  value: aircraftCostValues.fuelCost[i],
                },
                {
                  label: 'Maintenance',
                  color: 'rgba(54, 162, 235, 1)',
                  value: aircraftCostValues.maintenance[i],
                },
                {
                  label: 'Engine Overhaul',
                  color: 'rgba(255, 206, 86, 1)',
                  value: aircraftCostValues.engOverhaul[i],
                },
                {
                  label: 'Ground Fees',
                  color: 'rgba(75, 192, 192, 1)',
                  value: aircraftCostValues.groundFees[i],
                },
                {
                  label: 'Miscellaneous Variable',
                  color: 'rgba(153, 102, 255, 1)',
                  value: aircraftCostValues.miscVar[i],
                },
              ]
              return (
                <div key={i} className="flex flex-col gap-15">
                  <div className="space-y-6">
                    <h5 className="font-semibold text-center">{aircraft.aircraft_name}</h5>
                    <div className="flex gap-4 justify-center">
                      <p className="pr-4 text-right font-bold">Annual Operating Costs</p>
                      <p className={`pl-1 own-cost-output ${hasLimitedAccess ? "blurData" : ""}`}>
                        {hasLimitedAccess ? "Null" : `${currencySymbol}${numeral(totalFixedCost + (estimatedHours * totalVariableCost)).format("0,0")}`}
                      </p>
                    </div>
                  </div>
                  <OwnershipCostsPieChart
                    title={'Fixed Costs'}
                    totalValue={`${currencySymbol}${numeral(totalFixedCost).format("0,0")}`}
                    chartData={fixedCostsData}
                    hasLimitedAccess={hasLimitedAccess}
                    titleClassName={data.length > 2 && "lg:!text-base"}
                    totalValueClassName={data.length > 2 && "lg:!text-3xl"}
                  />
                  <OwnershipCostsPieChart
                    title={'Variable Costs'}
                    totalValue={`${currencySymbol}${numeral(totalVariableCost).format("0,0")}`}
                    chartData={variableCostsData}
                    hasLimitedAccess={hasLimitedAccess}
                    titleClassName={data.length > 2 && "lg:!text-base"}
                    totalValueClassName={data.length > 2 && "lg:!text-3xl"}
                  />   
                  <CostsBreakDown
                    title={'Fixed Costs'}
                    headIcon={AiOutlineCalculator}
                    index={i}
                    readOnly={readOnly}
                    currencySymbol={currencySymbol}
                    hasLimitedAccess={hasLimitedAccess}
                    aircraftCostValues={aircraftCostValues}
                    setAircraftCostValues={setAircraftCostValues}
                    resetValues={resetValues}
                    rows={[
                      {
                        displayName: "Crew Salary",
                        variable: "crewSalary",
                        color: "#FF577A",
                      },
                      {
                        displayName: "Crew Training",
                        variable: "crewTraining",
                        color: "#2999E6",
                      },
                      {
                        displayName: "Hangar",
                        variable: "hangar",
                        color: "#FFC651",
                      },
                      {
                        displayName: "Insurance Hull",
                        variable: "insuranceHull",
                        color: "#3AB8B8",
                      },
                      {
                        displayName: "Insurance Liability",
                        variable: "insuranceLiability",
                        color: "#3AB8B8",
                      },
                      {
                        displayName: "Management",
                        variable: "management",
                        color: "#925DFD",
                      },
                      {
                        displayName: "Miscellaneous Fixed",
                        variable: "miscFixed",
                        color: "#FF943D",
                      },
                    ]} 
                  />
                  <CostsBreakDown
                    title={'Variable Costs'}
                    headIcon={CiCalculator2}
                    index={i}
                    readOnly={readOnly}
                    currencySymbol={currencySymbol}
                    hasLimitedAccess={hasLimitedAccess}
                    aircraftCostValues={aircraftCostValues}
                    setAircraftCostValues={setAircraftCostValues}
                    resetValues={resetValues}
                    rows={[
                      {
                        displayName: "Fuel Cost",
                        variable: "fuelCost",
                        color: "#FF577A",
                      },
                      {
                        displayName: "Maintenance",
                        variable: "maintenance",
                        color: "#2999E6",
                      },
                      {
                        displayName: "Engine Overhaul",
                        variable: "engOverhaul",
                        color: "#FFC651",
                      },
                      {
                        displayName: "Ground Fees",
                        variable: "groundFees",
                        color: "#3AB8B8",
                      },
                      {
                        displayName: "Miscellaneous Variable",
                        variable: "miscVar",
                        color: "#925DFD",
                      },
                    ]} 
                  />
                </div>
              )
            })
          }
        </div>
      </div>
      )}
    </>
  );
};
export default OwnershipCost;