import React from 'react'
import '@google/model-viewer'

const GLBAircraftModelViewer = ({ src, poster }) => {
  return (
    <model-viewer
      src={src}
      ar-modes='webxr scene-viewer quick-look'
      camera-controls
      poster={poster}
      shadow-intensity='1'
      tone-mapping='neutral'
      camera-orbit='330deg 88deg 2m'
      style={{ width: '100%', height: '100%' }}
    />
  )
}

export default GLBAircraftModelViewer
