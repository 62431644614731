import React from "react";
import cn from "classnames";
import styles from "./styles/styles.module.scss";
import { useState, useEffect } from "react";
import GLBAircraftModelViewer from "../common/GLBAircraftModelViewer";

const KeyFacts = ({ data, comparisonIds, readOnly }) => {
  console.log(comparisonIds.filter((id) => id !== `753`).join(","))
  const [aircraftsData, setAircraftsData] = useState(data);
  const [keyFacts0, setKeyFacts0] = useState([]);
  const [keyFacts1, setKeyFacts1] = useState([]);
  const [keyFacts2, setKeyFacts2] = useState([]);

  useEffect(() => {
    if (aircraftsData[0] !== undefined) {
      setKeyFacts0(aircraftsData[0].key_facts.split("\n"));
      setKeyFacts1(aircraftsData[1].key_facts.split("\n"));
      if (aircraftsData[2] !== undefined) {
        setKeyFacts2(aircraftsData[2].key_facts.split("\n"));
      }
    } 
  }, [data[2]]);

  return (
    <div className={`mt-8 grid lg:grid-cols-${aircraftsData.length} gap-10`}>
      {aircraftsData.map((aircraft, index) => {
        return (
          <div key={aircraft.aircraft_id} className={`flex flex-col gap-8`}>
            {!readOnly && aircraftsData.length > 2 && 
              <a href={`/compare?aircrafts=${comparisonIds.filter((id) => id !== `${aircraft.id}`).join(",")}`} className="mx-auto">
                <button className={cn(styles.btn)} >
                  Remove Aircraft
                </button>
              </a>
            }
            <div className={`w-full aspect-[500/192] max-h-48`}>
              <GLBAircraftModelViewer src={aircraft.aircraft_3d_model} poster={aircraft.aircraft_image} />
            </div>
            <div>
              <p className="text-oldSilver text-lg">{aircraft?.aircraft_manufacturer}</p>
              <h4 className="text-2xl lg:text-4xl font-bold">{aircraft.aircraft_name}</h4>
            </div>
            <ul className="list-inside list-disc text-lg text-oldSilver">
              {index === 0
                ? keyFacts0.map((fact, index) => {
                    return <li key={index}>{fact}</li>;
                  })
                : index === 1
                ? keyFacts1.map((fact, index) => {
                    return <li key={index}>{fact}</li>;
                  })
                : keyFacts2.map((fact, index) => {
                    return <li key={index}>{fact}</li>;
                  })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
export default KeyFacts;
