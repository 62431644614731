const StatComponent = ({ stat }) => {
  return (
    <div className='grid grid-cols-2 gap-5 pt-5 pb-6'>
      <p className='font-bold text-CharlestonGreen'>{stat.title}</p>
      <div className='text-grey'>
        {Array.isArray(stat.content) ? (
          <div className='flex flex-col gap-4'>
            {stat.content.map(({ title, content }) => (
              <div className='grid grid-cols-[40%,1fr] gap-2'>
                <p className="break-words">{title}</p>
                <p className={`${stat.blurValue ? 'blurData px-2' : ''}`}>
                  {stat.blurValue ? 'Null' : content}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className={`${stat.blurValue ? 'blurData px-2' : ''}`}>
            {stat.blurValue ? 'Null' : stat.content}
          </p>
        )}
      </div>
    </div>
  )
}
//  stats a list of object containing title, content, blurValue, content can be a string or a array of object of title, and content
const AircraftsTechnicalInfoCard = ({ title, stats = [] }) => {
  return (
    <div className='w-full grid md:grid-cols-[34%,1fr] gap-10'>
      <h3 className='heading-medium'>{title}</h3>
      <div className='w-full divide-y divide-gainsboro'>
        {stats.map((stat, i) => (
          <StatComponent key={i} stat={stat} />
        ))}
      </div>
    </div>
  )
}

export default AircraftsTechnicalInfoCard
