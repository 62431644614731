import cn from "classnames";
import styles from "./Footer.module.scss";
import cropped_logo_white from "../../../assets/cropped-logo-white.webp";

const Footer = () => {
  return (
    <div className={cn(styles.footer, "flex justify-center p-10 md:p-16")}>
      <div className="container grid gap-12 xl:grid-cols-2 xl:gap-8">
        <div className={cn(styles.left_col)}>
          <div>
            <img src={cropped_logo_white} alt="" />

            <p>71–75 Shelton Street, London, WC2H 9JQ, UK</p>
            <p><a href="tel:442080505015">+44 20 8050 5015</a></p>
            <p><a href="mailto:contact@compareprivateplanes.com">contact@compareprivateplanes.com</a></p>
          </div>
          
          <p className={cn(styles.copyright_text)}>© Magic Lagoon Limited, 2024 All rights reserved</p>
        </div>

        <div className="grid gap-8 lg:gap-12">
          <div>
            <h3>Important Info</h3>
            <p className={cn(styles.copyright_text)}>Performance data and recommendations from this platform are for informational purposes only and should not be used for actual flight planning. The platform's cost analyses are simulations and may differ from real world aircraft costs. There are multiple factors that will affect the cost of operation, including but not limited to region, airports, aircraft history, and more.</p>
          </div>
        </div>




        

      </div>
    </div>
  );
};

export default Footer;
