import React, { useState, useContext } from 'react'
import apiService from '../../services/api'
import cropped_logo_blue from '../../assets/cropped-logo-blue.png'
import AircraftCard from '../../components/aiAircraftSearch/AircraftCard'
import Answers from '../../components/aiAircraftSearch/Answers'
import AppContext from '../../custom-context/appContext'
import Cookies from 'js-cookie'
import { AUTHENTICATION_COOKIE_KEY } from '../../utils/constants/app-constants'

const questionsToAsk = [
  {
    id: 1,
    question:
      'What missions will you be flying per year? List every airport along with the frequency per year',
    answer: [
      {
        airport1: { input: '', icao: '' },
        airport2: { input: '', icao: '' },
        timesPerYear: 0,
        return: false
      }
    ]
  },
  {
    id: 2,
    question: 'How many passengers will you want to fly with?',
    answer: 0
  },
  {
    id: 3,
    question: 'What is your acquisition budget?',
    answer: 0
  },
  {
    id: 4,
    question: 'How much are you willing to spend per year in operating costs?',
    answer: {
      budget: 0,
      inculde_depreciation: false
    }
  },
  {
    id: 5,
    question: 'How long do you plan to have the aircraft for?',
    answer: 0
  },
  {
    id: 6,
    question: 'Which of these features are non-negotiable?',
    answer: {
      minimum_pilots: 1,
      toilet_available: false,
      shower_available: false,
      space_to_sleep: false,
      flat_floor: false,
      inflight_baggage_access: false,
      dedicated_bedroom: false
    }
  },
  {
    id: 7,
    question:
      'What is the maximum age of aircraft that you are willing to accept?',
    answer: 0
  },
  {
    id: 8,
    question: 'How much baggage will you be carrying on average?',
    answer: ''
  },
  {
    id: 9,
    question: 'Any additional preferences or details?',
    answer: ''
  },
  {
    id: 10,
    question: 'Adjust your results output',
    answer: {
      results: 5,
      overall_match: 0,
      mission_match: 0,
      feature_match: 0,
      budget_match: 0
    }
  }
]

export default function AiArcraftSearch () {
  const {
    aiSearchAircraftLimitation,
    setAiSearchAircraftLimitation,
    isUnlimitedAiSearchAircraft
  } = useContext(AppContext)
  const [questionsAnswers, setQuestionsAnswers] = useState([...questionsToAsk])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [aircrafts, setAircrafts] = useState([])
  const [foundMatch, setFoundMatch] = useState(false)
  const [hasDoneLookup, setHasDoneLookup] = useState(false)
  const [aircraftSearchInProgress, setAircraftSearchInProgress] =
    useState(false)
  const [activeSort, setActiveSort] = useState({
    field: '',
    dir: ''
  })
  const cookies = Cookies.get()
  const [, loggedInUserLogin] = Object.entries(cookies).find(([key]) =>
    key.includes(AUTHENTICATION_COOKIE_KEY)
  ) || [undefined, undefined]
  const userName = decodeURI(loggedInUserLogin)
  const fetchAircrafts = async () => {
    setHasDoneLookup(true)
    setAircraftSearchInProgress(true)
    const response = await apiService.aiSearchAircrafts(
      questionsAnswers,
      !isUnlimitedAiSearchAircraft ? `username=${userName}` : ''
    )
    if (response.data.length === 0) {
      setAircraftSearchInProgress(false)
      setFoundMatch(false)
      return
    } else {
      setAircrafts(response.data)
      setFoundMatch(true)
    }
    setAircraftSearchInProgress(false)
  }
  const handleIndexChange = type => {
    if (type === 'prev') {
      if (currentIndex === 0) return
      setCurrentIndex(currentIndex - 1)
    } else if (type === 'reset') {
      setCurrentIndex(0)
      setQuestionsAnswers(questionsToAsk)
      setAircrafts([])
      setFoundMatch(false)
      setHasDoneLookup(false)
    } else if (type === 'next') {
      if (currentIndex === questionsAnswers.length - 1) {
        if (aiSearchAircraftLimitation > 0 || isUnlimitedAiSearchAircraft) {
          fetchAircrafts()
          if (!isUnlimitedAiSearchAircraft)
            setAiSearchAircraftLimitation(aiSearchAircraftLimitation - 1)
          console.log('submit value is: ', questionsAnswers)
        }
      } else {
        setCurrentIndex(currentIndex + 1)
      }
    }
  }
  const setSort = field => {
    activeSort.field !== field
      ? setActiveSort({ field, dir: 'asc' })
      : activeSort.dir === 'asc'
      ? setActiveSort({ field, dir: 'desc' })
      : setActiveSort({ field: '', dir: '' })
  }
  const sortingOptions = [
    {
      field: 'overall_match',
      text: 'Overall Match'
    },
    {
      field: 'missions_score',
      text: 'Mission Match'
    },
    {
      field: 'features_score',
      text: 'Features Match'
    },
    {
      field: 'budget_score',
      text: 'Budget Match'
    },
    {
      field: 'annual_operating_cost',
      text: 'Annual Cost'
    },
    {
      field: 'new_purchase',
      text: 'Purchase Price'
    },
    {
      field: 'total_ownership_cost',
      text: 'Total Ownership Cost'
    }
  ]
  return (
    <div className='min-h-screen flex flex-col'>
      <div className='w-100 py-8 px-4 md:px-8 flex flex-col md:gap-6 gap-8'>
        <div className='flex flex-col justify-around items-center py-12 gap-6'>
          <h2 className='flex items-center gap-2 text-center text-3xl md:text-6xl font-bold'>
            <span>
              Answer the Following <br />
              Questions to Find the Right Jet
            </span>
            {/*<span
              className='h-10 w-fit change ai_beta_tag'
              style={{ minWidth: '80px' }}
            >
              AI Beta
            </span>*/}
          </h2>
          <button
            className='flex gap-2 items-center border border-[#EBECF2] bg-white rounded-xl py-2 px-6'
            onClick={() => handleIndexChange('reset')}
          >
            <i className='fa-solid fa-rotate text-[#B2B5BA]'></i>
            <span>Reset</span>
          </button>
          <div className='flex flex-col gap-5 w-full max-w-[850px] text-base'>
            <div
              className='relative border border-[#EBECF2] rounded-xl py-5'
              style={{ boxShadow: '0px 21px 19px -9px #0000000D' }}
            >
              <span className='absolute right-2 top-1 text-xs text-[#B2B5BA]'>
                {currentIndex + 1}/{questionsAnswers.length}
              </span>
              <h4 className='px-4 pb-3 font-bold'>
                {questionsAnswers[currentIndex].question}
              </h4>
              <div className='min-h-20 w-full'>
                <Answers
                  currentIndex={currentIndex}
                  setQuestionsAnswers={setQuestionsAnswers}
                  questionsAnswers={questionsAnswers}
                />
              </div>
            </div>
            <div className='flex justify-between'>
              <button
                className='border border-[#EBECF2] bg-white rounded-xl py-2 px-6'
                style={{
                  visibility: currentIndex === 0 ? 'hidden' : 'visible'
                }}
                onClick={() => handleIndexChange('prev')}
              >
                Back
              </button>
              <button
                className='border border-[#EBECF2] bg-white rounded-xl py-2 px-6'
                onClick={() => handleIndexChange('next')}
              >
                {currentIndex === questionsAnswers.length - 1
                  ? 'Submit'
                  : 'Next'}
              </button>
            </div>
          </div>
        </div>

        {aircraftSearchInProgress && (
          <div className='w-full py-12 flex flex-col justify-center items-center'>
            <i className='fa fa-spinner fa-spin text-6xl'></i>
            <h3 className='text-xl mt-6'>Finding the best aircraft...</h3>
          </div>
        )}

        {!foundMatch && !aircraftSearchInProgress && hasDoneLookup && (
          <div className='w-full py-12 flex flex-col justify-center items-center'>
            <i className='fa fa-solid fa-plane-circle-exclamation text-4xl'></i>
            <h3 className='text-xl mt-6'>No related aicraft found.</h3>
          </div>
        )}
        {foundMatch && !aircraftSearchInProgress && (
          <>
            <div className='flex gap-4 lg:flex-wrap lg:justify-center max-lg:overflow-x-auto scroll-smooth scrollbar-size max-lg:pb-[20px]'>
              {sortingOptions.map((option, i) => {
                return (
                  <button
                    key={i}
                    className='max-lg:w-full border border-[#EBECF2] bg-white rounded-xl py-2 px-6'
                    onClick={() => setSort(option.field)}
                  >
                    <div className='flex gap-1 items-center'>
                      <span className='text-nowrap'>{option.text}</span>
                      <i className='fa-solid fa-dataow-down-9-1'></i>
                      <span className='ml-1'>
                        {activeSort.field === option.field ? (
                          <i
                            className={`fa-solid fa-sort-${
                              activeSort.dir === 'asc' ? 'up' : 'down'
                            }`}
                          ></i>
                        ) : (
                          <i className='fa-solid fa-sort'></i>
                        )}
                      </span>
                    </div>
                  </button>
                )
              })}
            </div>
            <div className='flex flex-col gap-10'>
              {aircrafts
                .sort((a, b) => {
                  const valueA = a[activeSort.field]
                  const valueB = b[activeSort.field]

                  if (activeSort.dir === 'asc') {
                    // Handle numerical values
                    if (!isNaN(valueA) && !isNaN(valueB)) {
                      return valueA - valueB
                    }
                    // Handle string values
                    return valueA.localeCompare(valueB)
                  } else if (activeSort.dir === 'desc') {
                    if (!isNaN(valueA) && !isNaN(valueB)) {
                      return valueB - valueA
                    }
                    // For descending order, you can swap the parameters in localeCompare or invert the result
                    return valueB.localeCompare(valueA)
                  }
                  return 0
                })
                .map(aircraft => {
                  return (
                    <>
                      <AircraftCard key={aircraft.id} aircraft={aircraft} />
                    </>
                  )
                })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
