import { useParams } from 'react-router-dom'
import CompareAircrafts from '../compareAircrafts'
import { useState } from 'react'
import SingleAircraftDetails from '../SingleAircraft/SingleAircraftDetails'
import axios, { isAxiosError } from 'axios'
import { API_URL } from '../../utils/constants/app-constants'
import { toast } from 'react-toastify'

const SharedPage = () => {
  const { sharedId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [pageData, setPageData] = useState({})
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [password, setPassword] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${API_URL}access-shared-link/${sharedId}/?password=${password}`
      )
      if (res.status == '200') {
        setPageData(res.data)
        setIsAuthorized(true)
      } else {
        toast.error(res.data.error) // Handle error
      }
    } catch (error) {
      let message = 'Something went wrong!'
      if (isAxiosError(error)) {
        message = error.response.data.error
      }
      toast.error(message)
    } finally {
      setIsLoading(false)
    }
  }

  const DisplayPage = () => {
    let Page
    switch (pageData.page_type) {
      case 'compare':
        Page = CompareAircrafts
        break
      case 'aircraft':
        Page = SingleAircraftDetails
        break
    }
    if (Page) {
      return (
        <Page
          defaultSearchParmasString={pageData.search_params}
          readOnly
          defaultPageData={pageData.page_data}
        />
      )
    } else {
      return <div className='m-auto'>Invalid page type</div>
    }
  }
  return (
    <div className='min-h-full py-8 px-4 md:px-8 flex flex-col md:gap-6 gap-8'>
      {!isAuthorized || isLoading ? (
        <form
          className='bg-white rounded-lg p-4 space-y-5 w-[min(100%,300px)] m-auto shadow-2xl'
          onSubmit={handleSubmit}
        >
          <div>
            <h2 className='text-xl font-semibold'>Enter Password</h2>
            <p className='text-[10px] text-oldSilver'>
              to access this shared page
            </p>
          </div>
          <input
            disabled={isLoading}
            type='password'
            value={password}
            autoFocus
            placeholder='Password'
            onChange={e => setPassword(e.target.value)}
            className='w-full border border-cetaceanBlue rounded-lg text-base py-1 px-2'
          />
          <button
            disabled={isLoading}
            className='py-2 px-4 bg-cetaceanBlue text-white rounded-lg w-full flex justify-center items-center gap-2'
          >
            {isLoading && (
              <i className='fa-solid fa-spinner text-white animate-spin' />
            )}{' '}
            <span>Access</span>
          </button>
        </form>
      ) : (
        <DisplayPage />
      )}
    </div>
  )
}
export default SharedPage
