import React, { useState, useEffect, useContext, useMemo } from "react";
import cn from "classnames";
import { HiOutlineSearch } from "react-icons/hi";
import BounceLoader from "react-spinners/BounceLoader";
import Card from "../components/common/Card";
import Dropdown from "../components/common/Dropdown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  CATEGORY_OPTIONS,
  MANUFACTURER_OPTIONS,
  PRODUCTION_OPTIONS,
  CATEGORY_OPTIONS_DIC,
  MANUFACTURER_OPTIONS_DIC,
  PRODUCTION_OPTIONS_DIC,
  CURRENCY_OPTIONS,
  REGION_OPTIONS,
  UNIT_OPTIONS,
} from "../utils/constants/app-constants";
import { MdOutlineExpandMore } from "react-icons/md";
import styles from "../styles/Search.module.scss";
import { Button, Slider } from "@mui/material";
import { StateContext } from "../context";
import AppContext from "../custom-context/appContext";

const RangeSliderFilter = ({label,rangeKey,search,setSearch,min,max,defaultValue,minimumGap,applylimitedAccess = false})=>{
  const [expand,setExpand] = useState()
  const value = search[rangeKey]
  return (
    <div>
      <Accordion
        expanded={expand}
        onChange={() => !applylimitedAccess && setExpand(!expand)}
        disabled={applylimitedAccess}
      >
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <div className={styles.label}>
            {label}: {value[0]} - {value[1]}
          </div>
        </AccordionSummary>
        <div className={styles.range}>
          <Slider
            className={styles.slider_home}
            value={value}
            onChange={(e, val) => {
              if (val[1] - val[0] >= (minimumGap || 0))
                setSearch((currentSearch) => ({ ...currentSearch, [rangeKey]: val }))
            }}
            min={min}
            max={max}
            defaultValue={defaultValue || value}
            disableSwap
            step={1}
          />
        </div>
      </Accordion>
    </div>
  )
}
export default function Search() {
  const { userLevel } = useContext(AppContext);
  const hasLimitedAccess = ["jet_finder","pro_jet_finder"].includes(userLevel)
  const [currency, setCurrency] = useState(CURRENCY_OPTIONS[0]);
  const [conversionRate, setConversionRate] = useState(1);
  const [region, setRegion] = useState(REGION_OPTIONS[0]);
  const [unit, setUnit] = useState(UNIT_OPTIONS[0]);
  const [aircraftData, setAircraftData] = useState([]);
  const [filteredAirCraftData, setFilteredAirCraftData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const { allAircraftData, getCurrencyConversion } = useContext(StateContext);
  useEffect(() => {
    async function fetchData() {
      let cRate = await getCurrencyConversion(currency);
      setConversionRate(cRate);
    }
    fetchData();
  }, [currency]);

  const regionPrefixes = {
    "North America": "NA",
    "South America": "SA",
    "Europe": "EU",
    "Asia": "AS",
  };

  const maxVal = (key) => {
    return aircraftData.length === 0 ? 0 : aircraftData.reduce((max, item) => Math.max(max, item[key]), 0);
  }

  const minVal = (key) => {
    return aircraftData.length === 0 ? 0 : aircraftData.reduce((min, item) => Math.min(min, item[key]), aircraftData[0][key]);
  }
  const isImperialUnits = unit === 'Imperial Units'
  const keys = {
    pax: 'max_pax',
    range: isImperialUnits ? "range_NM" : "range_KM",
    cruise: isImperialUnits ? "high_cruise_knots" : "high_speed_cruise_kmh",
    altitude: isImperialUnits ? "max_altitude_feet" : "max_altitude_meters",
    fuel_burn: isImperialUnits ? "hourly_fuel_burn_GPH" : "hourly_fuel_burn_LPH",
    baggage_capacity: isImperialUnits ? "baggage_capacity_CF" : "baggage_capacity_cubicmeters",
    takeoff_distance: isImperialUnits ? "TO_distance_feet" : "TO_distance_meters",
    landing_distance: isImperialUnits ? "landing_distance_feet" : "landing_distance_meters",
    annual_cost: `${regionPrefixes[region]}_annual_total`,
    hourly_price: `${regionPrefixes[region]}_hourly_total`,
    new_purchase: "new_purchase",
    pre_owned: "average_pre_owned",
  }
  const initialSearch = ()=>{
    return {
      "aircraft_name": "",
      "category": null,
      "aircraft_manufacturer": null,
      "in_production": null,
      "pax": [minVal(keys.pax),maxVal(keys.pax)],
      "range": [minVal(keys.range),maxVal(keys.range)],
      "cruise": [minVal(keys.cruise),maxVal(keys.cruise)],
      "altitude": [minVal(keys.altitude),maxVal(keys.altitude)],
      "fuel_burn": [minVal(keys.fuel_burn),maxVal(keys.fuel_burn)],
      "baggage_capacity": [minVal(keys.baggage_capacity),maxVal(keys.baggage_capacity)],
      "takeoff_distance": [minVal(keys.takeoff_distance),maxVal(keys.takeoff_distance)],
      "landing_distance": [minVal(keys.landing_distance),maxVal(keys.landing_distance)],
      "annual_cost": [minVal(keys.annual_cost),maxVal(keys.annual_cost)],
      "hourly_price": [minVal(keys.hourly_price),maxVal(keys.hourly_price)],
      "new_purchase": [minVal(keys.new_purchase),maxVal(keys.new_purchase)],
      "pre_owned": [minVal(keys.pre_owned),maxVal(keys.pre_owned)],
    }
  } ;

  const [originalSearch, setOriginalSearch] = useState(initialSearch);
  const [search, setSearch] = useState(originalSearch);

  // Initial data fetch and setting
  useEffect(() => {
    const contentContainer = document.getElementById('content-container');
    if (contentContainer) contentContainer.scrollTo(0, 0);

    const fetchData = async () => {
      setFetching(true);
      let data = await allAircraftData();
      setAircraftData(data);
      setFilteredAirCraftData(data);
      setFetching(false);
    };

    fetchData();
  }, []);
  useEffect(()=>{
    setOriginalSearch(initialSearch())
  },[aircraftData])
  useEffect(()=>{
    setSearch(originalSearch)
  },[originalSearch])
  useEffect(() => {
    console.log(search)
    setFilteredAirCraftData(aircraftData.filter((item) => {
      return (
        (search.aircraft_name === "" || item.aircraft_name.toLowerCase().includes(search.aircraft_name.toLowerCase())) 
        && (search.category === null || item.category === search.category) 
        && (search.aircraft_manufacturer === null || item.aircraft_manufacturer === search.aircraft_manufacturer) 
        && (search.in_production === null || item.in_production === search.in_production) 
        && (item[keys.pax] >= search.pax[0] && item[keys.pax] <= search.pax[1])
        && (item[keys.range] >= search.range[0] && item[keys.range] <= search.range[1])
        && (item[keys.cruise] >= search.cruise[0] && item[keys.cruise] <= search.cruise[1])
        && (item[keys.altitude] >= search.altitude[0] && item[keys.altitude] <= search.altitude[1])
        && (item[keys.fuel_burn] >= search.fuel_burn[0] && item[keys.fuel_burn] <= search.fuel_burn[1])
        && (item[keys.baggage_capacity] >= search.baggage_capacity[0] && item[keys.baggage_capacity] <= search.baggage_capacity[1])
        && (item[keys.takeoff_distance] >= search.takeoff_distance[0] && item[keys.takeoff_distance] <= search.takeoff_distance[1])
        && (item[keys.landing_distance] >= search.landing_distance[0] && item[keys.landing_distance] <= search.landing_distance[1])
        && (item[keys.annual_cost] >= search.annual_cost[0] && item[keys.annual_cost] <= search.annual_cost[1])
        && (item[keys.hourly_price] >= search.hourly_price[0] && item[keys.hourly_price] <= search.hourly_price[1])
        && (item[keys.new_purchase] >= search.new_purchase[0] && item[keys.new_purchase] <= search.new_purchase[1])
        && (item[keys.pre_owned] >= search.pre_owned[0] && item[keys.pre_owned] <= search.pre_owned[1])
      );
    }));
  }, [search]);

  const handleEnumChange = (key, val) => {
    console.log(key, val)
    if (search[key] === val) {
      setSearch((currentSearch) => ({ ...currentSearch, [key]: null }))
    }
    else {
      setSearch((currentSearch) => ({ ...currentSearch, [key]: val }));
    }
  }

  const handleProductionChange = (val) => {
    if (search.in_production === val) {
      setSearch((currentSearch) => ({ ...currentSearch, in_production: null }))
    }
    else {
      setSearch((currentSearch) => ({ ...currentSearch, in_production: val }));
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 21; 
  const totalPages = Math.ceil(filteredAirCraftData.length / itemsPerPage);

  const renderPageNumbers = () => {
    let pages = [];

    // Logic to determine which buttons to show
    if (totalPages < 5) {
      // If there are 5 or fewer pages, show all page numbers
      for (let i = 1; i < totalPages; i++) {
        pages.push(i);
      }
    } else {
      // More complex logic for when there are more than 5 pages
      if (currentPage < 3) {
        // Current page is 3 or less, show first 4 and last page
        pages = [1, 2, 3, 4, totalPages];
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        // Between page 4 and the third-to-last page
        pages = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
      } else {
        // Last three pages
        pages = [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      }
    }

    // Render buttons based on pages array
    return pages.map(page => (
      <Button key={page} size="small" variant={currentPage === page ? `contained` : `outlined`} onClick={()=> {setCurrentPage(page)}}>{page}</Button>
    ));
  };

  const [buttonClass, setButtonClass] = useState("");

  const openFilter = () => {
    const filter = document.querySelector(".filters_target");
    if (filter.style.display === "none") {
      setButtonClass("filter-open");
      filter.style.display = "flex";
    } else {
      setButtonClass("");
      filter.style.display = "none";
    }
  };

  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn(styles.container)}>
        <div className={styles.row}>
          <span className={`${styles.open_filter} ${styles[buttonClass]}`} onClick={() => openFilter()}>
            <i className="fa-solid fa-sliders"></i>
          </span>


          <div className={"filters_target " + styles.filters}>
            <div className={styles.top}>
              <div className={styles.title}>Search Aircraft</div>
            </div>

            {JSON.stringify(search) !== JSON.stringify(originalSearch) && (
              <Button key={1} size="small" variant="contained" 
              onClick={()=> {
                setFilteredAirCraftData(aircraftData)
                setSearch(originalSearch)
              }}
              >
                Reset Filters
              </Button>
            )}

            <div className={styles.form}>
              <div className={styles.search}>
                <input
                  className={styles.input}
                  type="text"
                  value={search.aircraft_name}
                  onChange={(e, val) => setSearch((currentSearch) => ({ ...currentSearch, aircraft_name: e.target.value }))}
                  name="search"
                  placeholder="Search Aircraft"
                  required
                />
                <button className={styles.result}>
                  <HiOutlineSearch name="search" size="16" />
                </button>
              </div>
            </div>

            <div className={styles.sorting}>
              <div className={styles.dropdown}>
                <div className={styles.label}>Category</div>
                <Dropdown
                  className={styles.dropdown}
                  value={CATEGORY_OPTIONS_DIC[search.category]}
                  setValue={(value) => handleEnumChange("category", value)}
                  options={CATEGORY_OPTIONS}
                  placeholder="All categories"
                />
              </div>
            </div>
            <div className={styles.sorting}>
              <div className={styles.dropdown}>
                <div className={styles.label}>Manufacturer</div>
                <Dropdown
                  className={styles.dropdown}
                  value={MANUFACTURER_OPTIONS_DIC[search.aircraft_manufacturer]}
                  setValue={(value) => handleEnumChange("aircraft_manufacturer", value)}
                  options={MANUFACTURER_OPTIONS}
                  placeholder="All manufacturers"
                />
              </div>
            </div>
            <div className={styles.sorting}>
              <div className={styles.dropdown}>
                <div className={styles.label}>In Production</div>{" "}
                <Dropdown
                  className={styles.dropdown}
                  value={
                    PRODUCTION_OPTIONS_DIC[
                      search.in_production === true
                        ? "Yes"
                        : search.in_production === false
                        ? "No"
                        : "Select"
                    ]
                  }
                  setValue={(value) => handleProductionChange(value === "Yes")}
                  options={PRODUCTION_OPTIONS}
                  placeholder={"-"}
                />
              </div>
            </div>

            <RangeSliderFilter
              label={`Passengers`}
              rangeKey='pax'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.pax)}
              min={minVal(keys.pax)}
            />
            <RangeSliderFilter
              label={`Range (${ isImperialUnits ?'NM': 'KM'})`}
              rangeKey='range'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.range)}
              min={minVal(keys.range)}
            />
            
            <RangeSliderFilter
              label={`Cruise Speed (${ isImperialUnits ?'Knots': 'KMH'})`}
              rangeKey='cruise'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.cruise)}
              min={minVal(keys.cruise)}
            />
            <RangeSliderFilter
              label={`Max Altitude (${ isImperialUnits ?'Feet': 'Meters'})`}
              rangeKey='altitude'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.altitude)}
              min={minVal(keys.altitude)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`Fuel Burn (${ isImperialUnits ?'GPH': 'LPH'})`}
              rangeKey='fuel_burn'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.fuel_burn)}
              min={minVal(keys.fuel_burn)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`Baggage Capacity (${ isImperialUnits ?'CF': 'Cubic Meters'})`}
              rangeKey='baggage_capacity'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.baggage_capacity)}
              min={minVal(keys.baggage_capacity)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`Takeoff Distance (${ isImperialUnits ?'Feet': 'Meters'})`}
              rangeKey='takeoff_distance'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.takeoff_distance)}
              min={minVal(keys.takeoff_distance)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`Landing Distance (${ isImperialUnits ?'Feet': 'Meters'})`}
              rangeKey='landing_distance'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.landing_distance)}
              min={minVal(keys.landing_distance)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`Hourly Price (${currency})`}
              rangeKey='hourly_price'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.hourly_price)}
              min={minVal(keys.hourly_price)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`Annual Price (${currency})`}
              rangeKey='annual_cost'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.annual_cost)}
              min={minVal(keys.annual_cost)}
              applylimitedAccess={hasLimitedAccess}
            />
            <RangeSliderFilter
              label={`New Purchase Price (${currency})`}
              rangeKey='new_purchase'
              search={search}
              setSearch={setSearch}
              max={maxVal(keys.new_purchase)}
              min={minVal(keys.new_purchase)}
              applylimitedAccess={hasLimitedAccess}
            />
          </div>

          <div className={styles.wrapper}>
            <div className={`${styles.dropdown} my-4 md:my-0`}>
              <Dropdown
                className={styles.dropdown}
                headerDropdown={true}
                value={unit}
                setValue={(value) => setUnit(value)}
                options={UNIT_OPTIONS}
              />
              <Dropdown
                headerDropdown={true}
                className={styles.dropdown}
                value={region}
                setValue={(value) => setRegion(value)}
                options={REGION_OPTIONS}
              />
              <Dropdown
                className={styles.dropdown}
                headerDropdown={true}
                value={currency}
                setValue={(value) => setCurrency(value)}
                options={CURRENCY_OPTIONS}
              />
            </div>
            {fetching 
                ? <div className="flex justify-center items-center h-96 w-full">
                    <BounceLoader color={"#123abc"} loading={fetching} size={150} />
                  </div>
                : filteredAirCraftData.length === 0
                  ? <div className="flex justify-center items-center h-96">
                      <h1>No Aircraft Found</h1>
                    </div>
                  : <div className={styles.list}>
                    {filteredAirCraftData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((product) => (
                      <Card
                        className={styles.card}
                        item={product}
                        key={product.aircraft_id}
                        unit={unit}
                        currency={currency}
                        region={region}
                        conversionRate={conversionRate}
                        hasLimitedAccess={hasLimitedAccess}
                      />
                    ))}
                    </div>
            }
            <div className="flex gap-2 items-center mt-10 justify-center">
                <Button size="small" disabled={currentPage === 1} variant="outlined" onClick={()=>{currentPage > 1 && setCurrentPage((num)=> num - 1)}}>{'<'}</Button>
                {renderPageNumbers()}
                <Button size="small" disabled={currentPage >= totalPages} variant="outlined" onClick={()=>{setCurrentPage((num)=>num < totalPages ? num +1 : num)}}>{'>'}</Button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
