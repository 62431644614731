import { toast } from "react-toastify"

const handleCopyOnClipboard = (text, message = 'Copied') => {
    navigator.clipboard.writeText(text)
    toast.success(message)
}

const completeSharedLink = (sharedId) => {
    return `${window.location.origin}/share/${sharedId}`
}

export {
    handleCopyOnClipboard,
    completeSharedLink
}