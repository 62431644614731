import React, {useState, useEffect} from 'react';
import apiService from '../../services/api';
import numeral from 'numeral';

const STATICBARCOLORS = {
    // colors for class
    VLJ:'linear-gradient(90deg, #5247C6 0%, rgba(82, 71, 198, 0.48) 123.08%)',
    Light:'linear-gradient(90deg, #42275A 0%, #734B6D 100%)',
    Medium:'linear-gradient(90deg, #FFAFBD 0%, #FFC2A0 100%)',
    Large:'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
    // colors for class
    Cessna:'linear-gradient(90deg, #42275A 0%, #734B6D 100%)',
    Bombardier:'linear-gradient(90deg, #5247C6 0%, rgba(82, 71, 198, 0.48) 123.08%)',
    Embraer:'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
    Gulfstream:'linear-gradient(90deg, #56AB2F 0%, #A8E063 100%)',
    Dassault:'linear-gradient(90deg, #FFAFBD 0%, #FFC2A0 100%)',
    Raytheon:'',
    Learjet:'linear-gradient(90deg, #EB3349 0%, #F45C43 100%)',
    Hawker:'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
    Beechjet:'',
    Honda:'linear-gradient(90deg, #5247C6 0%, rgba(82, 71, 198, 0.48) 123.08%)',
    Cirrus:'',
    IAI:'',
    Pilatus:'',
    Beechcraft:'',
    Eclipse:'linear-gradient(90deg, #42275A 0%, #734B6D 100%)',
    // colors for years
    '2019':'linear-gradient(90deg, #5247C6 0%, rgba(82, 71, 198, 0.48) 123.08%)',
    '2020':'linear-gradient(90deg, #42275A 0%, #734B6D 100%)',
    '2021':'linear-gradient(90deg, #FFAFBD 0%, #FFC2A0 100%)',
    '2022':'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
    '2023':'linear-gradient(90deg, #06BEB6 0%, #48B1BF 100%)',
    '2024':'linear-gradient(90deg, #06BEB6 0%, #48B1BF 100%)',
}
const calculatToal = (data,type)=>{
    if (type === 'sum')
        return Object.values(data).reduce((prev,cur)=>prev+cur,0)
    else if (type === 'max')
        return Math.max(...Object.values(data))
    else
        return null
}
const StatBarGroup = ({ title,timePeriod,applyFilter=false,blur=false, apiRoute, additionalApiFilters }) => {
    const [data, setData] = useState({});
    const [sumOfData,setSumOfData] = useState(calculatToal(data,'sum'))
    const [maxOfData,setMaxOfData] = useState(calculatToal(data,'max'))
    const [fetched, setFetched] = useState(false);
    const [showByNumber,setShowByNumber] = useState(true)

    useEffect(() => {
        async function fetchData() {
            if(apiRoute === 'getFlightHistory'){
                const response = await apiService.getFlightHistory((additionalApiFilters?`&${additionalApiFilters}`:''));
                const formatedRes = {}
                for (const year in response.data) {
                    formatedRes[year] = Object.values(response.data[year]).reduce((sum, value) => sum + value, 0);
                }
                setData(formatedRes);
                setSumOfData(calculatToal(formatedRes,'sum'))
                setMaxOfData(calculatToal(formatedRes,'max'))
            } else {
                const response = await apiService[apiRoute](timePeriod?timePeriod:30,additionalApiFilters);
                setData(response);
                setSumOfData(calculatToal(response,'sum'))
                setMaxOfData(calculatToal(response,'max'))
            }
            setFetched(true);
        }
        fetchData();
    }, [apiRoute,timePeriod, additionalApiFilters]);

    const Bar = ({title,number})=>{
        const percent= (number/sumOfData)*100;
        const width = (number/maxOfData)*100;
        const bgColor = STATICBARCOLORS[title];
        const barStyle = {
            width: `${width}%`,
            maxWidth: (100 + "%"),
            minWidth:"70px",
            background: bgColor ? bgColor : '#28ace2',
            transition:"width 1s"
        }
        return (
            <tr>
                <td className='line-clamp-1 w-auto max-w-28 pr-2'>{title}</td>
                <td className='w-full'>
                    <div className="h-6 rounded-r-2xl text-white px-2 py-1 flex items-center justify-end"
                        style={barStyle}>
                        <span className={`${blur ? "blurData" : ""}`}>{blur ? "Null" : (showByNumber? numeral(number).format("0,0"):`${percent.toFixed(1)}%`)}</span>
                    </div>
                </td>
            </tr>
        )
    }
    return(
    <div className="card map-container !h-auto overflow-y-scroll flex flex-col gap-2" style={{maxHeight:apiRoute === 'getFlightHistory'?'':'34vh'}}>
        <div className="flex flex-col md:flex-row md:justify-between gap-4 mb-8">
            <h3 className="text-3xl mb-4">{title}</h3>
            {applyFilter && (showByNumber
            ? <button className="interact_button md:ml-auto" onClick={() => setShowByNumber(false)}><i class="fa-light fa-percent"></i>&nbsp;Percent</button>
            : <button className="interact_button md:ml-auto" onClick={() => setShowByNumber(true)}><i class="fa-solid fa-dataow-down-9-1"></i>&nbsp;Numbers</button>)
            }
        </div>
        <div className="overflow-y-scroll">
            {fetched && Object.values(data).length === 0
            ? <div className="py-12 flex flex-col justify-center items-center"> 
                <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
                <h3 className="text-xl mt-6">No Data Available.</h3>
                </div>
            : Object.values(data).length === 0 
                ? <div className="py-12 flex flex-col justify-center items-center">
                    <i className="fa fa-spinner fa-spin text-6xl"></i>
                    <h3 className="text-3xl mt-6">Loading Data...</h3>
                    </div>
                : (
                    <table>
                        <tbody className="mb-8">
                            {
                                Object.entries(data).map((val,i)=>{
                                    return <Bar key={i} title={val[0]} number={val[1]}/>
                                })
                            }
                        </tbody>
                    </table>
                )
            }
        </div>
    </div>)
};

export default StatBarGroup;