import numeral from 'numeral'
import React, { useEffect, useMemo, useState } from 'react'
import CardHeading from './CardHeading'
import { FaRegCompass } from 'react-icons/fa'
import apiService from '../../services/api'

const FleetActvityInfo = ({ regNumbers }) => {
  regNumbers = regNumbers?.split('&reg=') || []
  const [fleetInfo, setFleetInfo] = useState({})
  const { maxTotalHours, maxMovements, maxAvgMissionLength } = useMemo(() => {
    let maxTotalHours = 1
    let maxMovements = 1
    let maxAvgMissionLength = 1

    for (const [, data] of Object.entries(fleetInfo)) {
      maxTotalHours = Math.max(maxTotalHours, data.hours || 0)
      maxMovements = Math.max(maxMovements, data.movements || 0)
      maxAvgMissionLength = Math.max(
        maxAvgMissionLength,
        data.avgMissionLength || 0
      )
    }
    return {
      maxTotalHours,
      maxMovements,
      maxAvgMissionLength
    }
  }, [fleetInfo])
  console.log(maxTotalHours, maxMovements, maxAvgMissionLength)
  const ActivityRow = ({
    label,
    totalHours,
    totalMovements,
    avgMissionLength
  }) => {
    const bars = {
      totalHours: {
        value: totalHours,
        postfix: '',
        color: '#5078FF',
        keepDecimal: false,
        heighestValue: maxTotalHours
      },
      totalMovements: {
        value: totalMovements,
        postfix: '',
        color: '#CC89FF',
        keepDecimal: false,
        heighestValue: maxMovements
      },
      avgMission: {
        value: numeral(avgMissionLength).format('0,0.0'),
        postfix: 'hours',
        color: '#FF8B43',
        keepDecimal: true,
        heighestValue: maxAvgMissionLength
      }
    }
    return (
      <tr className='group font-medium'>
        <td className='text-lg pr-6'>{label}</td>
        <td className='py-1 pl-4 w-full border-b group-last:border-b-0 '>
          <table>
            <tbody>
              {Object.entries(bars).map(([key, bar], i) => (
                <tr key={i}>
                  <td className='md:whitespace-nowrap px-2 text-sm text-right'>
                    <p>
                      {bar.keepDecimal
                        ? bar.value
                        : numeral(bar.value).format('0,0')}{' '}
                      {bar.postfix}
                    </p>
                  </td>
                  <td className='w-full px-2'>
                    <div className='rounded-2xl overflow-hidden bg-[#e6e4e5] h-2 w-full'>
                      <div
                        className='h-full rounded-full'
                        style={{
                          width: `${(bar.value / bar.heighestValue) * 100}%`,
                          background: bar.color
                        }}
                      ></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    )
  }
  const FleetActivity = () => {
    let totalHours = 0
    let totalMissionLength = 0
    let totalActiveDays = 0
    const values = Object.values(fleetInfo)

    for (const data of values) {
      totalHours += data['hours'] || 0
      totalMissionLength += data['avgMissionLength'] || 0
      totalActiveDays += data['activeDays'] || 0
    }
    const totalAircrafts = regNumbers?.length || 0;
    
    const divideByAircrafts = totalAircrafts || 1 // Avoid division by zero
    const avgAnnualHours = totalHours / divideByAircrafts
    const avgMissionLength = totalMissionLength / (values.length || 1)
    const avgActiveDays = totalActiveDays / divideByAircrafts
    const rows = [
      {
        label: 'Number in Service',
        value: regNumbers?.length || 0,
        postfix: '',
        keepDecimal: false
      },
      {
        label: 'Average Annual Hours',
        value: avgAnnualHours.toFixed(2),
        postfix: '',
        keepDecimal: false
      },
      {
        label: 'Average Days Flown',
        value: numeral(avgActiveDays).format('0,0'),
        postfix: '',
        keepDecimal: false
      },
      {
        label: 'Average Mission Length',
        value: numeral(avgMissionLength).format('0,0.0'),
        postfix: 'hours',
        keepDecimal: true
      }
    ]
    return (
      <table className='mt-2'>
        <tbody className=''>
          {rows.map(({ label, value, keepDecimal, postfix }, i) => (
            <tr className='border-b last:border-b-0 font-medium'>
              <td className='text-lg pr-2'>
                <h6 className='text-CharlestonGreen text-lg whitespace-nowrap'>
                  {label}
                </h6>
              </td>
              <td className='py-4 pl-4 w-full text-grey text-end text-sm'>
                {keepDecimal ? value : numeral(value).format('0,0')} {postfix}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
  const [fetching, setFetching] = useState(false)
  const transformData = data => {
    return Object.fromEntries(
      Object.entries(data).map(([year, months = {}]) => {
        months = Object.values(months).map(monthlyInfo => monthlyInfo)
        const totalInfo = months.reduce(
          (acc, cur) => {
            return {
              hours: acc['hours'] + (cur['hours'] || 0),
              movements: acc['movements'] + (cur['flights'] || 0),
              activeDays: acc['activeDays'] + (cur['activeDays'] || 0)
            }
          },
          {
            hours: 0,
            movements: 0,
            activeDays: 0
          }
        )
        totalInfo.avgMissionLength = totalInfo.hours / (totalInfo.movements || 1)
        return [year, totalInfo]
      })
    )
  }
  useEffect(() => {
    async function fetchData () {
      setFetching(true)
      const airportResponse = await apiService.getFlightTotalHistory(
        '',
        regNumbers
      )
      setFleetInfo(transformData(airportResponse.data))
      setFetching(false)
    }
    fetchData()
  }, [])
  const Loading = ({ component }) => (
    <>
      {fetching && (
        <div className='my-auto flex flex-col justify-center items-center'>
          <i className='fa fa-spinner fa-spin text-6xl'></i>
          <h3 className='text-xl mt-6'>Loading Data...</h3>
        </div>
      )}

      {!fetching &&
        (!fleetInfo ? (
          <div className='my-auto flex flex-col justify-center items-center'>
            <i className='fa fa-solid fa-plane-circle-exclamation text-4xl'></i>
            <h3 className='text-xl mt-6'>No Data Available.</h3>
          </div>
        ) : (
          component
        ))}
    </>
  )
  return (
    <div className='lg:h-[700px] space-y-12'>
      <div className='lg:h-1/2 flex flex-col gap-3'>
        <CardHeading title='Annual Activity' icon={FaRegCompass} />
        <Loading
          component={
            <>
              <div className='size-full max-lg:max-h-72 overflow-y-auto'>
                <table>
                  <tbody>
                    {Object.entries(fleetInfo).sort(([yearA],[yearB])=>yearB-yearA).map(
                      ([label, { hours, movements, avgMissionLength }]) => (
                        <ActivityRow
                          label={label}
                          totalHours={hours}
                          totalMovements={movements}
                          avgMissionLength={avgMissionLength}
                        />
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <ul className='flex justify-center items-center flex-wrap gap-4 gap-y-2 mt-2'>
                {[
                  { label: 'Total Flight Hours', color: '#5078FF' },
                  { label: 'Total Movements', color: '#CC89FF' },
                  { label: 'Average Mission Length', color: '#FF8B43' }
                ].map(({ label, color }) => (
                  <li className={`flex gap-1.5 items-center`}>
                    <div
                      className='size-3 rounded-full shrink-0'
                      style={{ backgroundColor: color }}
                    />
                    <p className='text-left text-sm'>{label}</p>
                  </li>
                ))}
              </ul>
            </>
          }
        />
      </div>
      <div className='w-full lg:h-1/2 flex flex-col gap-3'>
        <CardHeading title='Fleet Activity' icon={FaRegCompass} />
        <Loading component={<FleetActivity />} />
      </div>
    </div>
  )
}

export default FleetActvityInfo
