import React from "react";

const BasicRowsCard = ({ rows = [] }) => {

  const titleClasses = "pb-2 text-lg lg:text-xl my-8";
  const contentClasses = "pl-4 font-bold text-lg lg:text-xl my-8";

  return (
    <div className="card p-6">
      <table className="table-auto w-full h-full min-h-[300px]">
        <tbody>
          {
            rows.map(({title,content,blur = false},i)=>(
              <tr key={i}>
                <td className={titleClasses}>{title}</td>
                <td className={`${contentClasses} ${blur ? "blurData select-none" : ""}`}>
                  {blur ? "Null" : content}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default BasicRowsCard;