import LockImage from "./lockImage"

const YearSelector = ({year=2024,setYear=()=>{},disabled=false}) => {
  const years = [2019, 2020, 2021, 2022, 2023, 2024]

  const handleSelectChange = event => {
    setYear(parseInt(event.target.value, 10))
  }

  return (
    <div className='interact_button md:ml-auto cursor-pointer'>
      <i className='fa-solid fa-calendar'></i>
      <select
        class='universal-chart-select-val'
        value={year}
        onChange={(e)=> !disabled && handleSelectChange(e)}
      >
        {years.map(yearOption => (
          <option key={yearOption} value={yearOption}>
            {yearOption}
          </option>
        ))}
      </select>
      {disabled && <LockImage />}
    </div>
  )
}

export default YearSelector
