import React, {useState, useEffect} from 'react';
import apiService from '../../services/api';
import PaginatedTable from './paginatedTable';
import ChartFilters from '../dashboard/chartFilters';
import YearSelector from '../common/YearSelector';
import { TABLE_BLURED_VALUE } from '../../utils/constants/app-constants';

const SimpleTableCard = ({ title, type='items',showFilters=false,showAdditionalFilters=false, apiRoute, timePeriod, additionalApiFilters, maxHeight='', disableYearFilter = false, disableChartFilter = false, transformDataFunction = null /*Function used to tranform api respone data */,blurExceptTopThree=false }) => {
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [year, setYear] = useState(2024);
  const [apiFilters, setApiFilters] = useState(additionalApiFilters ? additionalApiFilters:"");

  const transformToBlurExceptTopThree = (response) =>{
    return response.data.map((obj,index)=>{
      const updatedData = Object.fromEntries(Object.entries(obj).map(([key,value])=>(
        [key, index > 2 ? TABLE_BLURED_VALUE : value]
      )))
      return updatedData;
    })
  }

  useEffect(() => {
    async function fetchData() {
      setFetched(false);
      setData([])
      if (additionalApiFilters == "reg=") return;
      const response = await apiService[apiRoute](timePeriod ? timePeriod : 30, apiFilters + `&year=${year}`);
      if (blurExceptTopThree){
        setData(transformToBlurExceptTopThree(response))
      } else if (typeof transformDataFunction === 'function'){
        setData(transformDataFunction(response));
      } else {
        setData(response.data);
      }
      setFetched(true);
    }
    fetchData();
  }, [timePeriod, apiRoute,year,apiFilters]);

  return(
  <div className="card map-container overflow-y-scroll flex flex-col gap-2" 
  style={{maxHeight:title === 'Fleet List'?'64vh':maxHeight?maxHeight:''}}>
    <div className="flex flex-col md:flex-row gap-4 md:items-center">
    <h3 className="text-3xl mb-4">{title}</h3>
      {showFilters && (
        <>
          <YearSelector year={year} setYear={setYear} disabled={disableYearFilter} />
          <ChartFilters setApiFilters={setApiFilters} showOtherfilters={showAdditionalFilters} disableFilters={disableChartFilter} />
        </>
      )}
    </div>
    {fetched && data.length === 0
      ? <div className="py-12 flex flex-col justify-center items-center"> 
          <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
          <h3 className="text-xl mt-6">No Data Available.</h3>
        </div>
      : data.length === 0 
          ? <div className="py-12 flex flex-col justify-center items-center">
              <i className="fa fa-spinner fa-spin text-6xl"></i>
              <h3 className="text-3xl mt-6">Loading Data...</h3>
            </div>
          : <PaginatedTable data={data} additional={data.length} type={type} />
    }
  </div>)
};

export default SimpleTableCard;

