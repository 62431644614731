import React, {useState, useEffect} from 'react';
import apiService from '../../services/api';
import numeral from "numeral";

import change_arrow_down from "../../assets/change_arrow_down.png";
import change_arrow_up from "../../assets/change_arrow_up.png";

const AirportActivityHistory = ({ icaoCode, hasLimitedAccess=false}) => {
  const [airportHistory, setAirportHistory] = useState({});
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (icaoCode.length !== 4) {
        setFetching(false);
        return;
      }
      setFetching(true);
      const history = await apiService.getAirportActivity(icaoCode);
      setAirportHistory(history);
      setFetching(false);
    }
    
    fetchData();
  }, [icaoCode]);

  const MovementsActivity = ({title,change,width,movements,blur=false})=>{
    return (
    <div className=''>
      <div className="flex items-center gap-2 mb-2">
      <h4 className="text-xl">{title}</h4>
      <div style={{minWidth: 85}} className={`change ${change >= 0 ? "positiveChange" : "negativeChange"}`}>
        {numeral(change).format("0,0.0")}%
        {change !== 0 && <img src={change < 0 ? change_arrow_down : change_arrow_up} alt="" />}
      </div>
    </div>
    <div 
      className={`bg-[#28ace2] h-6 rounded-r-2xl text-white px-2 py-1 flex items-center justify-end`}
      style={{
        width: width + "%", maxWidth: (100 + "%")}}
    >
      <span className={`${blur ? "blurData" : ""}`}>{blur ? "Null" : numeral(movements).format("0,0")}</span>&nbsp; movements
    </div>
  </div>)
  }

  return (
    <div className="card">
      <div className="w-full">
        {fetching
          ? <div className="py-24 flex flex-col justify-center items-center">
              <i className="fa fa-spinner fa-spin text-6xl"></i>
              <h3 className="text-3xl mt-6">Loading Data...</h3>
            </div>
          : Object.keys(airportHistory).length === 0 
            ? <div className="py-24 flex flex-col justify-center items-center">
                <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
                <h3 className="text-xl mt-6">No Data Available.</h3>
              </div> :
             <div className='flex flex-col gap-8'>
                <MovementsActivity title='Activity Last Month' width={40} movements={airportHistory?.counts?.last_month} change={airportHistory?.changes?.change_month} blur={hasLimitedAccess} />
                <MovementsActivity title='Activity This Year' width={60} movements={airportHistory?.counts?.this_year} change={airportHistory?.changes?.change_this_year} blur={hasLimitedAccess} />
                <MovementsActivity title='Activity Last Year' width={100} movements={airportHistory?.counts?.last_year} change={airportHistory?.changes?.change_last_year} blur={hasLimitedAccess} />
              </div>
         }
      </div>
    </div>
  );
}

export default AirportActivityHistory;