import React, { useState, useEffect, useContext, } from "react";
import { useSearchParams } from "react-router-dom";
import apiService from "../../services/api";
import BasicRowsCard from "../../components/shared/basicRowsCard";
// import TopRoutes from "../../components/shared/topRoutes";
import FlightHistoryCard from "../../components/dashboard/flightHistory";
import TopLocations from "../../components/dashboard/topLocations";
import change_arrow_up from "../../assets/change_arrow_up.png";
import change_arrow_down from "../../assets/change_arrow_down.png";
import numeral from "numeral";
import SimpleTableCard from "../../components/shared/simpleTableCard";
import StatBarGroup from "../../components/operators/StatBarGroup";
import AircraftFlightHistory from "../../components/shared/aircraftFlightHistory";
import AppContext from "../../custom-context/appContext";


export default function Operators() {
  const { userLevel } = useContext(AppContext);
  const hasLimitedAccess = ["jet_finder"].includes(userLevel);
  const [timePeriod, setTimePeriod] = useState(30);
  const [searchParams] = useSearchParams();
  const operatorParam = searchParams.get("operator")
  const [operatorName,setOperatorName] = useState(operatorParam? operatorParam : '');
  const [inputValue, setInputValue] = useState("");
  const [isSingleOperator,setIsSingleOperator] = useState(operatorName?true:false)
  const [operatorData,setOperatorData] = useState({})
  const [foundMatch, setFoundMatch] = useState(false);
  const [hasDoneLookup, setHasDoneLookup] = useState(false);
  const [lookupOperatorInProgress, setLookupOperatorInProgress] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionInProgress, setsuggestionInProgress] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hasDoneSuggestion, setHasDoneSuggestion] = useState(false);


  async function searchSuggestions() {
    try{
      setSuggestions([])
      setHasDoneSuggestion(false);
      setsuggestionInProgress(true)
      const res = await apiService.getOperatorSuggestions(inputValue);
      console.log(res)
      setSuggestions(res.data)
    }catch(err){
      console.log(err)
    } finally{
      setHasDoneSuggestion(true);
      setsuggestionInProgress(false)
    }
  }


  useEffect(() => {
    if(inputValue.length && operatorName !== inputValue){
      const delayDebounceFn = setTimeout(() => {
        setShowSuggestions(true)
        searchSuggestions()
      }, 700)
      return ()=>clearTimeout(delayDebounceFn)
    } else{
      setShowSuggestions(false)
      setSuggestions([])
    }
  }, [inputValue]);
  
  useEffect(()=>{
    if(operatorName) setInputValue(operatorName)
    async function fetchData() {
      setOperatorData({});
      if (!operatorName.length > 0) {
        setLookupOperatorInProgress(false)
        setHasDoneLookup(false);
        setFoundMatch(false);
        setIsSingleOperator(false)
        return;
      }
      setHasDoneLookup(true);
      setLookupOperatorInProgress(true);
      const response = await apiService.getSingleOperators(operatorName);
      if (response.message === "Invalid operator name") {
        setLookupOperatorInProgress(false)
        setFoundMatch(false);
        return;
      }else{
        setOperatorData(response.data)
        setFoundMatch(true);
      }
      setLookupOperatorInProgress(false);
    }
    fetchData();
  },[operatorName])
  const handleSuggestionClick = (operator)=>{
    setInputValue(operator)
    setFoundMatch(false)
    setShowSuggestions(false)
    setSuggestions([])
    setHasDoneSuggestion(false)
    setOperatorName(operator);
    setIsSingleOperator(true);
  }
  
  return (
  <div className="w-100 py-8 px-4 md:px-8 flex flex-col md:gap-6 gap-8">
    <div className="flex justify-around items-center py-12 gap-6">
      <h2 className="text-center text-3xl md:text-4xl font-bold">Search for an Operator</h2>
      <div className="relative">
        <input 
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value.replace("/", ""))} 
          onFocus={()=>setShowSuggestions(true)}
          onBlur={()=>setShowSuggestions(false)}
          className="aircraft_lookup_input" 
          type="text" 
          placeholder="Operator Name" 
        />
        {
          !(showSuggestions || suggestions.length) ? ''
          : (
            <div className="absolute top-[calc(100%+20px)] z-50 card w-full !p-0">
              {suggestionInProgress && (
                <div className="w-full py-12 flex flex-col justify-center items-center"> 
                <i className="fa fa-spinner fa-spin text-6xl"></i>
                  <h3 className="text-xl mt-6">Fetching operators...</h3>
                </div>
              )}
              {suggestions.length === 0 && !suggestionInProgress && hasDoneSuggestion && (
                <div className="w-full py-12 flex flex-col justify-center items-center"> 
                  <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
                  <h3 className="text-xl mt-6">No related operator Found.</h3>
                </div>
              )}
              {suggestions.length > 0 && (
                <ul className="py-5">
                  {suggestions.map((suggestion,i) => (
                    <li key={i} className="hover:bg-gray-200 cursor-pointer py-1 my-1 flex gap-2 items-center"
                    onClick={()=>handleSuggestionClick(suggestion.operator_name)}
                    >
                      <p className="pl-4 font-bold text-lg lg:text-xl line-clamp-1">{suggestion.operator_name}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )
        }
      </div>
    </div>

    {!isSingleOperator ?(
      <>
        <div className="grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-8">
          <div className="lg:col-span-3 md:gap-6 gap-8 flex flex-col">
            <FlightHistoryCard title='Flight History' additionalApiFilters={'operator=true'} hasLimitedAccess={hasLimitedAccess}/>
          </div>
          <div className="lg:col-span-2 md:gap-6 gap-8 flex flex-col">
            <StatBarGroup title={'Fleet by Class'} apiRoute='getFlightsByCategory' applyFilter={true} additionalApiFilters={`operator=true`} blur={hasLimitedAccess} />
            <StatBarGroup title={'Fleet by Make'} apiRoute='getFlightsByMake' applyFilter={true} additionalApiFilters={`operator=true`} blur={hasLimitedAccess} />
          </div>

        </div>
        <SimpleTableCard
            title="Operator Directory"
            type="Operators"
            apiRoute="getOperators"
            // additionalApiFilters={``}
            timePeriod={30}
            maxHeight="100vh"
            blurExceptTopThree={hasLimitedAccess}
          />
      </> 
    ):(
      <>
        {lookupOperatorInProgress && (
            <div className="w-full py-12 flex flex-col justify-center items-center">
              <i className="fa fa-spinner fa-spin text-6xl"></i>
              <h3 className="text-xl mt-6">Fetching airport data...</h3>
            </div>
        )}

        {!foundMatch && !lookupOperatorInProgress && hasDoneLookup && (
          <div className="w-full py-12 flex flex-col justify-center items-center"> 
            <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
            <h3 className="text-xl mt-6">Invalid icao code.</h3>
          </div>
        )}
        {foundMatch && !lookupOperatorInProgress &&(
        <>
          <div className="grid md:grid-cols-3 md:gap-6 gap-8">

            <BasicRowsCard 
              rows={[
                {
                  title: "Operator",
                  content: operatorData.name
                },
                {
                  title: "Fleet Size",
                  content: operatorData.fleet_size
                },
                {
                  title: "Top Aircraft",
                  content: operatorData.top_aircraft,
                  blur: hasLimitedAccess
                },
                {
                  title: "Change YoY",
                  content: (
                    !operatorData.change_yoy ? "N/A" : (
                    <>
                      <div className={`${operatorData.change_yoy >= 0 ? "positiveChange" : "negativeChange"} change`}>
                        {numeral(operatorData.change_yoy).format("0,0.0")}%
                        {operatorData.change_yoy !== 0 && <img src={operatorData.change_yoy < 0 ? change_arrow_down : change_arrow_up} alt="" />}
                      </div>
                    </>)
                  ),
                  blur: hasLimitedAccess
                },
              ]}
            />
            <AircraftFlightHistory additionalApiFilters={`operator=${operatorName}`} blurValues={hasLimitedAccess} />

            <StatBarGroup title={'Annual Activity'} apiRoute={'getFlightHistory'} additionalApiFilters={`operator=${operatorName}`} blur={hasLimitedAccess} />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-8">
          <div className="lg:col-span-3 md:gap-6 gap-8 flex flex-col">
            <FlightHistoryCard title='Flight History' additionalApiFilters={`operator=${operatorName}`} hasLimitedAccess={hasLimitedAccess} />
          </div>
          
          <div className="lg:col-span-2 md:gap-6 gap-8 flex flex-col">
            <SimpleTableCard
              title="Fleet List"
              type="aircraft"
              apiRoute="getOperatorFleetData"
              additionalApiFilters={`operator=${operatorName}`}
              timePeriod={30}
              blurExceptTopThree={hasLimitedAccess}
            />
          </div>
        </div>
        {/* <TopRoutes timePeriod={timePeriod} presetFilters={`operator=${operatorName}`} /> */}
        <TopLocations title='Airport' timePeriod={timePeriod} presetFilters={`operator=${operatorName}`} hasLimitedAccess={hasLimitedAccess} />
        </>
        )}
      </>
    )}
  </div>
  );
}