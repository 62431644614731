import React, {useEffect, useState} from 'react';
import { FORMAT_DATE, TABLE_BLURED_VALUE } from '../../../utils/constants/app-constants';
import numeral from "numeral";
import cn from "classnames";
import styles from "./styles.module.scss"
import change_arrow_up from "../../../assets/change_arrow_up.png";
import change_arrow_down from "../../../assets/change_arrow_down.png";
import MiniTableChart from '../miniTableChart';

const PaginatedTable = ({ data, additional, type, includeChart, allAircraft, additionalFilters, yearOnYearOverride,miniChartType }) => {
  const headers = data.length > 0 ? Object.keys(data[0]) : [];
  const [activeSort, setActiveSort] = useState({ header: "", dir: "" });
  const isBluredValue = (value) =>{
    return value === TABLE_BLURED_VALUE
  }

  useEffect(() => {
    console.log(additionalFilters)
  }, [additionalFilters]);

  const formatCell = (header, value) => {
    if (isBluredValue(value)) {
      const upperCaseHeader = header.toUpperCase()
      if (upperCaseHeader.includes('LOOKUP')){
        return (<td key={header}><span className="interact_button blurData">Lookup</span></td>);
      } else {
        return <td key={header} className='blur-[5px]'>Null</td>
      }
    } else if (['change','change_yoy','change_30_days'].includes(header.toLowerCase())) {
      const changeClass = cn(styles.change_text, `${value >= 0 ? "positiveChange" : "negativeChange"} change`);
      return (
      <td key={header} className="px-0">
        <div className="flex justify-center">
          <div className={changeClass}>
            {numeral(value).format("0,0.0")}%
            {value !== 0 && <img src={value < 0 ? change_arrow_down : change_arrow_up} alt="" />}
          </div>
        </div>
      </td>
      );
    } else if (header === "price") {
      return (<td key={header}>${Intl.NumberFormat('en-US').format(value)}</td>);
    } else if (header === "movements") {
      return (<td key={header}>{Intl.NumberFormat('en-US').format(value)}</td>);
    } else if (['flights','visits','avg_anual_hours','avg_distance','avg_mission_length','hours','avg_per_month','total_hours','hours_per_month'].includes(header) || (header === 'aircraft' && typeof value === 'number')){
      return (<td key={header}>{Intl.NumberFormat('en-US').format(value)}</td>)
    }
    else if (header === "date") {
      return (<td key={header}>{FORMAT_DATE(value)}</td>);
    }
    else if (header === "Year") {
      return (<td key={header}>{!value ? "N/A" : value}</td>);
    }
    else if (['OPERATOR_LOOKUP','AIRPORT_LOOKUP',"LOOKUP"].includes(header.toUpperCase())) {
      const upperCaseHeader = header.toUpperCase()
      if (upperCaseHeader === 'LOOKUP')
        return (<td key={header}><a className="interact_button" href={`/lookup?reg=${value}`}>Lookup</a></td>);
      else if (upperCaseHeader === 'AIRPORT_LOOKUP')
        return (<td key={header}><a className="interact_button" href={`/airport-lookup?icao=${value}`}>Search</a></td>);
      else if (upperCaseHeader === 'OPERATOR_LOOKUP')
        return (<td key={header}><a className="interact_button" href={`/operators?operator=${value}`}>Search</a></td>);
    }
    else if (header === 'match'){
      const changeClass = cn(styles.change_text, `${value ? "positiveChange" : "negativeChange"} change capitalize  !min-w-min !px-6`);
      return (
      <td key={header} className="px-0">
        <div className="flex justify-center">
          <div className={changeClass}>
            {value?'Yes':'No'}
          </div>
        </div>
      </td>
      );
    }
    else if (['variable','value','feature'].includes(header)) {
      return(<td key={header} className='capitalize !text-start'>{value.replaceAll('_',' ')}</td>);
    } else if (header === 'charter_aircraft'){
      return(<td key={header}>{value ? "Yes":"No"}</td>);
    }
    else {
      return(<td key={header}>{value}</td>);
    }
  };

  const exclusions = ['_id', 'id', 'dir', 'lat', 'lng', 'start_coords', 'end_coords', 'icao','isMissionsEmpty','regs','countryCode'];
  const filteredHeaders = headers.filter(header => !exclusions.includes(header));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; 
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.sort((a, b) => {
    const valueA = a[activeSort.header];
    const valueB = b[activeSort.header];
    const nonAllowedValuesArray = [null,undefined]
    if (
      isBluredValue(valueA) || 
      isBluredValue(valueB) ||
      nonAllowedValuesArray.includes(valueA) || 
      nonAllowedValuesArray.includes(valueB)
    ) return 0;
    if (activeSort.dir === "asc") {
      // Handle numerical values
      if (!isNaN(valueA) && !isNaN(valueB)) {
        return valueA - valueB;
      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // Handle string values
        return valueA.localeCompare(valueB);
      }
    } else if (activeSort.dir === "desc") {
      if (!isNaN(valueA) && !isNaN(valueB)) {
        return valueB - valueA;
      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // For descending order, you can swap the parameters in localeCompare or invert the result
        return valueB.localeCompare(valueA);
      }
    }
    return 0;
  }).slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderPageNumbers = () => {
    let pages = [];

    // Logic to determine which buttons to show
    if (totalPages <= 5) {
      // If there are 5 or fewer pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // More complex logic for when there are more than 5 pages
      if (currentPage <= 3) {
        // Current page is 3 or less, show first 4 and last page
        pages = [1, 2, 3, 4, totalPages];
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        // Between page 4 and the third-to-last page
        pages = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
      } else {
        // Last three pages
        pages = [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      }
    }

    // Render buttons based on pages array
    return pages.map(page => (
      <button
        key={page}
        className={`${currentPage === page ? `bg-[#28ace2] text-white` : `hover:bg-[#28ace2] hover:text-white`} border border-gray-300 px-2 py-1 rounded`}
        onClick={() => setCurrentPage(page)}
      >
        {page}
      </button>
    ));
  };

  const [openedCharts, setOpenedCharts] = useState({});
  const toggleOpenChart = (index) => setOpenedCharts({ ...openedCharts, [index]: openedCharts[index] ? !openedCharts[index] : true });

  const typeMap = {
    "Cities": "city",
    "Countries": "country",
    "Airports": "airport"
  }
  
  const setSort = (header) => {
    activeSort.header !== header 
      ? setActiveSort({ header, dir: "asc" })
      : activeSort.dir === "asc"
        ? setActiveSort({ header, dir: "desc" })
        : setActiveSort({ header: "", dir: "" })
  }

  useEffect(() => {
    console.log(activeSort);
    console.log(paginatedData)
  }, [activeSort]);
  const ApplyOrderInHeader = ({header}) =>{
    header = ['operator_lookup','airport_lookup'].includes(header)?'LOOKUP':header
    const headersToApllyOrder = ['fleet','avg_annual_hours','avg_distance','avg_mission_length','hours','total_hours','aircraft','hours_per_month','annual_hours','Year','reg_number','serial_number','owner/operator','charter_aircraft','country','change']
    const isInculded = headersToApllyOrder.includes(header)
  return (
        isInculded? (
          <th className="table_header cursor-pointer"
            onClick={() => setSort(header)}>
              <div className='flex justify-center gap-1 items-center'>
                <span>{header.replaceAll('_', ' ')}</span>
                <i className="fa-solid fa-dataow-down-9-1"></i>
                <span className="ml-1">
                  {activeSort.header === header ? <i className={`fa-solid fa-sort-${activeSort.dir === "asc" ? "up" : "down"}`}></i>: (<i className="fa-solid fa-sort"></i>)
                  }
                </span>
              </div>
          </th>
        ):<th className="table_header">{header.replaceAll('_', ' ')}</th>
  
  )
  }
  return(
  <>
    <div className="overflow-x-scroll">
      <table className="cpp_table w-full">
        <thead className="sticky top-0 z-50">
          <tr>
          {filteredHeaders.map((header,i) => (
            (!allAircraft || header === 'serial_no.')
              ? <ApplyOrderInHeader key={i} header={header} />
              : <th 
                  className="table_header cursor-pointer"
                  key={header}
                  onClick={() => setSort(header)}
                >
                  {header === "Lookup"
                    ? "LOOKUP"
                    : <>
                      {header.replaceAll('_', ' ')}
                      <span className="ml-1">
                        {activeSort.header === header && <i className={`fa-solid fa-arrow-${activeSort.dir === "asc" ? "up" : "down"}`}></i>}
                      </span>
                    </>
                  }
                </th>
          ))}
          {includeChart && <th className="table_header">Graph</th>}
          </tr>
        </thead>

        <tbody>
        {paginatedData.map((row, index) => {
          const isChartDisabled = Object.values(row).includes(TABLE_BLURED_VALUE);
          return (
              <>
                <tr key={index}>
                  {filteredHeaders.map((header) => (
                    formatCell(header, row[header])
                  ))}
                  {(includeChart) && 
                  <td key={`chart-${index}`} className={`${isChartDisabled ? "blurData select-none" : ""}`}>
                    <div className="flex justify-center">
                      <div onClick={()=> !isChartDisabled && toggleOpenChart(index)} className="interact_button force-static">
                        {openedCharts[index] ? "Hide" : "View"}
                      </div>
                    </div>
                  </td>
                  }
                </tr>
                {openedCharts[index] && (
                  <MiniTableChart 
                    key={index} 
                    apiQuery={
                      yearOnYearOverride 
                      ? additionalFilters 
                      : type === "Routes" 
                        ? `origin=${row.origin}&destination=${row.destination}` 
                        : `${typeMap[type]}=${row[typeMap[type]]}${additionalFilters && `&${additionalFilters}`}`
                    }
                    icao={row.icao ? row.icao : false}
                    type={miniChartType}
                    regs={row.regs ? row.regs : false} />
                )}
              </>
            )
        })}
        </tbody>
      </table>
      {data.length === 1 && data[0].isMissionsEmpty?(<div className='w-full text-center text-[#B6B8BD]'>This aircraft can fly all inputted missions</div>):''}
    </div>
    <div className="w-full flex flex-col md:flex-row gap-4 md:items-center mt-auto">
      {additional && type && <p className="table-count">{Intl.NumberFormat('en-US').format(additional)}&nbsp;{type}</p>}
      {totalPages > 1 && <div className="md:ml-auto flex items-center gap-2">
        {currentPage !== 1 && 
          <button className="border-2 rounded-md py-1 px-2" onClick={() => setCurrentPage(currentPage - 1)}><i className="fa-solid fa-angles-left"></i></button>
        }
        {renderPageNumbers()}
        {currentPage !== totalPages &&
          <button className="border-2 rounded-md py-1 px-2" onClick={() => setCurrentPage(currentPage + 1)}><i className="fa-solid fa-angles-right"></i></button>
        }
        </div>}
    </div>
  </>
  )
};

export default PaginatedTable;

