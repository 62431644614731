import React from 'react'
import numeral from 'numeral';
const Table = ({ data, rows }) => {

    const RenderTableRow = ({displayName,childDisplayNames, variableName,childvariableNames, isNumeral = false,positiveType,keepDecimal=false,isBlured=false}) => {
      const values = data.map(aircraft=>aircraft[variableName])
      const positiveValue = isNumeral && (positiveType === 'high' ? Math.max(...values) :positiveType === 'low'? Math.min(...values):false)
      const childrenPositiveValues = childvariableNames?.map(variable=> {
        const values = data.map(aircraft=>aircraft[variable])
        return Math.max(...values)
      })
      
      return (
        <tr>
          <td className='font-medium'>
            <div className="flex justify-between gap-4">
              <h6>{displayName}</h6>
              {childDisplayNames && (
                <div className="flex flex-col gap-5">
                  {
                    childDisplayNames.map((name,i)=>(
                      <p key={i} className='text-grey py-1'>{name}</p>  
                    ))
                  }
                </div>
              )}
            </div>
          </td>
          {data.map((aircraft) => {
            const value = aircraft[variableName];
            const formatValue = (value) => {
              if (value === true) return "Yes";
              if (value === false) return "No";
              return value;
            }
            return (
              <td className={`text-grey ${isBlured ? "blurData select-none" : ""}`}>
                {
                  childvariableNames ? (
                    <div className="flex flex-col gap-5">
                      {
                        childvariableNames.map((variable,i)=>{
                          const value = aircraft[variable];
                          return (
                            <React.Fragment key={i}>
                              {
                                isBlured ? (
                                  <div className={`w-fit mx-auto`}>
                                    {'00'}
                                  </div>
                                ) : (
                                  <p className={`w-fit mx-auto py-1 ${childrenPositiveValues[i] && childrenPositiveValues[i] === value ? "positiveChange change" :""}`}>{value}</p>
                                )
                              }
                            </React.Fragment>
                          )
                        })
                      }
                    </div>
                  ): (
                    isBlured ? (
                      <div className={`w-fit mx-auto`}>
                        {isNumeral ? '00' : 'null'}
                      </div>
                    ) : (
                      <div className={`w-fit mx-auto ${positiveValue && positiveValue === value ? "positiveChange change" :""}`}>
                        {(isNumeral && !keepDecimal) ? value === 0 ? '-' : numeral(value).format("0,0") : formatValue(value)}
                      </div>
                    )
                  )
                }
              </td>
            );
          })}
        </tr>
      );
    };
  return (
    <div className='overflow-x-auto'>
      <table className='cpp_table !table-fixed w-full'>
        <thead className='!text-black !bg-transparent !capitalize !font-medium'>
          <tr>
            <th></th>
            {data.map(aircraft => {
              return <th>{aircraft.aircraft_name}</th>
            })}
          </tr>
        </thead>
        <tbody className='first-col-left'>
          {rows.map((row,i) => {
            return <RenderTableRow key={i} {...row}/>
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
