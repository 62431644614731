import React, { useState, useEffect } from 'react'
import apiService from '../../services/api'
import PaginatedTable from '../shared/paginatedTable'
import CountryHeatMap from './CountryHeatMap'

const MostRegisteredCountries = ({
  title = 'Most Registered Countries',
  presetFilters
}) => {
  const [data, setData] = useState(null)
  const [fetching, setFetching] = useState(false)
  useEffect(() => {
    async function fetchData () {
      setFetching(true)
      const airportResponse = await apiService.getMostRegisteredCountries(
        presetFilters
      )
      console.log(airportResponse)
      setData(airportResponse)
      setFetching(false)
    }
    fetchData();
  }, [])
  return (
    <div className='card no-padding grid lg:grid-cols-2'>
      <div className='map-height rounded-t-lg lg:rounded-r-none lg:rounded-l-lg overflow-hidden'>
        {data && (
          <CountryHeatMap data={data} containerId={`heatmap-3`} />
        )}
      </div>

      <div className='live-flights-container flex flex-col gap-4 p-4'>
        <h3 className='text-3xl'>{title}</h3>

        {fetching && (
          <div className='my-auto flex flex-col justify-center items-center'>
            <i className='fa fa-spinner fa-spin text-6xl'></i>
            <h3 className='text-3xl mt-6'>Loading Data...</h3>
          </div>
        )}

        {!fetching && !data && (
          <div className='my-auto flex flex-col justify-center items-center'>
            <i className='fa fa-solid fa-plane-circle-exclamation text-4xl'></i>
            <h3 className='text-xl mt-6'>No Data Available.</h3>
          </div>
        )}
        {!fetching && data && (
          <PaginatedTable
            data={data}
            additional={data.length}
            type='Countries'
            includeChart={true}
            miniChartType='mostRegisteredCountries'
          />
        )}
      </div>
    </div>
  )
}

export default MostRegisteredCountries
