import axios from "axios";
import { API_URL } from "../utils/constants/app-constants";

class apiService { 
  async getAircraftByName(name) {
    const response = await axios.get(API_URL + "aircraft-by-name/" + name + "/");
    if (response.data === null) return;
    return response.data;
  }

  async getAircraftById(aircraft_id) {
    console.log(aircraft_id);
    const response = await axios.get(
      API_URL + "aircrafts/" + aircraft_id + "/"
    );
    console.log(API_URL + "aircrafts/" + aircraft_id + "/");

    if (response.data === null) return;
    console.log(response.data.aircraft);
    return response.data.aircraft;
  }

  async getAircrafts(additionalFilters) {
    const response = await axios.get(API_URL + "aircraft-items/" + (additionalFilters ? "?" + additionalFilters : ""));
    console.log(response.data);
    if (response.data === null) return;
    return response.data;
  }

  async getDirectoryAircrafts(_,additionalFilters) {
    const response = await axios.get(API_URL + "aircrafts-directory/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getLiveFlights(additionalFilters) {
    const response = await axios.get(API_URL + "live-flights/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getPrevious24MonthFlights(additionalFilters) {
    const response = await axios.get(API_URL + "twentyfour-month-flight-history/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsForPreviousYears(additionalFilters) {
    const response = await axios.get(API_URL + "flights-for-previous-years/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getHoursForPreviousYears(regs) {
    const response = await axios.post(API_URL + "hours-for-previous-years/",{regs});
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByPeriod() {
    const response = await axios.get(API_URL + "flights-by-period/");
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByContinent(timePeriod, additionalFilters) {
    const response = await axios.get(`${API_URL + "flights-by-continent/" + timePeriod + "/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByCategory(timePeriod,additionalFilters){
    const response = await axios.get(`${API_URL}flights-by-category/${timePeriod}/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByMake(timePeriod,additionalFilters){
    const response = await axios.get(`${API_URL}flights-by-make/${timePeriod}/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByModel(timePeriod,additionalFilters){
    const response = await axios.get(`${API_URL}flights-by-model/${timePeriod}/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getAircraftsByCountry(_,additionalFilters){
    const response = await axios.get(`${API_URL}aircrafts-by-country/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getAircraftsByCategory(_,additionalFilters){
    const response = await axios.get(`${API_URL}aircrafts-by-category/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getAircraftsByMake(_,additionalFilters){
    const response = await axios.get(`${API_URL}aircrafts-by-make/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getAircraftsByModel(_,additionalFilters){
    const response = await axios.get(`${API_URL}aircrafts-by-model/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getMostRegisteredCountries(additionalFilters){
    const response = await axios.get(`${API_URL}most-registered-countries/${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByDomesticVsInternational(timePeriod){
    const response = await axios.get(API_URL + "domestic-vs-int/" + timePeriod + "/");
    if (response.data === null) return;
    return response.data;
  }

  async getFlightHistory(additionalFilters){
    const response = await axios.get(`${API_URL + "flight-history/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightTotalHistory(additionalFilters,reg=[]){
    const response = await axios.post(`${API_URL + "flight-total-history/"}${additionalFilters ? "?" + additionalFilters : ""}`,{
      reg
    });
    if (response.data === null) return;
    return response.data;
  }

  async getFlightHoursHistory(additionalFilters,reg=[]){
    const response = await axios.post(`${API_URL + "flight-hours-history/"}${additionalFilters ? "?" + additionalFilters : ""}`,{
      reg
    });
    if (response.data === null) return;
    return response.data;
  }

  async getFlightDistanceHistory(additionalFilters){
    const response = await axios.get(`${API_URL + "flight-distance-history/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByMonthByYear({year,additionalFilters}){
    const response = await axios.get(`${API_URL + "flights-by-month-for-year/" + year + "/"}${additionalFilters ? "?" + additionalFilters : ""}` );
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByAirport({timePeriod, additionalFilters}){
    console.log(additionalFilters)
    const response = await axios.get(`${API_URL + "flights-by-airport/" + timePeriod + "/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByCity({timePeriod, additionalFilters}){
    const response = await axios.get(`${API_URL + "flights-by-city/" + timePeriod + "/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getFlightsByCountry({timePeriod, additionalFilters}){
    const response = await axios.get(`${API_URL + "flights-by-country/" + timePeriod + "/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getTopRoutes(timePeriod, additionalFilters){
    const response = await axios.get(`${API_URL + "top-routes/" + timePeriod + "/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getStockPrices(){
    const response = await axios.get(API_URL + "stock-prices/");
    if (response.data === null) return;
    return response.data;
  }

  async getLatestNews(){
    const response = await axios.get(API_URL + "latest-news/");
    if (response.data === null) return;
    return response.data;
  }

  async getRegistrations(timePeriod, additionalFilters){
    const response = await axios.get(`${API_URL + "registrations/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }

  async getAccidents(timePeriod, additionalFilters){
    const response = await axios.get(`${API_URL + "accidents/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if (response.data === null) return;
    return response.data;
  }
  
  async lookupAircraft(registration){
    const response = await axios.get(API_URL + "aircraft-lookup/" + registration + "/");
    if (response.data === null) return;
    return response.data;
  }

  async getRegNumbersForAircraft(additionalFilters){
    const response = await axios.get(API_URL + "reg-numbers-for-aircraft/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getFleetActivity(regNumbers = [],additionalFilters){
    const response = await axios.post(API_URL + "fleet-activity/" + (additionalFilters ? "?" + additionalFilters : ""),{reg:regNumbers});
    if (response.data === null) return;
    return response.data;
  }

  async getFleetData(additionalFilters){
    const response = await axios.get(API_URL + "fleet-data/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getAvgAnnualHoursAndDistance(additionalFilters){
    const response = await axios.get(API_URL + "avg-annual-hours-and-distance/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async getLast12MonthsFlights(additionalFilters){
    const response = await axios.get(API_URL + "last-12-months-flights/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }
  async getAIAircraftOverview(id, question){
    
   
    const response = await axios.get(API_URL + "ai-aircraft-overview/" + id + "/" + question, {responseType : 'stream'});
    // return response
    return response
  }
  async getAIIndustryHealth(additionalFilters){
    try{
      const response = await axios.get(API_URL + "ai-health-industry/" + (additionalFilters ? "?" + additionalFilters : ""))
      if(response.data == null)return;
      return response.data;
    }catch(error){
      throw error
    }
  }
  async getAIAircraftHealth(additionalFilters){
    const response = await axios.get(API_URL + "ai-health-aircraft/" + (additionalFilters ? "?" + additionalFilters : ""))
    if(response.data == null)return;
    return response.data;
  }
  async lookupAirport(icaoCode){
    const response = await axios.get(API_URL + "airport-lookup/" + icaoCode + "/");
    if (response.data === null) return;
    return response.data;
  }
  async getAirportDetails(icaoCode){
    const response = await axios.get(API_URL + `airport-details/${icaoCode}/`)
    if(response.data == null)return;
    return response.data;
  }
  async getOwnerCompanySuggestions(query){
    const response = await axios.get(API_URL + "owner-company-suggestions/" + (query))
    if(response.data == null)return;
    return response.data;
  }
  async getAirportSuggestions(query){
    const response = await axios.get(API_URL + "aiport-lookup-suggestions/" + (query))
    if(response.data == null)return;
    return response.data;
  }
  async getAirportOperators(additionalFilters){
    const response = await axios.get(API_URL + "operators-by-airport/" + (additionalFilters ? "?" + additionalFilters : ""))
    if(response.data == null)return;
    return response.data;
  }

  async getAircraftModels(additionalFilters){
    const response = await axios.get(API_URL + "aircraft-models-by-airport/" + (additionalFilters ? "?" + additionalFilters : ""))
    if(response.data == null)return;
    return response.data;
  }
  async getAircraftsByAirport(additionalFilters){
    const response = await axios.get(API_URL + "aircrafts-by-airport/" + (additionalFilters ? "?" + additionalFilters : ""))
    if(response.data == null)return;
    return response.data;
  }
  async getAirportActivity(icaoCode){
    const response = await axios.get(API_URL + `airport-activity/${icaoCode}/`)
    if(response.data == null)return;
    return response.data;
  }
  async getOperatorSuggestions(query){
    const response = await axios.get(API_URL + "operator-lookup-suggestions/" + (query))
    if(response.data == null)return;
    return response.data;
  }
  async getOperators(timePeriod, additionalFilters){
    const response = await axios.get(`${API_URL + "operators/"}${additionalFilters ? "?" + additionalFilters : ""}`);
    if(response.data == null)return;
    return response.data;
  }
  async getSingleOperators(operator){
    const response = await axios.get(`${API_URL}operator-by-name/${operator}`);
    if(response.data == null)return;
    return response.data;
  }

  async getOperatorFleetData(timeperiod,additionalFilters){
    const response = await axios.get(API_URL + "operator-fleet-data/" + (additionalFilters ? "?" + additionalFilters : ""));
    if (response.data === null) return;
    return response.data;
  }

  async aiSearchAircrafts(questions,additionalFilters){
    const response = await axios.get(API_URL + `jet-finder/?questions=${JSON.stringify(questions)}` + (additionalFilters ? "&" + additionalFilters : ""))
    if (response.data === null) return;
    return response.data;
  }
}

export default new apiService();
