import { useEffect, useState, useCallback } from "react";
import { useBeforeUnload } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { AUTHENTICATION_COOKIE_KEY, USERlEVELS } from "../utils/constants/app-constants";
import { API_URL } from "../utils/constants/app-constants";

function useQuestionLimitation() {
  const cookies = Cookies.get();
  const [, loggedInUserLogin] = Object.entries(cookies).find(([key]) =>
    key.includes(AUTHENTICATION_COOKIE_KEY)
  ) || [undefined, undefined];
  const userName = decodeURI(loggedInUserLogin);
  console.log("userName");
  console.log(typeof userName);
  console.log(userName);
  const [isUnlimitedAiSearchAircraft, setIsUnlimitedAiSearchAircraft] = useState(false);
  const [limitation, setLimitation] = useState(20);
  const [aiSearchAircraftLimitation, setAiSearchAircraftLimitation] =
    useState(0);

  const fetchQuestionLimitation = useCallback(async () => {
    try {
      if (!userName || userName === "undefined")
        throw new Error("No access code found");

      const { data: targetUserMemberships } = await axios(
        `https://compareprivateplanes.com/wp-json/wp/v2/user-membership/`,
        { params: { userlogin: userName } }
      )
      const activeMemberships = targetUserMemberships.filter(membership => {
        const expirationDate = new Date(membership.expiration_date)
        return membership.status === 'active' && (membership.expiration_date?expirationDate > new Date():true)
      })
      activeMemberships.reverse()
      const unlimitedUserLevels = ['77','78']
      let userMemberShip = {};
      for (let i = 0; i < activeMemberships.length; i++) {
        const object_id = activeMemberships[i].object_id
        if (USERlEVELS[object_id]) {
          if (unlimitedUserLevels.includes(object_id)){
            setIsUnlimitedAiSearchAircraft(true)
          } else {
            userMemberShip = activeMemberships[i]
            setIsUnlimitedAiSearchAircraft(false)
          }
          break
        }
      }

      await axios
        .post(`${API_URL}update-limitation/`, { 
          name: userName,
          userLevelCode:userMemberShip.object_id,
          memberShipExpirationDate:userMemberShip.expiration_date,
        })
        .then((res) => {
          setLimitation(res.data.limitation);
          setAiSearchAircraftLimitation(res.data.searchAircraftLimitation || 0)
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log("Error in user");
      setLimitation(-1);
    }
  });

  useBeforeUnload(
    useCallback(() => {
      Cookies.remove(AUTHENTICATION_COOKIE_KEY);
      localStorage.setItem("test", "cleared");
    }, [])
  );

  useEffect(() => {
    fetchQuestionLimitation();
  }, [fetchQuestionLimitation]);

  return {
    loggedInUsername: userName,
    isUnlimitedAiSearchAircraft,
    limitation,
    setLimitation,
    aiSearchAircraftLimitation,
    setAiSearchAircraftLimitation
  };
}

export default useQuestionLimitation;
