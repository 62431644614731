import React, { useContext } from 'react'
import DonutChartCard from '../../components/shared/donutCard'
import SimpleTableCard from '../../components/shared/simpleTableCard'
import MostRegisteredCountries from '../../components/AllAircrafts/MostRegisteredCountries'
import AppContext from '../../custom-context/appContext'
import { TABLE_BLURED_VALUE } from '../../utils/constants/app-constants'

export default function AllAircrafts () {
  const { userLevel } = useContext(AppContext);
  const hasLimitedAccess = ["jet_finder","pro_jet_finder"].includes(userLevel);
  const directoryTranfromFunction = (response = {data:[]})=>{
  const bluredKeys = ["reg_number","serial_number","owner/operator","charter_aircraft","Year","annual_hours","lookup"]
    return response.data.map((obj)=>{
      const updatedData = Object.fromEntries(Object.entries(obj).map(([key,value])=>(
        [key, bluredKeys.includes(key) ? TABLE_BLURED_VALUE : value]
      )))
      return updatedData;
    });
  }
  return (
    <div className='flex flex-col gap-12'>
      <div className='grid md:grid-cols-2 lg:grid-cols-4 md:gap-6 gap-8'>
        <DonutChartCard
          title='Aircraft by Region'
          apiRoute='getAircraftsByCountry'
        />
        <DonutChartCard
          title='Aircraft by Class'
          apiRoute='getAircraftsByCategory'
        />
        <DonutChartCard
          title='Aircraft by Make'
          apiRoute='getAircraftsByMake'
        />
        <DonutChartCard
          title='Aircraft by Model'
          apiRoute='getAircraftsByModel'
        />
      </div>
      <SimpleTableCard
        title='Directory'
        type='Aircraft'
        apiRoute='getDirectoryAircrafts'
        showFilters
        showAdditionalFilters
        maxHeight='100vh'
        disableYearFilter={hasLimitedAccess}
        disableChartFilter={hasLimitedAccess}
        transformDataFunction={hasLimitedAccess && directoryTranfromFunction}
      />
      <MostRegisteredCountries />
    </div>
  )
}
