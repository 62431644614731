import React, { useState, useEffect } from "react";
import { AIRCRAFT_REGNUMBER_COUNTRY_PREFIXES, FILTER_OPTIONS } from "../../utils/constants/app-constants";
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import apiService from "../../services/api";
import LockImage from "../common/lockImage";

const RangeInput = ({ setState, label, value, isDisabled=false }) => {
  const [startValue, setStartValue] = useState(value ? value.split('_')[0] :'');
  const [endValue, setEndValue] = useState(value ? value.split('_')[1] : '');

  const handleStartChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 0) {
      setStartValue(value);
    }
  };

  const handleEndChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 0) {
      setEndValue(value);
    }
  };

  const handleClick = () => {
    if (startValue !== undefined && endValue !== undefined && endValue >= startValue) {
      setState(`${startValue}_${endValue}`);
    }
  };
  
  const reset = ()=>{
    setStartValue('')
    setEndValue('')
    setState('')
  }
  return (
    <div className="flex flex-col gap-2">
      <label className="font-bold">{label}:</label>
      <div className="flex gap-2 justify-between">
        <input
          type="number"
          disabled={isDisabled}
          // max={endValue}
          value={startValue}
          placeholder="Start"
          onChange={handleStartChange}
          className="border border-solid border-gray-300 rounded-lg shadow-md py-1 px-2 text-center text-base text-normal w-[90px]"
        />
        <input
          type="number"
          disabled={isDisabled}
          // min={startValue}
          value={endValue}
          placeholder="End"
          onChange={handleEndChange}
          className="border border-solid border-gray-300 rounded-lg shadow-md py-1 px-2 text-center text-base text-normal w-[90px]"
        />
      </div>
      <div className="flex gap-2">
        <button disabled={isDisabled} onClick={handleClick} className="flex-1 py-1 px-2 bg-black text-white rounded-lg">Filter</button>
        {
          value && (
            <button onClick={reset} className="flex-1 py-1 px-2 bg-black text-white rounded-lg">Unset</button>
          )
        }
      </div>
    </div>
  );
};

const OwnerSelect = ({ state, setState,isDisabled=false }) => {
  const [selectedValue, setSelectedValue] = useState({
    label: state,
    value: state,
  })
  async function searchSuggestions (inputValue) {
    return new Promise(async resolve => {
      if (!inputValue) inputValue = 'b'
      const res = await apiService.getOwnerCompanySuggestions(inputValue)
      const formatedData = res.data.map(({ companyName }) => ({
        label: companyName,
        value: companyName
      }))
      formatedData.unshift({label:'All',value:''})
      resolve(formatedData)
    })
  }
  const customStyles = {
    container: styles => ({
      ...styles,
      width: '190px'
    }),
    input: styles => ({
      ...styles,
      borderRadius: '8px'
    }),
    control: styles => ({
      ...styles,
      borderRadius: '8px',
      border: '1px solid rgb(209 213 219)',
      boxShadow:
        'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px'
    })
  }
  const handleChange = e => {
    setSelectedValue(e)
    setState(e.value)
  }
  return (
    <AsyncSelect
      styles={customStyles}
      cacheOptions
      defaultOptions
      defaultValue={{label:"All", value:''}}
      name='owner'
      onChange={(e)=> !isDisabled && handleChange(e)}
      loadOptions={searchSuggestions}
      value={selectedValue}
      menuPosition='fixed'
    />
  )
}
const ChartFilters = ({ setApiFilters,showOtherfilters=false,disableFilters=false }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [classFilters, setClassFilters] = useState([]);
  const [makeFilters, setMakeFilters] = useState([]);
  const [modelFilters, setModelFilters] = useState([]);
  const [country, setCountry] = useState('');
  const [mfryear, setMfryear] = useState('');
  const [annualhours, setAnnualhours] = useState('');
  const [owner, setOwner] = useState('');
  const [isCharter, setIsCharter] = useState(null);
  const [filterIsActive, setFilterIsActive] = useState(false);

  // Dynamically calculate the unique options based on selected filters
  const getFilteredOptions = () => {
    const filteredOptions = FILTER_OPTIONS.filter(item =>
      (classFilters.length === 0 || classFilters.includes(item.class)) &&
      (makeFilters.length === 0 || makeFilters.includes(item.make)) &&
      (modelFilters.length === 0 || modelFilters.includes(item.model))
    );

    return {
      uniqueMakes: [...new Set(filteredOptions.map(item => item.make))],
      uniqueModels: [...new Set(filteredOptions.map(item => item.model))],
      uniqueClasses: [...new Set(filteredOptions.map(item => item.class))]
    };
  };

  const { uniqueMakes, uniqueModels, uniqueClasses } = getFilteredOptions();

  const handleClassFilterChange = (selectedClass) => {
    setClassFilters(prevFilters =>
      prevFilters.includes(selectedClass)
        ? prevFilters.filter(filter => filter !== selectedClass)
        : [...prevFilters, selectedClass]
    );
  };

  const handleMakeFilterChange = (selectedMake) => {
    setMakeFilters(prevFilters =>
      prevFilters.includes(selectedMake)
        ? prevFilters.filter(filter => filter !== selectedMake)
        : [...prevFilters, selectedMake]
    );
  };

  const handleModelFilterChange = (selectedModel) => {
    setModelFilters(prevFilters =>
      prevFilters.includes(selectedModel)
        ? prevFilters.filter(filter => filter !== selectedModel)
        : [...prevFilters, selectedModel]
    );
  };

  useEffect(() => {
    const createQueryString = (params) => {
      return params
        .map(param => param.values.map(value => `${param.key}=${encodeURIComponent(value)}`).join('&'))
        .join('&');
    };

    const selectedFilters = [
      { key: 'class', values: classFilters },
      { key: 'make', values: makeFilters },
      { key: 'model', values: modelFilters },
      { key: 'country', values: country?[country]: [] },
      { key: 'ischarter', values: typeof isCharter === 'boolean' ?[isCharter]: [] },
      { key: 'mfryear', values: mfryear?[mfryear]: [] },
      { key: 'annualhours', values: annualhours?[annualhours]: [] },
      { key: 'owner', values: owner?[owner]: [] },
    ].filter(param => param.values.length > 0);

    const queryString = createQueryString(selectedFilters);

    setFilterIsActive(queryString.length > 0);
    setApiFilters(queryString);
  }, [classFilters, makeFilters, modelFilters, country, isCharter, mfryear, annualhours,owner]);

  const resetFilters = () => {
    setClassFilters([]);
    setMakeFilters([]);
    setModelFilters([]);
    setCountry('')
    setMfryear('')
    setAnnualhours('')
    setOwner('')
    setIsCharter(null);
    setFilterIsActive(false);
    setIsDropdownVisible(false);
  };

  return (
    <div className="relative">
      <div className={`${filterIsActive ? "filter-active" : ""} interact_button`}onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
        <i className="fa-solid fa-filter"></i>
        <p>Filter</p>
        {disableFilters && <LockImage />}
      </div>

      {isDropdownVisible && (
        <div className="card dropdown-menu rounded-md max-md:max-w-full max-lg:max-w-[calc(100vw-127px)] overflow-x-auto">
          <div className="flex">
            <div>
              <div className="whitespace-nowrap">
                <h4 className="border-b-2 border-[#F3F5F9] px-4 text-xl whitespace-nowrap mb-2">Filter by Class</h4>
                {uniqueClasses.map((option, index) => (
                  <div
                    key={index}
                    className={`${disableFilters ? "" : "cursor-pointer"} rounded-lg py-1 mb-1 mr-1 pl-4 ${classFilters.includes(option) ? 'bg-gray-200' : ''}`}
                    onClick={() => !disableFilters && handleClassFilterChange(option)}
                  >
                    <h5>{option}</h5>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <div className="whitespace-nowrap">
                <h4 className="border-b-2 border-[#F3F5F9] px-4 text-xl whitespace-nowrap mb-2">Filter by Make</h4>
                <div className="options-list">
                  {uniqueMakes.map((option, index) => (
                    <div
                      key={index}
                      className={`${disableFilters ? "" : "cursor-pointer"} rounded-lg py-1 mb-1 mr-1 pl-4 ${makeFilters.includes(option) ? 'bg-gray-200' : ''}`}
                      onClick={() => !disableFilters && handleMakeFilterChange(option)}
                    >
                      <h5>{option}</h5>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className="whitespace-nowrap">
                <h4 className="border-b-2 border-[#F3F5F9] px-4 text-xl whitespace-nowrap mb-2">Filter by Model</h4>
                <div className="options-list">
                  {uniqueModels.map((option, index) => (
                    <div
                      key={index}
                      className={`${disableFilters ? "" : "cursor-pointer"} rounded-lg py-1 mb-1 mr-1 pl-4 ${modelFilters.includes(option) ? 'bg-gray-200' : ''}`}
                      onClick={() => !disableFilters && handleModelFilterChange(option)}
                    >
                      <h5>{option}</h5>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {
              showOtherfilters && (
                <div>
                  <div className="whitespace-nowrap">
                    <h4 className="border-b-2 border-[#F3F5F9] px-4 text-xl whitespace-nowrap mb-2">Other Filters</h4>
                    <div className="options-list px-1 flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <h5 className="font-bold">Country:</h5>
                        <Select
                          className=''
                          defaultValue={{ value: '', label: 'All' }}
                          menuPosition='fixed'
                          onChange={option => !disableFilters && setCountry(option.value)}
                          options={
                            [
                              {value:'', label:'All'},
                              ...AIRCRAFT_REGNUMBER_COUNTRY_PREFIXES.map(([prefix,country])=>(
                                { value: country, label: country }
                              ))
                            ]
                          }
                          value={{value:country,label:country || 'All'}}
                          styles={{
                            input: styles => ({
                              ...styles,
                              borderRadius: '8px'
                            }),
                            control: styles => ({
                              ...styles,
                              borderRadius: '8px',
                              border: '1px solid rgb(209 213 219)',
                              boxShadow:
                                'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px'
                            })
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <h5 className="font-bold">Owner/Operator:</h5>
                        <OwnerSelect setState={setOwner} state={owner} isDisabled={disableFilters} />
                      </div>
                      <RangeInput
                        label='MFR Year'
                        setState={setMfryear}
                        value={mfryear}
                        isDisabled={disableFilters}
                       />
                       <RangeInput
                        label='Annual Hours'
                        setState={setAnnualhours}
                        value={annualhours}
                        isDisabled={disableFilters}
                       />
                      <div className="flex flex-col gap-2">
                        <h5 className="font-bold">charter Aircraft:</h5>
                        <Select
                          className=''
                          defaultValue={{ value: null, label: 'All' }}
                          menuPosition='fixed'
                          onChange={option => !disableFilters && setIsCharter(option.value)}
                          options={
                            [
                              {value:null, label:'All'},
                              { value: true, label: "Yes" },
                              { value: false, label: "No" },
                            ]
                          }
                          value={{value: isCharter,label: isCharter === null ? 'All' : isCharter ? "Yes": 'No'}}
                          styles={{
                            input: styles => ({
                              ...styles,
                              borderRadius: '8px'
                            }),
                            control: styles => ({
                              ...styles,
                              borderRadius: '8px',
                              border: '1px solid rgb(209 213 219)',
                              boxShadow:
                                'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px'
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>

          {filterIsActive && (
            <div className="w-full flex justify-end mt-2">
              <button
                className="py-2 px-4 bg-cetaceanBlue text-white rounded-md"
                onClick={() => resetFilters()}
              >
                Reset Filters
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ChartFilters;