import numeral from 'numeral'
import CardHeading from './CardHeading'
import { FaRegCompass } from 'react-icons/fa'
import { LuMountain } from 'react-icons/lu'
import { SiGumtree } from "react-icons/si";


const PerformanceData = ({ params, unit, hasLimitedAccess = false }) => {
  const isImperialUnits = unit === 'Imperial Units';
  const altitudeUnit = isImperialUnits ? 'Feet' : 'Meters'
  const LollipopBarChart = ({isLanding, value, maxValue}) => {
    const verticalLinesPercentage = [
      4,4,4,4,4,5,5,5,5,6,6,7,7,8,8,9,9,10,10,11,11,12,12,13,13,14,14,15,15,16,16,17,17,18,18,19,19,20,20,21,21,22,22,23,23,24,25,26,27,28,29,30,32,34,36,38,40,45,50,55,60,70,85,100
    ]
    const valuePercentage = (value / maxValue) * 100;
    const color = isLanding ? "#64D090" : "#5078FF";
    return (
      <div className="relative">
        <div className={`h-[100px] flex justify-between gap-0.5 lg:gap-1 max-w-full ${isLanding ? "items-start" : "items-end"}`}>
          {
            verticalLinesPercentage.map((line,i)=>(
              <div key={i} style={{height:`${line}%`, backgroundColor: valuePercentage >= line ? color :"#e6e4e5"}} className={`w-1 rounded-full ${line<60?"":"shrink-0 max-lg:ml-0.5"}`} />
            ))
          }
        </div>
        <div className={`absolute ${isLanding ? "bottom-0 mb-3" : "top-0 mt-3"} left-0`}>
          <h6 className='font-semibold text-lg'>{isLanding ? "Landing" : "Take off"}</h6>
          <p className='text-quickSilver text-sm'>{numeral(value).format("0,0")} {altitudeUnit}</p>
        </div>
      </div>
    )
  };
  const GroundPerformance = () => {
    const variableUnit = isImperialUnits ? 'feet' : 'meters'
    const TO_distance = params[`TO_distance_${variableUnit}`]
    const landing_distance = params[`landing_distance_${variableUnit}`]
    return (
      <div>
        <CardHeading title="Ground Performance" icon={SiGumtree} />
        <div className='mt-6'>
          <LollipopBarChart  value={TO_distance} maxValue={TO_distance}/>
          <hr className='my-1.5 border-platinum' />
          <LollipopBarChart  isLanding value={landing_distance} maxValue={TO_distance}/>
        </div>
      </div>
    )
  }
  const CruiseSpeedRow = ({label, variable, heighColor, longeColor,longPercent, heighPercent})=>{
    const bars = {
      heigh: {
        value: variable === "kmh" ? params[`high_speed_cruise_${variable}`] :  params[`high_cruise_${variable}`],
        color: heighColor,
        percentage: heighPercent,
      },
      long: {
        value: params[`long_range_cruise_${variable}`],
        color: longeColor,
        percentage: longPercent
      }
    }
    return (
      <tr className='group font-medium'>
        <td className='text-lg pr-2'>{label}</td>
        <td className='py-4 pl-4 w-full border-b group-last:border-b-0 '>
          <table>
            <tbody>
              {
                Object.entries(bars).map(([key,bar],i)=>(          
                  <tr key={i}>
                    <td className="md:whitespace-nowrap px-2"><p>{bar.value}</p></td>
                    <td className="w-full px-2">
                      <div className="rounded-2xl overflow-hidden bg-[#e6e4e5] h-2 w-full">
                        <div className="h-full" style={{width: `${bar.percentage}%`, background: bar.color}}></div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </td>
      </tr>
    )
  }
  const AltitudePerformance = ()=>{
    const rows = [
      {
        label: "Initial Cruise Altitude",
        description: altitudeUnit,
        value: params[`initial_cruise_altitude${isImperialUnits ? '' : '_meters'}`],
        heighestValue: isImperialUnits ? 45000 : 13716,
      },
      {
        label: "Max Altitude ",
        description: altitudeUnit,
        value: params[`max_altitude_${isImperialUnits ? 'feet' : 'meters'}`],
        heighestValue: isImperialUnits ? 51000 : 15545,
      },
      {
        label: "Rate of Climb",
        description: `${altitudeUnit} per minute`,
        value: params[`rate_climb${isImperialUnits ? '' : '_meters'}`],
        heighestValue: isImperialUnits ? 5480 : 1670,
      },
      {
        label: "Fuel Burn",
        description: `${isImperialUnits ? 'Gallons' : 'Liters'} per hour`,
        value: params[`hourly_fuel_burn_${isImperialUnits ? 'GPH' : 'LPH'}`],
        heighestValue: isImperialUnits ? 626 : 2370,
      },
    ]
    return (
      <div className='w-full'>
        <CardHeading title="Altitudes" icon={LuMountain} />
        <table className='mt-2'>
          <tbody className=''>
            {
              rows.map(({label, description, value, heighestValue},i)=>(
              <tr className='border-b last:border-b-0 font-medium'>
                <td className='text-lg pr-2'>
                  <h6 className='text-CharlestonGreen text-base whitespace-nowrap'>{label}</h6>
                  <p className='text-quickSilver text-sm'>{description}</p>
                </td>
                <td className='py-4 pl-4 w-full'>
                    <p className="text-quickSilver text-end text-sm">{numeral(value).format("0,0")}</p>
                    <div className="rounded-2xl overflow-hidden bg-[#e6e4e5] h-2 w-full">
                      <div className="h-full" style={{width: `${(value / heighestValue) * 100}%`, background: "#5078FF"}}></div>
                    </div>
                </td>
              </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-14'>
      <div>
        <CardHeading title="Cruise Speed" icon={FaRegCompass} />
        <table className='mt-2'>
          <tbody className=''>
            {
              ["knots",isImperialUnits ? 'MPH' : 'kmh',"Mach"].map((variable)=>(
                  <CruiseSpeedRow label={variable} variable={variable} heighColor="#CC89FF" longeColor="#5078FF"
                   heighPercent={(params[`high_cruise_knots`]/550)*100}
                   longPercent={(params[`long_range_cruise_knots`]/550)*100}
                  />
                ))
            }
          </tbody>
        </table>
        <ul className="flex justify-center items-center flex-wrap gap-4">
            {
              [{label:"Long-Range Cruise",color:"#5078FF"},{label:"High-Speed Cruise",color:"#CC89FF"}].map(({label, color})=>(
                <li className={`flex gap-3 items-center`}>
                  <div className="size-3 rounded-full shrink-0" style={{backgroundColor: color}} />
                  <p className="text-left">{label}</p>
                </li>
              ))
            }
        </ul>
      </div>
      <AltitudePerformance />
      <GroundPerformance />
    </div>
  )
}

export default PerformanceData
