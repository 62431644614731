import cn from "classnames";
import global from "../styles/global.module.scss";
import SectionHeader from "../shared/SectionHeader";
import numeral from "numeral";
import {
  GoogleMap,
  Circle,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styles from "./styles/styles.module.scss";
import CardHeading from "../SingleAircraft/CardHeading";
import { FaRegMap } from "react-icons/fa";

const Map = ({ latLng, circles, containerClassName }) => (
  <GoogleMap
    zoom={2}
    center={latLng}
    options={{
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    }}
    mapContainerClassName={`h-[400px] md:h-full rounded-[30px] overflow-hidden ${containerClassName}`}
  >
    {circles.map(({options, onClick= ()=>{}}, index) => (
      <Circle
        key={index}
        center={latLng}
        options={options}
        onClick={onClick}
      />
    ))}
    <Marker position={latLng} />
  </GoogleMap>
);

const RangesRow = ({ title, subTitle, values,isNumeralValues, showDivider }) => {
  return (
    <tr className={`pt-4 pb-3 first:pt-0 last:pb-0 ${showDivider ? "border-b border-gainsboro":""}`}>
      <td className='pt-[inherit] pb-[inherit]'>
        <h6 className='text-CharlestonGreen font-semibold text-lg'>{title}</h6>
        <p className='text-base font-medium text-quickSilver'>{subTitle}</p>
      </td>
      {
        values.map(((value,i)=>(
          <td key={i} className='pl-4 text-right font-bold text-lg'>
            {isNumeralValues ? numeral(value).format('0,0'): value}
          </td>
        )))
      }
    </tr>
  )
}

const Range = ({latLng,setLatLong,address,setAddress, params }) => {
  var rangesDecrease = [
    params[0].range_decrease_per_passenger,
    params[1].range_decrease_per_passenger,
    params[2] !== undefined ? params[2].range_decrease_per_passenger : 0,
  ];
  var rangesNM = [
    params[0].range_NM,
    params[1].range_NM,
    params[2] !== undefined ? params[2].range_NM : 0,
  ];

  {
    const [nbPax, setNbPax] = useState(1);
    const NbSlide= useMemo(()=>Math.max(...params.map(p => p.max_pax)),[params]);
    const calculateRanges = ()=>params.map((aircraft,i)=>{
      const passengers = nbPax <= aircraft.max_pax ? nbPax : aircraft.max_pax
      return rangesNM[i] - passengers * rangesDecrease[i];
    });
  
    const [adjustedRanges,setAdjustedRanges] = useState(calculateRanges())
  
    useEffect(() => {
      setAdjustedRanges(calculateRanges());
    }, [nbPax, params]);
  
    function handleChange(address) {
      setAddress(address);
    }
  
    function handleSelect(address) {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => setLatLong(latLng))
        .catch((error) => console.error("Error", error));
    }
    const generateOptions =(strokeColor, fillColor, adjustedRangeNM)=>({
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      strokeColor,
      fillColor,
      radius: adjustedRangeNM * 1852, // Conversion to meters
    })
  
    var options0 = generateOptions("#FF0000", "#FF0000", adjustedRanges[0])
    var options1 = generateOptions("#0000FF", "#0000FF", adjustedRanges[1])
    var options2 = generateOptions("#FFFF00", "#FFFF00", adjustedRanges[2])

    const [infoOpen, setInfoOpen] = useState(false);
  
    const handleCircleClick = () => {
      setInfoOpen(!infoOpen);
    };    
  
  return (
    <div id="range_map" className='w-full '>
      <LoadScript
        id='script-loader'
        googleMapsApiKey='AIzaSyBoq4qT-mCxXcn5Mx77PAYVWXriJrVEY9A'
        libraries={['places']}
      >
        <div className='w-full grid md:grid-cols-[40%,1fr] gap-10'>
          <div className='flex flex-col justify-between gap-4 md:gap-8'>
            <CardHeading title='Range Map' icon={FaRegMap} />
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <div
                    className={cn(
                      styles.map_inputs,
                      global.pdf_hidden,
                      'flex flex-col gap-4 md:gap-8'
                    )}
                  >
                    <div className='grid grid-cols-[40%,1fr] items-center'>
                      <label className='font-medium text-lg'>Pax number</label>
                      <div className='flex gap-4'>
                        <input
                          type='number'
                          value={nbPax}
                          min={0}
                          max={NbSlide}
                          onChange={e => {
                            const value = parseInt(e.target.value)
                            value >= 0 &&
                              value <= NbSlide &&
                              setNbPax(value)
                          }}
                          className='border border-lightSilver px-2 text-right py-0 h-fit rounded-full max-w-14'
                        />
                        <Slider
                          valueLabelDisplay='auto'
                          aria-label='Volume'
                          value={nbPax}
                          max={NbSlide}
                          onChange={(e, newValue) =>
                            setNbPax(newValue)
                          }
                          sx={{
                            '& .MuiSlider-thumb': {
                              color: '#454545'
                            },
                            '& .MuiSlider-track': {
                              color: '#454545'
                            },
                            '& .MuiSlider-rail': {
                              color: '#acc4e4'
                            },
                            '& .MuiSlider-active': {
                              color: 'green'
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-[40%,1fr] items-center'>
                      <label
                        className='font-medium text-lg'
                        label='startLocation'
                      >
                        Start Location
                      </label>
                      <input
                        {...getInputProps({
                          placeholder: 'Start location',
                          className: 'location-search-input'
                        })}
                        id='startLocation'
                        label='Start Location'
                        className='w-full border border-lightSilver px-2 py-0 h-fit rounded-full'
                      />
                    </div>
                  </div>
                  <div className='autocomplete-dropdown-container'>
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item'
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' }
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <Map
            latLng={latLng} 
            circles={[
              { options: options0, onClick: handleCircleClick },
              { options: options1 },
              { options: options2 }
            ]}
            containerClassName={'md:hidden'} 
          />

            <table className='table-auto'>
              <tbody className=''>
                <RangesRow
                  title={""}
                  subTitle=""
                  values={["Range","Adjusted"]}
                />
                {
                  params.map(((aircraft,i)=>(
                    <RangesRow
                      key={i}
                      title={aircraft.aircraft_name}
                      subTitle='Nautical Miles'
                      values={[rangesNM[i],adjustedRanges[i]]}
                      isNumeralValues
                      showDivider
                    />
                  )))
                }
              </tbody>
            </table>
          </div>
          <Map
            latLng={latLng} 
            circles={[
              { options: options0, onClick: handleCircleClick },
              { options: options1 },
              { options: options2 }
            ]}
            containerClassName={'max-md:hidden'} 
          />
        </div>
      </LoadScript>
    </div>
  );
};
}

export default Range;