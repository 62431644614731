import { useState, useEffect, useContext, useMemo } from "react";
import { StateContext } from "../../context";
import global from "../../components/styles/global.module.scss";
import Axios from "axios";

import StatComparisonCard from "../../components/CompareAircrafts/StatComparisonCard";
import AquisitionCost from "../../components/CompareAircrafts/AquisitionCost";
// import Dimensions from "../../components/CompareAircrafts/Dimensions";
// import Features from "../../components/CompareAircrafts/Features";
// import Interior from "../../components/CompareAircrafts/Interior";
import KeyFacts from "../../components/CompareAircrafts/KeyFacts";
import OwnershipCost from "../../components/CompareAircrafts/OwnershipCost";
// import Performance from "../../components/CompareAircrafts/Performance";
// import Powerplant from "../../components/CompareAircrafts/Powerplant";
import Range from "../../components/CompareAircrafts/Range";
// import Weights from "../../components/CompareAircrafts/Weight";
import ComparisonModal from "../../components/CompareAircrafts/comparisonModal";

import { useSearchParams } from "react-router-dom";

import styles from "./styles.module.scss";
import Dropdown from "../../components/common/Dropdown";
import {
  REGION_OPTIONS,
  CURRENCY_OPTIONS,
  UNIT_OPTIONS,
  FUTURE_OPTIONS,
} from "../../utils/constants/app-constants";
import AppContext from "../../custom-context/appContext";
import CreateShareLinkModal from "../../components/common/CreateShareLinkModal";

const CompareAircrafts = ({defaultPageData, defaultSearchParmasString, readOnly=false}) => {
  const { userLevel } = useContext(AppContext)
  const hasLimitedAccess = ['jet_finder','pro_jet_finder','unlimited_jet_finder'].includes(userLevel)
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { allAircraftData } = useContext(StateContext);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [aircraftsData, setAircraftsData] = useState([]);
  const [estimatedFutureValue, setestimatedFutureValue] = useState(defaultPageData?.estimatedFutureValue || FUTURE_OPTIONS[0]);
  const [estimatedHours, setEstimatedHours] = useState(defaultPageData?.estimatedHours || 0);
  const [rangelatLng, setRangeLatLong] = useState(defaultPageData?.rangelatLng || { lat: 37.772, lng: -80 });
  const [rangeaddress, setRangeaddress] = useState(defaultPageData?.rangeaddress || "");
  const [yearManufacture0, setYearManufacture0] = useState(defaultPageData?.yearManufacture0 || "Select");
  const [yearManufacture1, setYearManufacture1] = useState(defaultPageData?.yearManufacture1 || "Select");
  const [yearManufacture2, setYearManufacture2] = useState(defaultPageData?.yearManufacture2 || "Select");
  const comparisonIds = useMemo(() => {
    const ids = defaultSearchParmasString ? new URLSearchParams(defaultSearchParmasString).get('aircrafts') : searchParams.get("aircrafts");
    return ids ? ids.split(",") : [];
  }, [searchParams]);

  useEffect(() => {
    console.log("Comparison ids triggering")
    setIsLoading(true)
    async function fetchData() {
      const response = await allAircraftData();
      setAircraftsData(response.filter((aircraft) => comparisonIds.includes(aircraft.id.toString())));
      setIsLoading(false)
    }
    if (comparisonIds.length > 0) fetchData();
  }, [comparisonIds]);

  const [unit, setUnit] = useState(UNIT_OPTIONS[0]);
  const [currency, setCurrency] = useState(CURRENCY_OPTIONS[0]);
  const [country, setCountry] = useState(REGION_OPTIONS[0]);
  // const [openModal, setOpenModal] = useState(false);

  const [conversionRate, setConversionRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  useEffect(() => {
    setCurrencySymbol(currency === "USD" ? "$" : currency === "GBP" ? "£" : "€");

    if (currency !== "USD") {
      Axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json`)
      .then((res) => {
        setConversionRate(res[currency.toLowerCase()]);
      });
    }
  }, [currency]);

  const onCurrencyChanged = (val) => {
    setCurrency(val);
  };

  const onUnitChanged = (val) => {
    setUnit(val);
  };

  const onCountryChanged = (val) => {
    setCountry(val);
  };
  const isImperialUnits = unit === "Imperial Units"
  const weightType = isImperialUnits ? "(lbs)" : "(KG)";
  const weightInLength = isImperialUnits ? "(Cubic Feet)" : "(Cubic Meters)";
  const currentDimension =  isImperialUnits ? "(Feet)" : "(Meters)"
  return (
    <>
      {isLoading ? (
          <div className="h-screen flex flex-col justify-center items-center">
            <i className="fa fa-spinner fa-spin text-6xl"></i>
            <h3 className="text-3xl mt-6">Loading Data...</h3>
          </div>
        ):Object.keys(aircraftsData).length === 0 ? <>
            <div style={{width:"100%", height:"64.6svh", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <h1>You have not selected any aircraft to compare</h1>
              {!readOnly && <h1><a href="https://dashboard.compareprivateplanes.com/search">Click here</a> to search aircraft to compare</h1>}
            </div>
          </>
        : <>
          <div className={"space-y-20"}>
            <div className={'flex max-md:flex-col justify-center gap-3 md:gap-4 md:items-center'}>
              <Dropdown
                className={styles.dropdown}
                value={unit}
                setValue={(value) => onUnitChanged(value)}
                options={UNIT_OPTIONS}
              />
              <Dropdown
                className={styles.dropdown}
                value={country}
                setValue={(value) => onCountryChanged(value)}
                options={REGION_OPTIONS}
              />
              <Dropdown
                className={styles.dropdown}
                value={currency}
                setValue={(value) => onCurrencyChanged(value)}
                options={CURRENCY_OPTIONS}
              />
              {!readOnly && (
                <>
                  <ComparisonModal currentIds={comparisonIds} />
                  <CreateShareLinkModal pageType="compare" searchParams={window.location.search} pageData={{
                    estimatedHours,
                    rangeaddress,
                    rangelatLng,
                    estimatedFutureValue,
                    yearManufacture0,
                    yearManufacture1,
                    yearManufacture2,
                  }} />
                </>)
              }
            </div>
            <KeyFacts data={aircraftsData} comparisonIds={comparisonIds} readOnly={readOnly} />
          </div>

          <StatComparisonCard
            id="basic"
            title="Basic Info"
            data={aircraftsData}
            rows={[
              {"displayName": "Production Start", "variableName": "production_start", "isNumeral": false},
              {"displayName": "Production End", "variableName": "production_end", "isNumeral": false},
              {"displayName": "In Production?", "variableName": "in_production", "isNumeral": false},
            ]}
          />
          <StatComparisonCard
            id="performance"
            title="Performance"
            data={aircraftsData}
            rows={[
              {"displayName": `Range (NM)`, "variableName": `range_NM`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Range (${isImperialUnits ? "Miles" : "KM"})`, "variableName": `${isImperialUnits?"range_Miles":"range_km"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Fuel Burn (${isImperialUnits ? "GPH" : "LPH"})`, "variableName": `${isImperialUnits?"hourly_fuel_burn_GPH":"hourly_fuel_burn_LPH"}`, "isNumeral": true, positiveType: "low"},
              {"displayName": `Max Altitude (${isImperialUnits ? "Feet" : "Meters"})`, "variableName": `${isImperialUnits?"max_altitude_feet":"max_altitude_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Climb Rate (${isImperialUnits ? "Feet / Min" : "Meters / Min"})`, "variableName": `${isImperialUnits?"rate_climb":"rate_climb_meters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Initial Cruise Altitude (${isImperialUnits ? "Feet" : "Meters"})`, "variableName": `${isImperialUnits?"initial_cruise_altitude":"initial_cruise_altitude_meters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Take Off Distance (${isImperialUnits ? "Feet" : "Meters"})`, "variableName": `${isImperialUnits?"TO_distance_feet":"TO_distance_meters"}`, "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
              {"displayName": `Landing Distance (${isImperialUnits ? "Feet" : "Meters"})`, "variableName": `${isImperialUnits?"landing_distance_feet":"landing_distance_meters"}`, "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
              {
                "displayName": `High Cruise Speed:`,
                childDisplayNames:[
                  `Knots`,
                  `${isImperialUnits ? "MPH" : "KHM"}`,
                  `Mach`,
                ],
                childvariableNames:[
                  `high_cruise_knots`,
                  `${isImperialUnits ? "high_cruise_MPH" : "high_speed_cruise_kmh"}`,
                  `high_cruise_Mach`,
                ],
               "variableName": "", "isNumeral": false
              },
              {
                "displayName": `Long Range Cruise Speed:`,
                childDisplayNames:[
                  `Knots`,
                  `${isImperialUnits ? "MPH" : "KHM"}`,
                  `Mach`,
                ],
                childvariableNames:[
                  `long_range_cruise_knots`,
                  `${isImperialUnits ? "long_range_cruise_MPH" : "long_range_cruise_kmh"}`,
                  `long_range_cruise_Mach`,
                ],
               "variableName": "", "isNumeral": false
              },
            ]}
          />
          <OwnershipCost
            data={aircraftsData}
            conversionRate={conversionRate}
            currencySymbol={currencySymbol}
            region={country}
            estimatedHours={estimatedHours}
            setEstimatedHours={setEstimatedHours}
            hasLimitedAccess={hasLimitedAccess}
            readOnly={readOnly}
          />
          <AquisitionCost
            estimatedFutureValue={estimatedFutureValue}
            setestimatedFutureValue={setestimatedFutureValue}
            yearManufacture0={yearManufacture0}
            setYearManufacture0={setYearManufacture0}
            yearManufacture1={yearManufacture1}
            setYearManufacture1={setYearManufacture1}
            yearManufacture2={yearManufacture2}
            setYearManufacture2={setYearManufacture2}
            data={aircraftsData}
            currency={currency}
            country={country}
            unit={unit}
            hasLimitedAccess={hasLimitedAccess}
            readOnly={readOnly}
            currencySymbol={currencySymbol}
          />
          {/* <HistoricalMarket params={aircraftsData} /> */}
          <Range params={aircraftsData} address={rangeaddress} setAddress={setRangeaddress} latLng={rangelatLng} setLatLong={setRangeLatLong} />
          <StatComparisonCard
            title="Interior"
            data={aircraftsData}
            rows={[
              {"displayName": "Max Passengers", "variableName": "max_pax", "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": "Typical Passengers", "variableName": "typical_pax", "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": "Cabin Noise (dB)", "variableName": "cabin_noise", "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
              {"displayName": "Cabin Altitude (Feet)", "variableName": "cabin_altitude", "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
              {"displayName": "Sea Level Cabin (Feet)", "variableName": "sea_level_cabin", "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": "Pressure Differential (PSI)", "variableName": "pressure_differential_psi", "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": "Living Zone Count", "variableName": "living_zones", "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": "Cabin Volume (Cubic Feet)", "variableName": "cabin_volume_CF", "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
            ]}
          />
          <StatComparisonCard
            title="Features"
            data={aircraftsData}
            rows={[
              {"displayName": "Minimum Pilots", "variableName": "minimum_pilots", "isNumeral": true, isBlured: hasLimitedAccess},
              {"displayName": "Avionics Suite", "variableName": "typical_avionic_suite", "isNumeral": false, isBlured: hasLimitedAccess},
              {"displayName": "Toilet Available", "variableName": "toilet", "isNumeral": false, isBlured: hasLimitedAccess},
              {"displayName": "Flat Floor", "variableName": "flat_floor", "isNumeral": false, isBlured: hasLimitedAccess},
              {"displayName": "Inflight Baggage Access", "variableName": "baggage_access", "isNumeral": false, isBlured: hasLimitedAccess},
              {"displayName": "Shower Available", "variableName": "shower", "isNumeral": false, isBlured: hasLimitedAccess},
              {"displayName": "Space to Sleep", "variableName": "space_to_sleep", "isNumeral": false, isBlured: hasLimitedAccess},
              {"displayName": "Dedicated Bedroom", "variableName": "dedicated_bed", "isNumeral": false, isBlured: hasLimitedAccess},
            ]}
          />
          <StatComparisonCard
            title="Powerplant"
            data={aircraftsData}
            rows={[
              {"displayName": "Engine Make", "variableName": "engine_manufacturer", "isNumeral": false},
              {"displayName": "Engine Model", "variableName": "engine_model", "isNumeral": false},
              {"displayName": `Thrust per Engine ${weightType}`, "variableName": `${unit === "Imperial Units" ? "thrust_output_lbs" : "thrust_output_kgs"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Total Thrust Output ${weightType}`, "variableName": `${unit === "Imperial Units" ? "total_thrust_lbs" : "total_thrust_kgs"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": "Lateral Noise (dB)", "variableName": "lateral_db", "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
              {"displayName": "Flyover Noise (db)", "variableName": "flyover_db", "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
              {"displayName": "Approach Noise (dB)", "variableName": "approach_db", "isNumeral": true, positiveType: "low", isBlured: hasLimitedAccess},
            ]}
          />
          <StatComparisonCard
            title="Weights"
            data={aircraftsData}
            rows={[
              {"displayName": `Max Take-Off Weight ${weightType}`, "variableName": `${isImperialUnits ? "MTOW_lbs" : "MTOW_kgs"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Max Landing Weight ${weightType}`, "variableName": `${isImperialUnits ? "max_landing_weight_lbs" : "max_landing_weight_kgs"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Max Ramp Weight ${weightType}`, "variableName": `${isImperialUnits ? "max_ramp_weight_lbs" : "max_ramp_weight_kgs"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Available Fuel ${weightType}`, "variableName": `${isImperialUnits ? "available_fuel_lbs" : "available_fuel_kgs"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Max Payload ${weightType}`, "variableName": `${isImperialUnits ? "max_payload_lbs" : "max_payload_kgs"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Useful Payload ${weightType}`, "variableName": `${isImperialUnits ? "useful_load_lbs" : "useful_payloads_kgs"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Basic Operating Weight ${weightType}`, "variableName": `${isImperialUnits ? "basic_operating_weight_lbs" : "basic_operating_weight_kgs"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Baggage Weight ${weightType}`, "variableName": `${isImperialUnits ? "baggage_weight_lbs" : "baggage_weight_kgs"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Total Baggage ${weightInLength}`, "variableName": `${isImperialUnits ? "baggage_capacity_CF" : "baggage_capacity_cubicmeters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Internal Baggage ${weightInLength}`, "variableName": `${isImperialUnits ? "internal_baggage_CF" : "internal_baggage_cubicmeters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `External Baggage ${weightInLength}`, "variableName": `${isImperialUnits ? "external_baggage_CF" : "external_baggage_cubicmeters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
            ]}
          />
          <StatComparisonCard
            title="Dimensions"
            data={aircraftsData}
            rows={[
              {"displayName": `Exterior Length ${currentDimension}`, "variableName": `${isImperialUnits ? "ext_length_feet" : "ext_length_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Exterior Height ${currentDimension}`, "variableName": `${isImperialUnits ? "exterior_height_feet" : "ext_height_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Wingspan ${currentDimension}`, "variableName": `${isImperialUnits ? "wingspan_feet" : "wingspan_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Interior Length ${currentDimension}`, "variableName": `${isImperialUnits ? "int_length_feet" : "int_length_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Interior Height ${currentDimension}`, "variableName": `${isImperialUnits ? "int_height_feet" : "int_height_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Interior Width ${currentDimension}`, "variableName": `${isImperialUnits ? "int_width_feet" : "int_width_meters"}`, "isNumeral": true, positiveType: "high"},
              {"displayName": `Door Height ${currentDimension}`, "variableName": `${isImperialUnits ? "door_height_feet" : "door_height_meters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
              {"displayName": `Door Width ${currentDimension}`, "variableName": `${isImperialUnits ? "door_width_feet" : "door_width_meters"}`, "isNumeral": true, positiveType: "high", isBlured: hasLimitedAccess},
            ]}
          />
        </>
      }
    </>
  );
};

export default CompareAircrafts;
