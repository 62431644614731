import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import cropped_logo_blue from '../../assets/cropped-logo-blue.png'
import Footer from '../common/footer'

const OnePageLayout = ({ sectionIds = [], children }) => {
    const location = useLocation()

    useEffect(() => {
        const hash = location.hash
        if (hash) {
            const element = document.getElementById(hash.substring(1))
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [location])

    return (
    <div className='h-screen flex flex-col'>
      <header className='flex items-center md:items-end justify-between max-md:flex-col gap-6 px-4 md:px-8 py-4 bg-white w-full font-semibold mb-8'>
        <a
          href={'/search'}
          className='shrink-0 flex gap-4 items-end'
        >
          <img src={cropped_logo_blue} alt='logo' className={'h-13 brightness-0'} />
          <div className="flex gap-2 items-center max-md:hidden">
            <i className={`fa-solid fa-arrow-left`} />
            <p>Search</p>
          </div>
        </a>
        <div className='w-auto max-w-full flex gap-4 lg:gap-7 overflow-x-auto mx-auto'>
          {sectionIds.map(id => (
            <Link 
              key={id} 
              to={`${location.pathname}${location.search}#${id}`} 
              className={`uppercase whitespace-nowrap`}
            >
              {id.replace('_', ' ')}
            </Link>
          ))}
        </div>
        <div className='w-10 h-10 bg-gray-100 rounded-full hidden md:flex items-center justify-center nav-item cursor-pointer'>
          <a href='https://compareprivateplanes.com/my-account'>
            <i className='fa-solid fa-user'></i>
          </a>
        </div>
      </header>
      <div
        id='content-container'
        className='main-content overflow-y-scroll flex-grow'
      >
        <div className='flex flex-col py-8 px-4 md:px-8 gap-18 lg:gap-28 limit_cotent_width'>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default OnePageLayout
