import React from 'react'
import Table from './Table'

const StatComparisonCard = ({ title, data, rows, id }) => {
  return (
    <div id={id} className=''>
      <h3 className='heading-medium'>{title}</h3>
      <Table data={data} rows={rows} />
    </div>
  )
}
export default StatComparisonCard
