import React from 'react'
import { Pie } from 'react-chartjs-2'
import cn from 'classnames'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
const OwnershipCostsPieChart = ({title, totalValue, chartData, titleClassName="", totalValueClassName="", hasLimitedAccess = false})=>{
    return (
      <div className="grid grid-cols-[30%,1fr] gap-7">
        <ul className="h-full flex flex-col justify-center gap-4">
            {
              chartData.map(({label, color})=>(
                <li className={`flex gap-3 items-center`}>
                  <div className="size-3 rounded-full shrink-0" style={{backgroundColor: color}} />
                  <p className="text-left">{label}</p>
                </li>
              ))
            }
        </ul>
        <div className="relative flex overflow-hidden">
          <Pie
            className={cn("m-auto size-full max-h-[450px]", hasLimitedAccess && "blur-[5px]")}
            data={{
              datasets: [
                {
                  data: chartData.map(({value})=> hasLimitedAccess ? 1 : value),
                  backgroundColor: chartData.map(({color})=>color),
                },
              ],
            }}
            options={{
                responsive: true,
                cutout: "90%",
                spacing: 10,
                plugins: {
                  tooltip: !hasLimitedAccess,
                  legend: {
                      position: "bottom",
                  },
                },
            }}
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            <h5 className={`text-2xl lg:text-5xl ${hasLimitedAccess ? "blurData" : ""} ${totalValueClassName}`}>
                {hasLimitedAccess ? "Null" : totalValue}
            </h5>
            <p className={`text-sm lg:text-xl ${titleClassName}`}>{title}</p>
        </div>
        </div>
      </div>
    )
  }

export default OwnershipCostsPieChart