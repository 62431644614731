import React, {useState, useEffect} from 'react';
import apiService from '../../services/api';
import PaginatedTable from '../shared/paginatedTable';
import YearSelector from '../common/YearSelector';
import { TABLE_BLURED_VALUE } from '../../utils/constants/app-constants';

const AirportSimpleTableCard = ({ title, apiRoute, icaoCode='',maxHeight, additionalApiFilters, hasLimitedAccess=false }) => {
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(2024);

  useEffect(() => {
    async function fetchData() {
      setFetched(false)
      setLoading(true)
      const response = await apiService[apiRoute](`icaoCode=${icaoCode}&year=${year}&${additionalApiFilters || ''}`);
      if (hasLimitedAccess) {
        const tranformedData = response.data.map((obj,index)=>{
          const updatedData = Object.fromEntries(Object.entries(obj).map(([key,value])=>(
            [key, index > 2 ? TABLE_BLURED_VALUE : value]
          )))
          return updatedData;
        })
        setData(tranformedData);
      } else{
        setData(response.data);
      }
      setFetched(true);
      setLoading(false)
    }
    fetchData();
  }, [icaoCode,year, apiRoute, additionalApiFilters]);
  return(
  <div className="card map-container overflow-y-scroll flex flex-col gap-2 max-h-[364px] lg:max-h-[364px]"
  style={{maxHeight:maxHeight?maxHeight:''}}>
    <div className="flex flex-col md:flex-row md:justify-center gap-4 mb-8">
      <h3 className="text-3xl mb-4">{title}</h3>
      <YearSelector year={year} setYear={setYear} disabled={hasLimitedAccess} />
    </div>
    {fetched && data.length === 0
      ? <div className="py-12 flex flex-col justify-center items-center"> 
          <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
          <h3 className="text-xl mt-6">No Data Available.</h3>
        </div>
      : loading
          ? <div className="py-12 flex flex-col justify-center items-center">
              <i className="fa fa-spinner fa-spin text-6xl"></i>
              <h3 className="text-3xl mt-6">Loading Data...</h3>
            </div>
          : <PaginatedTable data={data} />
    }
  </div>)
};

export default AirportSimpleTableCard;

