import cn from "classnames";
import styles from "./styles/styles.module.scss";
import { useState, useEffect } from "react";
import numeral from "numeral";
import OwnershipCostsPieChart from "../common/OwnershipCostsPieChart";
import CardHeading from "./CardHeading";
import { CiCalculator2 } from "react-icons/ci";
import { AiOutlineCalculator } from "react-icons/ai";
import { RiLoopRightLine } from "react-icons/ri";


const CostsBreakDown = ({title, headIcon,rows, heighestCost, readOnly=false, currencySymbol, hasLimitedAccess, isAiSearchAircraftPage,aircraftCostValues,setAircraftCostValues, resetValues})=>{
  const percentageOfTotalCost = (value, highestValue) => {
    return ((value / highestValue) * 100).toFixed(0)
  }
  return (
    <div className="space-y-10">
      <div className="flex">
        <CardHeading title={title} subTitle={'Breakdown'} icon={headIcon} />
        <button className={cn("re_interact_button whitespace-nowrap h-fit my-auto")} disabled={readOnly} onClick={() =>!readOnly && resetValues()}>
          Reset Values
          <RiLoopRightLine size={20} />
        </button>
      </div>

      <table className="table-auto w-full overflow-x-scroll">
        <tbody>
          {
            rows.map(({displayName,color,variable},i)=>(
              <tr>
                <td className="md:whitespace-nowrap  px-2 pb-2"><p>{displayName}</p></td>
                <td className="whitespace-nowrap px-2 xl:pl-12 pb-2"
                    style={isAiSearchAircraftPage?{paddingLeft:"1rem"}:{}}>
                  <span className="mr-2">{currencySymbol}</span>
                  <span className={`${hasLimitedAccess ? "blurData" : ""}`}>
                    <input
                      className={`${styles.cost_input}`}
                      style={isAiSearchAircraftPage?{fontSize:"12px",padding:"8px",maxWidth:'80px'}:{}}
                      type="text"
                      name={variable}
                      placeholder={displayName}
                      value={hasLimitedAccess ? "Null" : numeral(aircraftCostValues?.[variable]).format("0,0")}
                      onInput={(e)=>!readOnly && ( !hasLimitedAccess && handleInputChange(e,setAircraftCostValues))}
                      disabled={readOnly || hasLimitedAccess}
                    />
                  </span>
                </td>
                <td className="w-full px-2 pb-2">{barComponent(color, percentageOfTotalCost(aircraftCostValues?.[variable],heighestCost))}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
  </div>
  )
}

const barComponent = (colour, value) => {
  return (
  <div className="rounded-2xl overflow-hidden bg-[#e6e4e5] h-2 w-full">
    <div className="h-full" style={{width: `${value}%`, background: colour}}></div>
  </div>)
}

const handleInputChange = (event,setAircraftCostValues) => {
  const { name, value } = event.target;
  let newValue = parseFloat(value.replace(",", ""));
  console.log(newValue);
  setAircraftCostValues(prevValues => ({
    ...prevValues,
    [name]: newValue
  }));
};

const OwnershipCosts = ({aircraftCostValues, setAircraftCostValues, nbHours,setNbHours, isAiSearchAircraftPage = false, params, region, conversionRate, currencySymbol, hasLimitedAccess=false, readOnly=false }) => {
  const [totalFixedCost, setTotalFixedCost] = useState(0);
  const [highestFixedCost, setHighestFixedCost] = useState(0);
  const [totalVariableCost, setTotalVariableCost] = useState(0);
  const [highestVariableCost, setHighestVariableCost] = useState(0);

  function regionInitials(region) {
    const regionMap = {
      "North America": "NA",
      "South America": "SA",
      "Europe": "EU",
      "Asia": "AS"
    };
    
    return regionMap[region]
  }

  const getConvertedValueForParameter = (parameter) => {
    return params[`${regionInitials(region)}_${parameter}`] * conversionRate
  }

  const resetValues = () => {
    console.log("resetting values");

    setAircraftCostValues({
      crewSalary: (getConvertedValueForParameter("annual_employee_benefits") + getConvertedValueForParameter("annual_captain") + getConvertedValueForParameter("annual_first_office")),
      crewTraining: getConvertedValueForParameter("annual_crew_training"),
      hangar: getConvertedValueForParameter("annual_hangar"),
      insuranceHull: getConvertedValueForParameter("annual_insurance_hull"),
      insuranceLiability: getConvertedValueForParameter("annual_insurance_liability"),
      management: getConvertedValueForParameter("annual_management"),
      miscFixed: getConvertedValueForParameter("annual_misc"),
      fuelCost: getConvertedValueForParameter("hourly_fuel"),
      maintenance: getConvertedValueForParameter("hourly_maintenance"),
      engOverhaul: getConvertedValueForParameter("hourly_engine_overhaul"),
      groundFees: getConvertedValueForParameter("hourly_ground_fees"),
      miscVar: getConvertedValueForParameter("hourly_misc"),
      annualTotal: getConvertedValueForParameter("annual_total"),
    });
  }

  useEffect(() => {
    if (!params || !region || Object.keys(aircraftCostValues).length  ) return;
    resetValues()
  }, [params, region]);
  useEffect(() => {
    if (!isAiSearchAircraftPage) return;
    setNbHours(Math.round(params.annual_hours) || 0)
  }, [params]);
  useEffect(() => {
    if (Object.keys(aircraftCostValues).length === 0) return;

    let fixedCosts = [aircraftCostValues.crewSalary, aircraftCostValues.crewTraining, aircraftCostValues.hangar, aircraftCostValues.insuranceHull, aircraftCostValues.insuranceLiability, aircraftCostValues.management, aircraftCostValues.miscFixed]

    let variableCosts = [aircraftCostValues.fuelCost, aircraftCostValues.maintenance, aircraftCostValues.engOverhaul, aircraftCostValues.groundFees, aircraftCostValues.miscVar]
    console.log(fixedCosts,variableCosts)
    setTotalFixedCost(fixedCosts.reduce((a, b) => a + b, 0))
    setHighestFixedCost(Math.max(...fixedCosts))

    setTotalVariableCost(variableCosts.reduce((a, b) => a + b, 0))
    setHighestVariableCost(Math.max(...variableCosts))
  }, [aircraftCostValues]);
  
  const updateFlightHours = (event) => {
    // Only update the state if the input is a number
    if (isNaN(event.target.value)) return;
    console.log(event.target.value)
    setNbHours(event.target.value);
  }

  const fixedCostsData = [
    {
      label: 'Crew Salary',
      color: 'rgba(255, 99, 132, 1)',
      value: aircraftCostValues.crewSalary,
    },
    {
      label: 'Crew Training',
      color: 'rgba(54, 162, 235, 1)',
      value: aircraftCostValues.crewTraining,
    },
    {
      label: 'Hangar',
      color: 'rgba(255, 206, 86, 1)',
      value: aircraftCostValues.hangar,
    },
    {
      label: 'Insurance',
      color: 'rgba(75, 192, 192, 1)',
      value: aircraftCostValues.insuranceHull + aircraftCostValues.insuranceLiability,
    },
    {
      label: 'Management',
      color: 'rgba(153, 102, 255, 1)',
      value: aircraftCostValues.management,
    },
    {
      label: 'Miscellaneous Fixed',
      color: 'rgba(255, 159, 64, 1)',
      value: aircraftCostValues.miscFixed,
    },
  ]
  const variableCostsData = [
    {
      label: 'Fuel Cost',
      color: 'rgba(255, 99, 132, 1)',
      value: aircraftCostValues.fuelCost,
    },
    {
      label: 'Maintenance',
      color: 'rgba(54, 162, 235, 1)',
      value: aircraftCostValues.maintenance,
    },
    {
      label: 'Engine Overhaul',
      color: 'rgba(255, 206, 86, 1)',
      value: aircraftCostValues.engOverhaul,
    },
    {
      label: 'Ground Fees',
      color: 'rgba(75, 192, 192, 1)',
      value: aircraftCostValues.groundFees,
    },
    {
      label: 'Miscellaneous Variable',
      color: 'rgba(153, 102, 255, 1)',
      value: aircraftCostValues.miscVar,
    },
  ]
  
  return (
  <>
    {Object.keys(aircraftCostValues).length > 0 &&
    <>
      <div id='ownership_costs' className={`grid gap-6 grid-cols-1 md:gap-8 ${isAiSearchAircraftPage?'!flex flex-col !shadow-none !bg-transparent':''}`}>
        {!isAiSearchAircraftPage?(<div className="">
          <h3 className="heading-medium">Ownership Costs</h3>
          <div className="flex justify-center items-center my-12">
            <table>
              <tbody>
                <tr>
                  <td className="pr-4 text-right font-bold">Annual Operating Costs</td>
                  <td className={`pl-1 annual-cost-custom-out own-cost-output ${hasLimitedAccess ? "blurData" : ""}`}>{hasLimitedAccess ? "Null" : `${currencySymbol}${numeral(totalFixedCost + (nbHours * (totalVariableCost))).format("0,0")}`}</td>
                </tr>
                <tr>
                  <td className="pr-4 text-right py-6 font-bold">Annual Flight Hours</td>
                  <td className="py-6">
                    <input
                      className={styles.cost_input}
                      type="text"
                      name="nbHours"
                      placeholder="Enter hours"
                      value={nbHours}
                      onChange={(e) => !readOnly && updateFlightHours(e)}
                      disabled={readOnly}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="grid lg:grid-cols-2 gap-15 mt-4 items-center text-center">
            <OwnershipCostsPieChart
              title={'Fixed Costs'}
              totalValue={`${currencySymbol}${numeral(totalFixedCost).format("0,0")}`}
              chartData={fixedCostsData}
              hasLimitedAccess={hasLimitedAccess}
            />
            <OwnershipCostsPieChart
              title={'Variable Costs'}
              totalValue={`${currencySymbol}${numeral(totalVariableCost).format("0,0")}`}
              chartData={variableCostsData}
              hasLimitedAccess={hasLimitedAccess}
            />
          </div>
        </div>):(
          <div className="flex flex-col gap-8 ">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="flex justify-between">
                <p className="font-bold">Total Costs</p>
                <p className="acq-output-val">{currencySymbol}{numeral(
                    totalFixedCost + (nbHours * (totalVariableCost))
                    ).format("0,0")}</p>            
              </div>
    
              <div className="flex justify-between">
                <p className="font-bold">Annual Hours</p>
                <input
                  className={styles.cost_input}
                  type="text"
                  name="nbHours"
                  placeholder="Enter hours"
                  value={nbHours}
                  onChange={(e) => updateFlightHours(e)}
                />         
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="flex justify-between">
                <p className="font-bold">Fixed Costs</p>
                <p className="acq-output-val">{currencySymbol}{numeral(totalFixedCost).format("0,0")}</p>            
              </div>
    
              <div className="flex justify-between">
                <p className="font-bold">Variable Costs</p>
                <p className="acq-output-val">{currencySymbol}{numeral(totalVariableCost).format("0,0")}</p>            
              </div>
            </div>
          </div>
        )}
        <div className={`grid grid-cols-1 ${isAiSearchAircraftPage ? "":"lg:grid-cols-2"} gap-15 mt-4`}>
            <CostsBreakDown
              title={'Fixed Costs'}
              headIcon={AiOutlineCalculator}
              heighestCost={highestFixedCost}
              readOnly={readOnly}
              currencySymbol={currencySymbol}
              hasLimitedAccess={hasLimitedAccess}
              isAiSearchAircraftPage={isAiSearchAircraftPage}
              aircraftCostValues={aircraftCostValues}
              setAircraftCostValues={setAircraftCostValues}
              resetValues={resetValues}
              rows={[
                {
                  displayName: "Crew Salary",
                  variable: "crewSalary",
                  color: "#FF577A",
                },
                {
                  displayName: "Crew Training",
                  variable: "crewTraining",
                  color: "#2999E6",
                },
                {
                  displayName: "Hangar",
                  variable: "hangar",
                  color: "#FFC651",
                },
                {
                  displayName: "Insurance Hull",
                  variable: "insuranceHull",
                  color: "#3AB8B8",
                },
                {
                  displayName: "Insurance Liability",
                  variable: "insuranceLiability",
                  color: "#3AB8B8",
                },
                {
                  displayName: "Management",
                  variable: "management",
                  color: "#925DFD",
                },
                {
                  displayName: "Miscellaneous Fixed",
                  variable: "miscFixed",
                  color: "#FF943D",
                },
              ]} 
            />
            <CostsBreakDown
              title={'Variable Costs'}
              headIcon={CiCalculator2}
              heighestCost={highestVariableCost}
              readOnly={readOnly}
              currencySymbol={currencySymbol}
              hasLimitedAccess={hasLimitedAccess}
              isAiSearchAircraftPage={isAiSearchAircraftPage}
              aircraftCostValues={aircraftCostValues}
              setAircraftCostValues={setAircraftCostValues}
              resetValues={resetValues}
              rows={[
                {
                  displayName: "Fuel Cost",
                  variable: "fuelCost",
                  color: "#FF577A",
                },
                {
                  displayName: "Maintenance",
                  variable: "maintenance",
                  color: "#2999E6",
                },
                {
                  displayName: "Engine Overhaul",
                  variable: "engOverhaul",
                  color: "#FFC651",
                },
                {
                  displayName: "Ground Fees",
                  variable: "groundFees",
                  color: "#3AB8B8",
                },
                {
                  displayName: "Miscellaneous Variable",
                  variable: "miscVar",
                  color: "#925DFD",
                },
              ]} 
            />
        </div>
      </div>
    </>
    }
  </>
  );
};

export default OwnershipCosts;