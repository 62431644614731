import cn from "classnames";
import styles from "./styles/styles.module.scss";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import numeral from "numeral";

ChartJS.register(ArcElement, Tooltip, Legend);

const StatDonuts = ({ params, currencySymbol, conversionRate, regionPrefix, unit }) => {

  const chartData = (label,maxValues=[])=>{
    return {
      datasets: [
        {
          label,
          data: maxValues,
          backgroundColor: ["#565656", "#B4B4B4"],
          spacing: 0,
        },
      ],
    }
  }
  const data_passengers = chartData("Pax",[params.max_pax, 19])
  const data_range = chartData("Range",[params.range_NM, 8000]) 
  const data_cruise = chartData("Cruise Speed",[params.high_cruise_knots, 516])
  const data_cost = chartData("Cost Per Hour",[params.NA_hourly_total, 10000])
  const data_fuel = chartData("Hourly Fuel Burn",[params.hourly_fuel_burn_GPH, 626])

  const chartOptions = {  
    responsive: true,
    cutout: '90%',
    plugins: { tooltip: { enabled: false } },
  };

  const CustomDonghut = ({data,label,description,value})=>{
    return (
      <div className={cn(styles.chart_container)}>
        <div className="flex flex-col items-end uppercase">
          <h6 className="text-cetaceanBlue text-sm font-semibold whitespace-nowrap">{label}</h6>
          <p className="text-xs text-[#B4B4B4] font-medium">{description}</p>
        </div>
        <div className={styles.donghnutContainer}>
          <Doughnut
            data={data}
            options={chartOptions}
          />
          <span className={styles.chart_value}>{value}</span>
        </div>
      </div>
    )
  }
  const isImperialUnit = unit === "Imperial Units";
  const numeralFormatter = (value)=>numeral(value).format("0,0");
  const costPerHour = params[`${regionPrefix}_hourly_total`] !== 0 ? numeralFormatter(params[`${regionPrefix}_hourly_total`]) : "-";
  return (
    <div className={cn(styles.doughnut_charts_wrapper)}>
      <CustomDonghut label={'Passengers'} description={'pax'} data={data_passengers} value={params.max_pax} />
      <CustomDonghut label={'Range'} description={isImperialUnit ? "NM" : "KM"} data={data_range} value={isImperialUnit
            ? numeralFormatter(params.range_NM)
            : numeralFormatter(params.range_km)} />
      <CustomDonghut label={'Cruise Speed'} description={isImperialUnit ? "Knots" : "Kmh"} data={data_cruise} value={isImperialUnit
            ? numeralFormatter(params.high_cruise_knots)
            : numeralFormatter(params.high_speed_cruise_kmh)} />
      <CustomDonghut label={'Cost per Hour'} description={currencySymbol} data={data_cost} value={costPerHour} />
      <CustomDonghut label={'Fuel Burn'} description={isImperialUnit ? "GPH" : "LPH"} data={data_fuel} value={isImperialUnit
            ? numeralFormatter(params.hourly_fuel_burn_GPH)
            : numeralFormatter(params.hourly_fuel_burn_LPH)} />
    </div>
  );
};

export default StatDonuts;
