import { useContext, useEffect, useState } from 'react'
import axios, { isAxiosError } from 'axios'
import { API_URL } from '../../utils/constants/app-constants'
import { toast } from 'react-toastify'
import AppContext from '../../custom-context/appContext'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { completeSharedLink, handleCopyOnClipboard } from '../../utils/functions'

const SharedLinks = () => {
  const { loggedInUsername } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)
  const [sharedLinks, setSharedLinks] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const totalPages = Math.ceil(sharedLinks.length / itemsPerPage)
  const paginatedData = sharedLinks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )
  const fetchSharedLinks = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${API_URL}get-shared-links/?username=${loggedInUsername}`
      )
      if (res.status == '200') {
        setSharedLinks(res.data.data)
      } else {
        toast.error(res.data.error)
      }
    } catch (error) {
      let message = 'Something went wrong!'
      if (isAxiosError(error)) {
        message = error.response.data.error
      }
      toast.error(message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteLink = async linkId => {
    const loadingId = toast.loading('Deleting...')
    const toastSetting = {
      isLoading: false,
      autoClose: 2000
    }
    try {
      const res = await axios.delete(`${API_URL}delete-shared-link/${linkId}/`)
      if (res.status == '200') {
        toast.update(loadingId, {
          type: 'success',
          render: 'Deleted!',
          ...toastSetting
        })
        fetchSharedLinks()
      } else {
        toast.update(loadingId, {
          type: 'error',
          render: res.data?.error,
          ...toastSetting
        })
      }
    } catch (error) {
      let message = 'Something went wrong!'
      if (isAxiosError(error)) {
        message = error.response.data.error
      }
      toast.update(loadingId, {
        type: 'error',
        render: message,
        ...toastSetting
      })
    }
  }

  useEffect(() => {
    fetchSharedLinks()
  }, [])

  const TableTH = ({ children, className = '' }) => {
    return (
      <th
        className={`p-4 text-base md:text-lg font-semibold capitalize ${className}`}
      >
        {children}
      </th>
    )
  }
  const TableTD = ({ children }) => {
    return <th className='p-4 text-sm md:text-base font-medium'>{children}</th>
  }
  const renderPageNumbers = () => {
    let pages = []

    // Logic to determine which buttons to show
    if (totalPages <= 5) {
      // If there are 5 or fewer pages, show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i)
      }
    } else {
      // More complex logic for when there are more than 5 pages
      if (currentPage <= 3) {
        // Current page is 3 or less, show first 4 and last page
        pages = [1, 2, 3, 4, totalPages]
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        // Between page 4 and the third-to-last page
        pages = [1, currentPage - 1, currentPage, currentPage + 1, totalPages]
      } else {
        // Last three pages
        pages = [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
      }
    }
    // Render buttons based on pages array
    return pages.map(page => (
      <button
        key={page}
        className={`${
          currentPage === page ? `text-[#505050]` : `hover:text-[#505050]`
        } px-2 py-1 text-[#BFBFBF]`}
        onClick={() => setCurrentPage(page)}
      >
        {page}
      </button>
    ))
  }
  const PageChangeButton = ({ children, disabled, onClick }) => {
    return (
      <button
        className={`${
          disabled ? 'text-lightSilver' : 'text-black'
        } size-10 border-2 border-lightSilver rounded-full py-1 px-2`}
        onClick={() => !disabled && onClick()}
      >
        {children}
      </button>
    )
  }
  return (
    <div className='min-h-svh'>
      {isLoading ? (
        <div className='h-screen flex flex-col justify-center items-center'>
          <i className='fa fa-spinner fa-spin text-6xl'></i>
          <h3 className='text-3xl mt-6'>Loading Data...</h3>
        </div>
      ) : (
        <div className='w-full'>
          <div className='overflow-x-scroll max-h-[500px]'>
            <table className='w-full'>
              <thead className='sticky top-0 z-50'>
                <tr className='bg-cultured'>
                  <TableTH className='rounded-l-full'>Customer Name</TableTH>
                  <TableTH>Aircraft Selected</TableTH>
                  <TableTH>Date Created</TableTH>
                  <TableTH>Expire Date</TableTH>
                  <TableTH>Link</TableTH>
                  <TableTH>Password</TableTH>
                  <TableTH>View Count</TableTH>
                  <TableTH className='rounded-r-full'>Delete</TableTH>
                </tr>
              </thead>
              <tbody className='divide-y divide-platinum'>
                {paginatedData.map((row, index) => (
                  <tr key={index}>
                    <TableTD>{row.customer_name}</TableTD>
                    <TableTD>{row.aircraft_selected}</TableTD>
                    <TableTD>{format(row.created_at, 'MMM do, yyyy')}</TableTD>
                    <TableTD>
                      {format(row.expiration_date, 'MMM do, yyyy')}
                    </TableTD>
                    <TableTD>
                      <input
                        type='text'
                        readOnly
                        value={completeSharedLink(row._id)}
                        className='w-[min(100%,300px)] border border-gray-300 rounded-lg py-2 px-4 text-center cursor-pointer'
                        onClick={() =>
                          handleCopyOnClipboard(completeSharedLink(row._id),'Link Copied!')
                        }
                      />
                    </TableTD>
                    <TableTD>{row.password}</TableTD>
                    <TableTD>{row.views}
                      {/*<Link
                        to={`/${row.page_type}${row.search_params}`}
                        className='px-8 py-2 !bg-[#292929] !text-white text-sm rounded-full'
                      >
                        View
                      </Link>*/}
                    </TableTD>
                    <TableTD>
                      <button
                        onClick={() => handleDeleteLink(row._id)}
                        className='px-8 py-2 !bg-[#292929] !text-white text-sm rounded-full'
                      >
                        X
                      </button>
                    </TableTD>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='w-full flex flex-col md:flex-row gap-4 md:items-center mt-10'>
            {totalPages > 1 && (
              <div className='flex items-center gap-2'>
                <PageChangeButton
                  disabled={currentPage == 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <i className='fa-solid fa-angle-left' />
                </PageChangeButton>
                {renderPageNumbers()}
                <PageChangeButton
                  disabled={currentPage == totalPages}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <i className='fa-solid fa-angle-right' />
                </PageChangeButton>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default SharedLinks
