import React, {useState, useEffect} from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import numeral from "numeral";
import { FaRegCompass } from "react-icons/fa";
import CardHeading from "./CardHeading";
import FleetActvityInfo from "./FleetActvityInfo";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const transformData = (data) => {
  let transformedData = months.map((monthName, index) => {
    let monthData = { name: monthName };
    for (const year in data) {
      const num = data[year][index + 1]
      if (num === undefined) continue;
      monthData[year] = data[year][index + 1] || 0;
    }
    return monthData;
  });
  return transformedData;
};

const FleetHoursCard = ({preData, regNumbers, toggleShowAllAircraft, hasLimitedAccess = false}) => {
  const [rawData, setRawData] = useState({});
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);
  {/*const [buttonText, setButtonText] = useState("View All Aircraft");
  const toggleButtonText = () => {
    buttonText === "View All Aircraft" ? setButtonText("Hide All Aircraft") : setButtonText("View All Aircraft");
    toggleShowAllAircraft();
  }*/}

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      if (Object.keys(preData).length === 0) {
        setFetching(false);
        return;
      }
      setRawData(preData);
      setData(transformData(preData));
      setFetching(false);
    }
    fetchData();
  }, [preData]);

  return (
  <div id="flight_activty" className={`h-full`}>
    <div className="flex flex-col md:flex-row gap-4 md:items-center md:justify-between mb-15">
      <h3 className="heading-medium">Flight Activity</h3>

      {/* <button onClick={() => toggleButtonText()} className="interact_button">{buttonText}</button> */}
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-[1fr,33%] gap-6">
      <div>
        {fetching && 
          <div className="py-24 flex flex-col justify-center items-center">
            <i className="fa fa-spinner fa-spin text-6xl"></i>
            <h3 className="text-3xl mt-6">Loading Data...</h3>
          </div>
        }

        {!fetching && (
          Object.keys(rawData).length === 0
          ? <div className="py-24 flex flex-col justify-center items-center">
              <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
              <h3 className="text-xl mt-6">No Data Available.</h3>
            </div>
          : <>
              <ResponsiveContainer width="100%" height="100%" className={"!h-48 sm:!h-[500px] lg:!h-[700px]"}>
                  <LineChart
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="name" tick={{ fontSize: '10px' }} className={hasLimitedAccess && "blur-[5px]"} />
                    <YAxis label={{ value: "Flight Hours", angle: -90, position: 'insideLeft', offset: -5, style: { textAnchor: 'middle' } }} tickFormatter={(value) => `${value > 9999 ? `${value / 1000}k` : value}`} className={hasLimitedAccess && "blur-[5px]"} />
                    <Tooltip formatter={(value) => hasLimitedAccess ? "Null" : numeral(value).format("0,0")}  wrapperClassName={hasLimitedAccess && "blurData"} />
                    <Legend />
                    <Line type="monotone" dataKey="2019" stroke="#255A92" activeDot={{ r: 8 }} dot={false} strokeWidth={3} />
                    <Line type="monotone" dataKey="2020" stroke="#6DB0A9" dot={false} strokeWidth={3} />
                    <Line type="monotone" dataKey="2021" stroke="#837DE6" dot={false} strokeWidth={3} />
                    <Line type="monotone" dataKey="2022" stroke="#CE8188" dot={false} strokeWidth={3} />
                    <Line type="monotone" dataKey="2023" stroke="#91A78B" dot={false} strokeWidth={3} />
                    <Line type="monotone" dataKey="2024" stroke="#D3B26D" dot={false} strokeWidth={3} />
                  </LineChart>
                </ResponsiveContainer>
            </>
        )}
      </div>
      <FleetActvityInfo regNumbers={regNumbers} />
    </div>

  </div>
  )
};
  
export default FleetHoursCard;