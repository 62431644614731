import SingleAircraftDetails from '../SingleAircraft/SingleAircraftDetails'

const Subscription = () => {
  return (
    <SingleAircraftDetails
      aircraftID={781}
      allowComparison={false}
      isSampleAircraft
      showSimilarAircrafts={false}
      byPassAuth
    />
  )
}

export default Subscription
