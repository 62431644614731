import React, { useState, useEffect, useContext, } from "react";
import { useSearchParams } from "react-router-dom";
import apiService from "../../services/api";
import AirportSimpleTableCard from "../../components/airportSearch/airportSimpleCards";
import BasicRowsCard from "../../components/shared/basicRowsCard";
// import TopRoutes from "../../components/shared/topRoutes";
import FlightHistoryCard from "../../components/dashboard/flightHistory";
import ActivityByTypeCard from "../../components/dashboard/activityByType";
// import TopLocations from "../../components/dashboard/topLocations";
import AirportActivityHistory from "../../components/shared/AirportActivityHistory";
import change_arrow_up from "../../assets/change_arrow_up.png";
import change_arrow_down from "../../assets/change_arrow_down.png";
import numeral from "numeral";
import AppContext from "../../custom-context/appContext";


const CustomBasicRowCard = ({icaoCode,hasLimitedAccess=false})=>{
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(false);
  useEffect(()=>{
    async function fetchData(){
      setData({})
      setFetching(true);
      const res = await apiService.getAirportDetails(icaoCode);
      setData(res.data)
      setFetching(false)
    }
    fetchData()
  },[icaoCode])
if(Object.keys(data).length === 0){
  return (
    <div className="card p-6">
      {fetching ? (
            <div className="w-full py-12 flex flex-col justify-center items-center">
              <i className="fa fa-spinner fa-spin text-6xl"></i>
              <h3 className="text-xl mt-6 text-center">Fetching airport details...</h3>
            </div>
        ):(
          <div className="w-full py-12 flex flex-col justify-center items-center"> 
            <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
            <h3 className="text-xl mt-6">Invalid icao code.</h3>
          </div>
      )}
  </div>
  )
}
return (
  <BasicRowsCard 
    rows={[
      {
        title: "Top Operator",
        content: data.topOperator,
        blur: hasLimitedAccess
      },
      {
        title: "Top Aircraft",
        content: data.topAircraft,
        blur: hasLimitedAccess
      },
      {
        title: "Avg Monthly Flights",
        content: Intl.NumberFormat('en-US').format(data.avgMonthyFlights),
        blur: hasLimitedAccess
      },
      {
        title: "Change YoY",
        content:  (
          !data.changeYoy ? "N/A" : (
          <>
            <div className={`${data.changeYoy >= 0 ? "positiveChange" : "negativeChange"} change`}>
              {numeral(data.changeYoy).format("0,0.0")}%
              {data.changeYoy !== 0 && <img src={data.changeYoy < 0 ? change_arrow_down : change_arrow_up} alt="" />}
            </div>
          </>)
        ),
        blur: hasLimitedAccess
      },
    ]}
  />
)
}

export default function LookupAirport() {
  const { userLevel } = useContext(AppContext);
  const hasLimitedAccess = ["jet_finder"].includes(userLevel);
  const [searchParams] = useSearchParams();
  const icaoParam = searchParams.get("icao")
  const [icaoCode,setIcaoCode] = useState(icaoParam? icaoParam.toUpperCase() : '');
  const [inputValue, setInputValue] = useState("");
  const [airportData,setAirportData] = useState({})
  const [foundMatch, setFoundMatch] = useState(false);
  const [hasDoneLookup, setHasDoneLookup] = useState(false);
  const [lookupAirportInProgress, setLookupAirportInProgress] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionInProgress, setsuggestionInProgress] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hasDoneSuggestion, setHasDoneSuggestion] = useState(false);

  async function searchSuggestions() {
    try{
      setSuggestions([])
      setHasDoneSuggestion(false);
      setsuggestionInProgress(true)
      const res = await apiService.getAirportSuggestions(inputValue);
      console.log(res)
      setSuggestions(res.data)
    }catch(err){
      console.log(err)
    } finally{
      setHasDoneSuggestion(true);
      setsuggestionInProgress(false)
    }
  }


  useEffect(() => {
    if(inputValue.length && icaoCode !== inputValue){
      const delayDebounceFn = setTimeout(() => {
        setShowSuggestions(true)
        searchSuggestions()
      }, 700)
      return ()=>clearTimeout(delayDebounceFn)
    } else{
      setShowSuggestions(false)
      setSuggestions([])
    }
  }, [inputValue]);
  
  useEffect(()=>{
    // functionality to get the data of airport and related by icao Code
    if(icaoCode) setInputValue(icaoCode.toUpperCase())
    async function fetchData() {
      setAirportData({});
      if (icaoCode.length !== 4) {
        setLookupAirportInProgress(false)
        setHasDoneLookup(false);
        setFoundMatch(false);
        return;
      }
      setHasDoneLookup(true);
      setLookupAirportInProgress(true);
      const response = await apiService.lookupAirport(icaoCode);
      console.log(response)
      if (response.message === "Invalid icao code") {
        setLookupAirportInProgress(false)
        setFoundMatch(false);
        return;
      }else{
        setAirportData(response)
        setFoundMatch(true);
      }
      setLookupAirportInProgress(false);
    }
    fetchData();
  },[icaoCode])
  const handleSuggestionClick = (icao)=>{
    setInputValue(icaoCode.toUpperCase())
    setFoundMatch(false)
    setShowSuggestions(false)
    setSuggestions([])
    setHasDoneSuggestion(false)
    setIcaoCode(icao);
  }
  
  return (
  <div className="w-100 py-8 px-4 md:px-8 flex flex-col md:gap-6 gap-8">
    <div className="flex flex-col items-center py-12 gap-6">
      <h2 className="text-center text-3xl md:text-4xl font-bold">Search for an Airport</h2>
      <div className="relative">
        <input 
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value.toUpperCase().replace("/", ""))} 
          onFocus={()=>setShowSuggestions(true)}
          onBlur={()=>setShowSuggestions(false)}
          className="aircraft_lookup_input" 
          type="text" 
          placeholder="Airport Name or ICAO Code" 
        />
        {
          !(showSuggestions || suggestions.length) ? ''
          : (
            <div className="absolute top-[calc(100%+20px)] z-50 card w-full !p-0">
              {suggestionInProgress && (
                <div className="w-full py-12 flex flex-col justify-center items-center"> 
                <i className="fa fa-spinner fa-spin text-6xl"></i>
                  <h3 className="text-xl mt-6">Fetching airports...</h3>
                </div>
              )}
              {suggestions.length === 0 && !suggestionInProgress && hasDoneSuggestion && (
                <div className="w-full py-12 flex flex-col justify-center items-center"> 
                  <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
                  <h3 className="text-xl mt-6">No related Airport Found.</h3>
                </div>
              )}
              {suggestions.length > 0 && (
                <ul className="py-5">
                  {suggestions.map((suggestion,i) => (
                    <li key={i} className="hover:bg-gray-200 cursor-pointer py-1 my-1 flex gap-2 items-center"
                    onClick={()=>handleSuggestionClick(suggestion.icao)}
                    >
                      <p className="pl-4 font-bold text-lg lg:text-xl line-clamp-1">{suggestion.airportName}</p>
                      <p>({suggestion.icao})</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )
        }
      </div>
    </div>

    {lookupAirportInProgress && (
        <div className="w-full py-12 flex flex-col justify-center items-center">
          <i className="fa fa-spinner fa-spin text-6xl"></i>
          <h3 className="text-xl mt-6">Fetching airport data...</h3>
        </div>
    )}

    {!foundMatch && !lookupAirportInProgress && hasDoneLookup && (
      <div className="w-full py-12 flex flex-col justify-center items-center"> 
        <i className="fa fa-solid fa-plane-circle-exclamation text-4xl"></i>
        <h3 className="text-xl mt-6">Invalid icao code.</h3>
      </div>
    )}

    {foundMatch && !lookupAirportInProgress && (
    <>
      <div className="grid md:grid-cols-3 md:gap-6 gap-8">

        <BasicRowsCard 
          rows={[
            {
              title: "Airport Name",
              content: airportData.name
            },
            {
              title: "City",
              content: airportData.city
            },
            {
              title: "Country",
              content: airportData.country
            },
            {
              title: "ICAO",
              content: airportData.icaoCode
            },
          ]}
        />

        <AirportActivityHistory icaoCode={icaoCode} hasLimitedAccess={hasLimitedAccess} />

        <CustomBasicRowCard icaoCode={icaoCode} hasLimitedAccess={hasLimitedAccess} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-8">
      <div className="lg:col-span-3 md:gap-6 gap-8 flex flex-col">
        <FlightHistoryCard title='Flight Activity' icaoCode={icaoCode} hasLimitedAccess={hasLimitedAccess} />
        <ActivityByTypeCard additionalApiFilters={`icaoCode=${icaoCode}`} hasLimitedAccess={hasLimitedAccess} />
      </div>
      
      <div className="lg:col-span-2 md:gap-6 gap-8 flex flex-col">
        <AirportSimpleTableCard
          title="Top Operators"
          apiRoute="getAirportOperators"
          icaoCode={icaoCode}
          hasLimitedAccess={hasLimitedAccess}
          />
        
        <AirportSimpleTableCard
          title="Top Models"
          apiRoute="getAircraftModels"
          icaoCode={icaoCode}
          hasLimitedAccess={hasLimitedAccess}
        />
        <AirportSimpleTableCard
          title="Aircraft"
          apiRoute="getAircraftsByAirport"
          icaoCode={icaoCode}
          hasLimitedAccess={hasLimitedAccess}
        />
      </div>

    </div>
    {/* <TopRoutes timePeriod={timePeriod} presetFilters={`icaoCode=${icaoCode}`} /> */}
    </>
    )}
  </div>
  );
}